import React from 'react';
import './LabeledValue.css';

const labeledValue = (props) => {
    return (
        <div className=''>
            <label style={gab}><strong>{props.text}</strong></label>
            <label style={gab}>{props.info}</label>
        </div>
    );
}

const gab = {
    paddingRight: '15px'
};

export default labeledValue;
