import * as actionTypes from '../actions/actionTypes';
import {createToggleInformationList} from '../../utility/ToggleHandler';

export function toggleCategoriesInformationList(categoryIds) {
    return (dispatch) => {
        dispatch(buildInformationListStart());
        try {
            const toggleInformation = createToggleInformationList(categoryIds);
            dispatch(buildInformationListSuccess(toggleInformation))
        } catch (error) {
            dispatch(buildInformationListFail(error))
        }
    };
}

function buildInformationListFail(error) {
    return {
        type: actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_FAIL,
        error: error
    };
}

function buildInformationListSuccess(toggleInformation) {
    return {
        type: actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_SUCCESS,
        toggleCategoriesInformationList: toggleInformation
    };
}

function buildInformationListStart() {
    return {
        type: actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_START
    };
}


export function toggleCategory(categoryId) {
    return (dispatch) => {
        dispatch(toggle(categoryId))
    };
}

function toggle(categoryId) {
    return {
        type: actionTypes.TOGGLE_CATEGORY,
        categoryId: categoryId
    };
}

export function toggleCategories(b) {
    return (dispatch) => {
        dispatch(toggleAll(b))
    };
}

function toggleAll(toggle) {
    return {
        type: actionTypes.TOGGLE_CATEGORIES,
        toggleAllCategories: !toggle
    };
}

export function clearToggleAllCategories() {
    return {
        type: actionTypes.CLEAR_TOGGLE_ALL_CATEGORIES
    };
}
