import * as actionTypes from '../actions/actionTypes';
import {changeToggleInformationFor} from '../../utility/ToggleHandler';
import {setToggleAll} from '../../utility/ToggleHandler';
import {toggleAllInList} from '../../utility/ToggleHandler';

const initialState = {
    toggleCategoriesInformationList: [],
    toggleAllCategories: false,
    error: ''
};

const toggleCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_START:
            return {
                ...state
            };
        case actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_SUCCESS:
            return {
                ...state,
                toggleCategoriesInformationList: action.toggleCategoriesInformationList
            };
        case actionTypes.BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_FAIL:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.TOGGLE_CATEGORY:
            return {
                ...state,
                toggleCategoriesInformationList: changeToggleInformationFor(action.categoryId, [...state.toggleCategoriesInformationList]),
                toggleAllCategories: setToggleAll([...state.toggleCategoriesInformationList], state.toggleAllCategories)
            }
        case actionTypes.TOGGLE_CATEGORIES:
            return {
                ...state,
                toggleCategoriesInformationList: toggleAllInList(action.toggleAllCategories, [...state.toggleCategoriesInformationList]),
                toggleAllCategories: action.toggleAllCategories
            }
        case actionTypes.CLEAR_TOGGLE_ALL_CATEGORIES:
            return {
                ...state,
               ...initialState
            }
        default:
            return state;
    }
}

export default toggleCategoriesReducer;
