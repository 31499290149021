import React, {Component} from 'react';
import angleDown from '../../../assets/images/angle-down-solid.svg';
import './AngleRotationIcon.css';

/*
* @param props
* clicked: action what will happen when clicking the angle icon
*/
class AngleRotationIcon extends Component {

    state = {
        rotate: false
    }

    angelRotation() {
        if (this.props.direction === undefined) {
            return this.state.rotate ? 'Up' : 'Down';
        }
        return this.props.direction ? 'Up' : 'Down';
    }

    rotationAction() {
        this.props.clicked();
        if (this.props.notRotate) {
            return;
        }
        this.setState({rotate: !this.state.rotate});
    }

    render() {
        return (
            <>
                <img className={this.angelRotation()} src={angleDown} alt='' onClick={() => this.rotationAction()}/>
            </>
        )
    }
}

export default AngleRotationIcon;
