import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';

export function initializeTranslation() {
   return i18n
        .use(Backend)
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            fallbackLng: 'en',
            debug: true,
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json'
            },
            react: {
                useSuspense: false
            },
            detection: {order: ['navigator']}
        }, (err, t) => {
            //TODO: implement proper error handling
            t('key');
        });
}



export default i18n;
