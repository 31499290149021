import React from 'react';

import {useDispatch} from 'react-redux';

import {ReactComponent as Download} from '../../../../../assets/images/download-solid.svg';
import {downloadDocument} from '../../../../../store/actions/ProducerAction';

import './ProducerDocument.css';
import '../../../../../components/UploadDocuments/Documents/Documents.css';

const ProducerDocument = ({document}) => {
    const dispatch = useDispatch();

    const download = (documentId, documentName) => {
        dispatch(downloadDocument(documentId, documentName))
    };


    return (
        <div className="document-download-container">
            <div className="document-producer-download">
                <button onClick={() => download(document.id, document.name)} className="down-producer-document">
                    <Download className="icon_download"/>
                </button>
            </div>
            <div className="document_name" onClick={() => download(document.id, document.name)}>
                {document.name}
            </div>
        </div>
    )
}

export default ProducerDocument;
