import React, {Component} from 'react';
import '../AllForms.css';
import './PasswordChangeForm.css'
import Button from '../../UI/Button/Button';
import {Link} from 'react-router-dom';

/**
 * props:
 * label: Show the title of the form
 * placeholderUsername
 * insertUserNameHandler: insert username handler function
 * placeholderPassword
 * insertCurrentPasswordHandler: insert password handler function
 * placeholderNewPassword
 * insertFirstNewPasswordHandler: insert first new password handler function
 * insertSecondNewPasswordHandler: insert second new password handler function
 * buttonTitle
 * loginAndChange: Login and password change function
 * firstNewPasswordInputColor
 * secondNewPasswordInputColor
 */
class PasswordChangeForm extends Component {

    render() {
        return (
            <div className='AllForms' onKeyUp={this.onKeyEnterHandler}>
                <label>{this.props.label}</label>
                <div>
                    <input type='text' placeholder={this.props.placeholderUsername}
                           onChange={this.props.insertUserNameHandler}/>
                    <input type='password' placeholder={this.props.placeholderPassword}
                           onChange={this.props.insertCurrentPasswordHandler}/>
                </div>
                <div style={newPasswordGab}/>
                <input id='new'
                       className={this.props.newPasswordInputColor}
                       type='password' placeholder={this.props.placeholderNewPassword}
                       onChange={this.props.newPasswordHandler}/>
                <input id='repeat'
                       className={this.props.confirmPasswordInputColor}
                       type='password' placeholder={this.props.placeholderNewPassword}
                       onChange={this.props.confirmPasswordHandler}/>
                <Button title={this.props.buttonTitle} clicked={this.props.loginAndChange}/>
                <Link to={'/'}>{this.props.linkTitle}</Link>
            </div>
        )
    }

    onKeyEnterHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.props.loginAndChange();
        }
    }
};

const newPasswordGab = {
    paddingTop: '15px'
};

export default PasswordChangeForm;
