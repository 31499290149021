import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import RequirementReducer from './store/reducers/RequirementReducer';
import LoginReducer from './store/reducers/LoginReducer';
import MessageReducer from './store/reducers/MessageReducer';
import RequirementFilterReducer from './store/reducers/RequirementFilterReducer';
import RequirementSelectionReducer from './store/reducers/RequirementSelectionReducer';
import ProducerReducer from './store/reducers/ProducerReducer';
import ToggleCategoriesReducer from './store/reducers/ToggleCategoriesReducer';
import CustomerInfoReducer from './store/reducers/CustomerInfoReducer';
import CountriesReducer from './store/reducers/CountriesReducer';
import RequirementResponseReducer from './store/reducers/RequirementResponseReducer';
import ToggleRequirementReducer from './store/reducers/ToggleRequirementReducer';
import ToggleResponseReducer from './store/reducers/ToggleResponseReducer';
import FavoritesReducer from './store/reducers/FavoritesReducer';
import ProductAreaReducer from './store/reducers/ProductAreaReducer';
import CategoryReducer from './store/reducers/CategoryReducer';
import SupportedLanguagesReducer from './store/reducers/SupportedLanguagesReducer';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers({
    /* your app’s top-level reducers */
    filteredRequirement: RequirementFilterReducer,
    requirementsData: RequirementReducer,
    login: LoginReducer,
    selectedRequirement: RequirementSelectionReducer,
    producer: ProducerReducer,
    message: MessageReducer,
    toggleCategories: ToggleCategoriesReducer,
    customerInfo: CustomerInfoReducer,
    countries: CountriesReducer,
    requirementResponses: RequirementResponseReducer,
    toggleRequirement: ToggleRequirementReducer,
    toggleResponse: ToggleResponseReducer,
    favorites: FavoritesReducer,
    productAreaData: ProductAreaReducer,
    categoryData: CategoryReducer,
    supportedLanguages: SupportedLanguagesReducer
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'logout') {
        state = undefined;
    }
    return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


export default rootReducer;
