import React, {useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';


import './Main.css';
import {useTranslation} from 'react-i18next';

import {addDocument, deleteDocument, downloadDocument} from '../../../../../store/actions/ProducerAction';
import {loadCountries} from '../../../../../store/actions/CountriesAction';
import AssetHandler from '../../../../../components/UploadDocuments/AssetHandler';
import ProducerDocument from '../../../../Customer/ProducerList/ListComponents/Document/ProducerDocument';

const Main = ({props, providerInfo, state}) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const countries = useSelector(state => state.countries);

    useEffect(() => {
        if (!countries) dispatch(loadCountries());
    }, [])

    const mainContent = useRef(null);

    const windowEvent = () => {
        if (mainContent.current && mainContent.current.clientWidth) {
            props.handleHeaderWidth(mainContent.current.clientWidth);
        }
    }

    useEffect(() => {
        windowEvent()
    }, [])

    const documentUpload = (providerId, document) => {
        dispatch(addDocument(providerId, document));
    }

    const deleteAsset = (documentId, ownerId) => {
        dispatch(deleteDocument(documentId, ownerId));
    }

    const downloadAsset = (documentId, documentName) => {
        dispatch(downloadDocument(documentId, documentName));
    }

    function showProducerDocuments(documents) {
        return documents ? documents.map(document => <ProducerDocument
            key={document.id} document={document}/>) : '-'
    }

    return (
        <div ref={mainContent} className="main-content">
            <div className="left-side">
                <div className="side-container">

                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_LOCATION')}</label>
                        </div>
                        <div className="col-right">
                            <select disabled={!state.editable} onChange={props.handleFields('countryId')}
                                    value={state.profile.countryId ? state.profile.countryId : ""} id="Producer-Country"
                                    className="producer-profile-main-fields" name="country">
                                {countries ?
                                    countries.map(country => (
                                        <option key={country.id} value={country.id}>
                                            {
                                                i18n.exists(country.name) ? (
                                                    t(country.name)
                                                ) : country.name
                                            }
                                        </option>
                                    )) : null
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_COMPANY_URL')}</label>
                        </div>
                        <div className="col-right">
                            {!state.editable ? (
                                <div className="producer-link"><a href={`http://${state.profile.companyUrl}`}
                                                                  rel="noopener noreferrer"
                                                                  target="_blank">{state.profile.companyUrl ? t('PRODUCER_LIST_COMPANY_URL_LINK') : ""}</a>
                                </div>) : (
                                <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                       onChange={props.handleFields('companyUrl')}
                                       value={state.profile.companyUrl ? state.profile.companyUrl : ""}
                                       id="Company-Url"/>)}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_OWNER_BELONGS_TO')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('belongsTo')}
                                   value={state.profile.belongsTo ? state.profile.belongsTo : ""} id="Owner"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_TURNOVER')}</label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('turnover')}
                                   value={state.profile.turnover ? state.profile.turnover : ""} id="Producer-Turnover"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_PERSONNEL_TOTAL')}</label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('personnelTotal')}
                                   value={state.profile.personnelTotal ? state.profile.personnelTotal : ""}
                                   id="Personnel-Total"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left documents">
                            <label>
                                {t('PRODUCER_DOCUMENTS')}
                            </label>
                        </div>
                        <div className="col-right">
                            {state.editable ?
                                <AssetHandler documents={providerInfo.profile.documents}
                                              ownerId={providerInfo.id}
                                              editable={state.editable}
                                              documentTyp='ProducerProfile'
                                              uploadDocumentAction={(providerId, document) => documentUpload(providerId, document)}
                                              deleteDocument={(documentId, ownerId) => deleteAsset(documentId, ownerId)}
                                              downloadDocument={(documentId, documentName) => downloadAsset(documentId, documentName)}/> :
                                showProducerDocuments(providerInfo.profile.documents)
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className="right-side">
                <div className="side-container">

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_CRIS_PRODUCT')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productName')}
                                   value={state.profile.productName ? state.profile.productName : ""}
                                   id="Cris-Product"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCT_URL')}
                            </label>
                        </div>
                        <div className="col-right">
                            {!state.editable ? (
                                <div className="producer-link"><a href={`http://${providerInfo.profile.productUrl}`}
                                                                  rel="noopener noreferrer"
                                                                  target="_blank">{state.profile.productUrl ? t('PRODUCER_LIST_PRODUCT_URL_LINK') : ""}</a>
                                </div>) : (
                                <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                       onChange={props.handleFields('productUrl')}
                                       value={state.profile.productUrl ? state.profile.productUrl : ""}
                                       id="Product-Url"/>)}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_CRIS_AT_MARKET')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="date" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleDate('productSince')}
                                   value={state.productSinceValue ? state.productSinceValue : ""} id="Cris-At-Market"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_COMPANY_TURNOVER')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productTurnover')}
                                   value={state.profile.productTurnover ? state.profile.productTurnover : ""}
                                   id="Company-Turnover"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_PERSONNEL_CRIS')}</label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productPersonnelTotal')}
                                   value={state.profile.productPersonnelTotal ? state.profile.productPersonnelTotal : ""}
                                   id="Personnel-Cris"/>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-left">
                            <label>{t('PRODUCER_PERSONNEL_DEV')}</label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productPersonnelDev')}
                                   value={state.profile.productPersonnelDev ? state.profile.productPersonnelDev : ""}
                                   id="Personnel-Dev"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_CURRENT_PROJECTS')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productInProgress')}
                                   value={state.profile.productInProgress ? state.profile.productInProgress : ""}
                                   id="Current-Projects"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-left">
                            <label>
                                {t('PRODUCER_PRODUCTIVE_SYSTEMS')}
                            </label>
                        </div>
                        <div className="col-right">
                            <input type="text" className="producer-profile-main-fields" disabled={!state.editable}
                                   onChange={props.handleFields('productLiveCount')}
                                   value={state.profile.productLiveCount ? state.profile.productLiveCount : ""}
                                   id="Productive-Systems"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};
export default Main;
