import React, {Component} from 'react';
import {connect} from 'react-redux';

import './Profile.css';
import Header from './ProfileContainer/Header/Header';
import Main from './ProfileContainer/Main/Main';
import Footer from './ProfileContainer/Footer/Footer';
import Spinner from '../../../components/UI/Spinner/Spinner';

import {
    updateProducerInfo,
    loadProducerInfo,
    updateProducerLoginInformation
} from '../../../store/actions/ProducerAction';
import * as UxHelper from '../../../utility/UxHelper';

class Profile extends Component {
    state = {
        profile: {
            profilePublished: false
        },
        editable: false,
        disabled: true,
        event: null,
        headerWidth: 0,
        //default value
        productSinceValue: "2000-01-01"
    }

    componentDidMount() {
        this.producerValidation();

        this.props.loadInfo(this.getId());

        window.addEventListener('mousedown', this.clickHandler);

        this.updateState();
        UxHelper.topScroll();
    }

    getId() {
        if (this.props.userType === 'CUSTOMER') {
            return this.props.location.state.producerId
        }
        return this.props.id;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profile !== this.props.profile) {
            this.updateState();
        }
    }

    UNSAFE_componentWillMount() {
        window.removeEventListener('mousedown', this.clickHandler);
    }

    producerValidation = () => {
        if (this.props.valid && this.props.userType === "PRODUCER") {
            this.setState({
                editable: true
            })
        }
    }

    updateState = () => {
        if (this.props.profile.id) {
            this.props.updateLoginInformation({
                ...this.props.account,
            });

            let date = new Date(this.props.profile.productSince);
            this.setState({
                profile: {
                    id: this.props.id,
                    belongsTo: this.props.profile.belongsTo,
                    name: this.props.profile.name,
                    countryId: this.props.profile.countryId,
                    countryName: this.props.profile.countryName,
                    personnelTotal: this.props.profile.personnelTotal,
                    productId: this.props.profile.productId,
                    productName: this.props.profile.productName,
                    productPersonnelTotal: this.props.profile.productPersonnelTotal,
                    productPersonnelDev: this.props.profile.productPersonnelDev,
                    productTurnover: this.props.profile.productTurnover,
                    productLiveCount: this.props.profile.productLiveCount,
                    profilePublished: this.props.profile.profilePublished,
                    companyUrl: this.props.profile.companyUrl,
                    productUrl: this.props.profile.productUrl,
                    productDescription: this.props.profile.productDescription,
                    productInProgress: this.props.profile.productInProgress,
                    turnover: this.props.profile.turnover,
                    productSince: this.props.profile.productSince,
                    productAreaId: this.props.account.productArea.id
                },
                productSinceValue: `${date.getFullYear()}-${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}-${date.getDate() > 8 ? date.getDate() : `0${date.getDate()}`}`
            })
        }
    };

    handleEvent = (value) => {
        return new Promise((resolve, reject) => {
            this.setState({event: value}, () => resolve(this.state.event))
        })
    };

    //handling outer click
    clickHandler = (event) => {
        let set = new Set(["producer-profile-main-fields", "title-edit-button"]);
        if (this.state.event === 'editProfile') {
            if (!set.has(event.target.className)) {
                this.props.editProfile(this.props.id, this.state.profile);
                this.setState({
                    ...this.state,
                    disabled: true,
                    event: null
                });
                this.handleEvent(null)
            }
        }
    };

    handleFields = (field) => {
        return event => {
            let profileObj = this.state.profile;
            profileObj[field] = event.target.value;
            if (this.state.event === null) {
                this.handleEvent("editProfile")
                    .then(() => this.setState({
                            ...this.state,
                            profile: profileObj,
                        })
                    )
            }
            if (this.state.event === "editProfile") {
                this.setState({
                    ...this.state,
                    profile: profileObj,
                })
            }
        }
    };


    handleDate = (field) => {
        return event => {

            let profileObj = this.state.profile;
            let valueForInput = event.target.value;
            let sinceValue = event.target.value.split("-")
            profileObj[field] = new Date(sinceValue[0], +sinceValue[1] - 1, +sinceValue[2]).valueOf();
            if (this.state.event === null) {
                this.handleEvent("editProfile")
                    .then(() => this.setState({
                            ...this.state,
                            profile: profileObj,
                            productSinceValue: valueForInput
                        })
                    )
            }
            if (this.state.event === "editProfile") {
                this.setState({
                    ...this.state,
                    profile: profileObj,
                    productSinceValue: valueForInput
                })
            }
        }
    };

    editProfile = () => {
        if (this.state.event === null) {
            this.handleEvent("editProfile")
                .then(() => this.setState({disabled: false}))
        }
        if (this.state.event === "editProfile") {
            this.setState({disabled: false});
        }
    };

    handleHeaderWidth = (width) => {
        this.setState({headerWidth: width});
    }

    cache = () => {
        this.setState({
                profile: {
                    ...this.state.profile,
                    profilePublished: false
                }
            },
            () => this.props.editProfile(this.props.id, this.state.profile)
        )
    };

    publish = () => {
        this.setState({
                profile: {
                    ...this.state.profile,
                    profilePublished: true
                }
            },
            () => this.props.editProfile(this.props.id, this.state.profile)
        )
    };

    //props pass to child components
    propsList = {
        cache: this.cache,
        publish: this.publish,
        editProfile: this.editProfile,
        handleFields: this.handleFields,
        handleEvent: this.handleEvent,
        handleHeaderWidth: this.handleHeaderWidth,
        handleDate: this.handleDate
    }

    render() {
        if (this.props.loading) {
            return <Spinner/>
        }
        return (
            <span className="Profile">
           <Header props={this.propsList} providerInfo={this.props} state={this.state}/>
           <Main props={this.propsList} providerInfo={this.props} state={this.state}/>
           <Footer props={this.propsList} providerInfo={this.props} state={this.state}/>
      </span>
        )
    }
};

const mapStateToProps = state => {
    return {
        profile: state.producer.profile,
        userType: state.login.account.userType,
        id: state.login.account.id,
        account: state.login.account,
        loading: state.producer.loading,
        valid: state.login.valid
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editProfile: (id, body) => dispatch(updateProducerInfo(id, body)),
        loadInfo: (id) => dispatch(loadProducerInfo(id)),
        updateLoginInformation: (body) => dispatch(updateProducerLoginInformation(body))
    }
};

const ProfileWithRedux = connect(mapStateToProps, mapDispatchToProps)(Profile);
export default ProfileWithRedux;
