import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import MainView from '../containers/Producer/ProducerMainView';
import Profile from '../containers/Producer/Profile/Profile';
import Logout from '../containers/Logout/Logout';
import InterestedInProductList from '../containers/Producer/CustomerList/CustomerList';
import MyFavoriteRequirements from '../containers/Producer/MyFavoriteRequirements/MyFavoriteRequirements';
import {
    PRODUCER_REQUIREMENTS_ROUTE_PATH,
    PRODUCER_MY_PROFILE_ROUTE_PATH,
    PRODUCER_CUSTOMER_LIST_ROUTE_PATH,
    PRODUCER_LOGOUT_ROUTE_PATH,
    PRODUCER_MY_FAVORITE_REQUIREMENTS_ROUTE_PATH
} from './RoutingPaths';

const producerRouting = () => {
    return (
        <Switch>
            <Route path={PRODUCER_REQUIREMENTS_ROUTE_PATH} component={MainView}/>
            <Route path={PRODUCER_MY_PROFILE_ROUTE_PATH} component={Profile}/>
            <Route path={PRODUCER_CUSTOMER_LIST_ROUTE_PATH} component={InterestedInProductList}/>
            <Route path={PRODUCER_LOGOUT_ROUTE_PATH} component={Logout}/>
            <Route path={PRODUCER_MY_FAVORITE_REQUIREMENTS_ROUTE_PATH} component={MyFavoriteRequirements}/>
            <Redirect to={PRODUCER_REQUIREMENTS_ROUTE_PATH}/>
        </Switch>
    );
}


export default producerRouting;
