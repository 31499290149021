import React, {Component} from 'react';
import './ContactPersonTable.css';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {ReactComponent as Plus} from '../../../../../../assets/images/plus-circle-solid.svg';
import {ReactComponent as Pen} from '../../../../../../assets/images/pen-solid.svg';
import {ReactComponent as Del} from '../../../../../../assets/images/trash-alt-regular.svg';

import {addContactPerson, updateContactPerson, deleteContactPerson} from '../../../../../../store/actions/ProducerAction';

class ContactTable extends Component {
    initialState = {
        name: "",
        email: "",
        telNo: "",
        defaultContact: false,
        countryId: 1,
        productId: this.props.profile.productId
    }
    state = {
        contactPerson: this.initialState,
        personArray: [],
        addPersonRow: false,
        editContactPerson: false,
        contactPersonId: null
    }
    componentDidMount(){
        this.processPersonsArray();
        window.addEventListener('mousedown', this.contactEv);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps!==this.props){
            this.setState({personArray: []},()=> this.processPersonsArray());
        }
    }
    UNSAFE_componentWillMount(){
        window.removeEventListener('mousedown', this.contactEv);
    }

    processPersonsArray = () =>{
        if(this.props.profile.persons){this.props.profile.persons.map((person, index)=>this.setState(prev=>{
                return {...prev, personArray: [...prev.personArray, {index: index, value: person, edit: true}]}
            })
        )
        }
    };

    contactEv = (event) => {
        if(this.state.addPersonRow && this.props.state.event === "addContact"){
            this.contactAddEvent(event.target.className);
        }else if(this.state.editContactPerson  && this.props.state.event === "editContact"){
            this.contactEditEvent(event.target.className);
        }else{
           return
        }
    }

    contactAddEvent = (className) => {
        const clickSet = new Set([
            'add-new-contact-person',
            'edit-column-price add-new-contact-person',
            'edit-contact-person add-new-contact-person icon-cancel'
        ]);
        if(!clickSet.has(className)){
            this.props.addContact(this.props.id,this.state.contactPerson);
            this.setState({addPersonRow: false});
            this.setState({contactPerson: this.initialState});
            this.props.handleEvent(null);
        }
    };

    contactEditEvent = (className) => {
        const clickSet = new Set([
            'edit-contact-person-field',
            'edit-column-person edit-contact-person-field',
            'edit-contact-person add-new-contact-person icon-cancel'
        ]);

        if(!clickSet.has(className)){
            this.setState({editContactPerson: false})
            this.props.updateContact(this.props.id,this.state.contactPersonId, this.state.contactPerson)
            this.setState({contactPerson: this.initialState});
            this.setState({contactPersonId: null});
            this.props.handleEvent(null);
        }
    };
    cancelAdd = () => {
        this.setState({addPersonRow: false});
        this.setState({contactPerson: this.initialState});
        this.props.handleEvent(null);
    };

    handleEdit = (id, index) => {
        if(this.props.state.event===null){
            this.props.handleEvent("editContact").then(()=>{
                let editPerson = this.state.personArray.filter(person=>person.value.id===id)[0];
                this.setState({contactPerson: editPerson.value});
                let other = this.state.personArray.filter(person=>person.value.id!==id);
                this.setState(prev=>{
                    return {...prev, personArray: [...other, {index: index, value: editPerson.value, edit: false}]}
                })
                this.setState({editContactPerson: true})
                this.setState({contactPersonId: id})
            });
        }
    };

    handleDelete = (id)=>{
        const {t} = this.props;
        let result = window.confirm(t("PRODUCT_CONTACT_PERSON_DELETE_CONFIRMATION"));
        if(result)this.props.deleteContact(id, this.props.id);
    };

    handleName =(event)=>{
        this.setState({contactPerson: {...this.state.contactPerson, name: event.target.value}})
    };

    handleEmail =(event)=>{
        this.setState({contactPerson: {...this.state.contactPerson, email: event.target.value}})
    };

    handleTelno =(event)=>{
        this.setState({contactPerson: {...this.state.contactPerson, telNo: event.target.value}})
    };

    handleDefault =(event)=>{
        this.setState({contactPerson: {...this.state.contactPerson, defaultContact: event.target.value}})
    };

    handleCountry =(event)=>{
        this.setState({contactPerson: {...this.state.contactPerson,  countryId: event.target.value}})
    };

    addContactPerson = () => {
        this.props.handleEvent("addContact")
            .then(()=>this.setState({addPersonRow: true}));
    }
    render(){
        const { t, i18n } = this.props;

        return(
        <table className="Contact-Table" >
            <tbody>
            <tr>
                <th className="Th-Name">{t('PRODUCT_CONTACT_PERSON_NAME')}</th>
                <th className="Th-Email" >{t('PRODUCT_CONTACT_PERSON_EMAIL')}</th>
                <th className="Th-Phone" >{t('PRODUCT_CONTACT_PERSON_PHONE_NUMBER')}</th>
                <th className="Th-Country" >{t('PRODUCT_CONTACT_PERSON_COUNTRY')}</th>
                <th className="Th-Default" >{t('PRODUCT_CONTACT_PERSON_DEFAULT')}</th>
                {
                    this.props.state.editable?( <th className="Th-Actions" >{t('PRODUCT_CONTACT_PERSON_ACTIONS')}</th>):null
                }

            </tr>
                {this.state.personArray.sort((a,b)=>a.index-b.index).map(row=>(
                    <tr className={!row.edit?'edit-contact-person-field':''} key={row.value.id}>
                        <td className={!row.edit?'edit-contact-person-field':''} >{row.edit?row.value.name:(<input type="text" className={!row.edit?'edit-contact-person-field':''} onChange={this.handleName} defaultValue={this.state.contactPerson.name} />)}</td>
                        <td className={!row.edit?'edit-contact-person-field':''}>{row.edit?row.value.email:(<input type="text" className={!row.edit?'edit-contact-person-field':''} onChange={this.handleEmail} defaultValue={this.state.contactPerson.email}/>)}</td>
                        <td className={!row.edit?'edit-contact-person-field':''}>{row.edit?row.value.telNo:(<input type="text" className={!row.edit?'edit-contact-person-field':''} onChange={this.handleTelno} defaultValue={this.state.contactPerson.telNo}/>)}</td>
                        <td className={!row.edit?'edit-contact-person-field':''}>
                            <select    onChange={this.handleCountry} disabled={row.edit}  value = { !row.edit?this.state.contactPerson.countryId : row.value.countryId } id="Producer-Country" className={!row.edit?'edit-contact-person-field':'contact-person-country'} name="country">
                                {this.props.countries?
                                  this.props.countries.map(country=>(
                                    <option key={country.id} value = {country.id}>
                                      {
                                           i18n.exists(country.name) ? (
                                            t(country.name)
                                          ): country.name
                                      }
                                    </option>
                                  )): null
                                }
                            </select>
                        </td>
                        <td className={!row.edit?'edit-contact-person-field':''}>{
                            row.edit?row.value.defaultContact?t('PRODUCT_CONTACT_PERSON_DEFAULT_CONTACT'):t('PRODUCT_CONTACT_PERSON_NOT_DEFAULT_CONTACT'):
                            (
                            <select  defaultValue = {this.state.contactPerson.defaultContact} onChange={this.handleDefault}  className={!row.edit?'edit-contact-person-field':''} id="Producer-Country" name="country">
                                <option  className={!row.edit?'edit-contact-person-field':''} value={true}>{t('PRODUCT_CONTACT_PERSON_DEFAULT_CONTACT')}</option>
                                <option className={!row.edit?'edit-contact-person-field':''}  value={false}>{t('PRODUCT_CONTACT_PERSON_NOT_DEFAULT_CONTACT')}</option>
                            </select>
                            )}
                        </td>
                        {
                            this.props.state.editable?(
                                <td className={!row.edit?'edit-contact-person-field':''}>
                                    <div className={!row.edit?'edit-column-person edit-contact-person-field':'edit-column-person'}>
                                    {!row.edit?(
                                        <button className="edit-contact-person add-new-contact-person icon-cancel" title={t("TABLE_CANCEL_BUTTON_TITLE")}  onClick={()=>this.cancelAdd()} />
                                    ):(
                                        <>
                                            <button onClick={()=>this.handleEdit(row.value.id, row.index)} title={t("CONTACT_PERSON_TABLE_EDIT_BUTTON_TITLE")} className="edit-contact-person edit-contact-person-field" >
                                                <Pen className="icon-edit"/>
                                            </button>
                                            <button onClick={()=>this.handleDelete(row.value.id)} title={t("CONTACT_PERSON_DELETE_BUTTON_TITLE")}  className="edit-contact-person edit-contact-person-field">
                                                <Del className="icon-del"/>
                                            </button>
                                        </>)}

                                    </div>
                                </td>):null
                        }


                    </tr>
                ))}
                {this.state.addPersonRow?(
                <tr className="add-new-contact-person" id="add-button-field">
                    <td className="add-new-contact-person" ><input type="text" className="add-new-contact-person" onChange={this.handleName} value={this.state.contactPerson.name} /></td>
                    <td className="add-new-contact-person" ><input type="text" className="add-new-contact-person" onChange={this.handleEmail} value={this.state.contactPerson.email}/></td>
                    <td className="add-new-contact-person" ><input type="text" className="add-new-contact-person" onChange={this.handleTelno} value={this.state.contactPerson.telNo}/></td>
                    <td className="add-new-contact-person" >
                        <select    onChange={this.handleCountry}  value = {  this.state.contactPerson.countryId } id="Producer-Country"  className="add-new-contact-person" name="country">
                            {this.props.countries?
                              this.props.countries.map(country=>(
                                <option key={country.id} value = {country.id}>
                                  {
                                       i18n.exists(country.name) ? (
                                        t(country.name)
                                      ): country.name
                                  }
                                </option>
                              )): null
                            }
                        </select>
                    </td>
                    <td className="add-new-contact-person" >
                        <select  defaultValue = {this.state.contactPerson.defaultContact} onChange={this.handleDefault}  className="add-new-contact-person"  id="Producer-Country" name="country">
                            <option  className="add-new-contact-person"   value={true}>{t('PRODUCT_CONTACT_PERSON_DEFAULT_CONTACT')}</option>
                            <option  className="add-new-contact-person"  value={false}>{t('PRODUCT_CONTACT_PERSON_NOT_DEFAULT_CONTACT')}</option>
                        </select>
                    </td>
                    <td>
                        <div className="edit-column-price add-new-contact-person">
                            <button className="edit-contact-person add-new-contact-person icon-cancel" title={t("TABLE_CANCEL_BUTTON_TITLE")}  onClick={()=>this.cancelAdd()} />
                        </div>
                    </td>
                </tr>):(
                this.props.state.editable?(
                    <tr id="add-button-field">
                    <td  colSpan="6">
                        <div className="col-right-plus" title={t("CONTACT_PERSON_ADD_BUTTON_TITLE")} onClick={()=>this.addContactPerson()}>
                            <Plus id="row-add-button path"/>
                        </div>
                    </td>
                </tr>
                ):null
                )}
            </tbody>

        </table>
        )
    }
}
const mapStateToProps = state => {
    return {
        id: state.login.account.id,
        profile: state.producer.profile,
        countries: state.countries
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addContact: (producerId,body) => dispatch(addContactPerson(producerId,body)),
        updateContact: (producerId, id, body) => dispatch(updateContactPerson(producerId, id, body)),
        deleteContact: (id, producerId) => dispatch(deleteContactPerson(id, producerId))
    }
};

const ContactTableWithRedux = connect(mapStateToProps, mapDispatchToProps)(ContactTable);
export default withTranslation()(ContactTableWithRedux);
