import React, {Component} from 'react';
import '../AllForms.css';
import Button from '../../UI/Button/Button';
import {Link} from 'react-router-dom';


/**
 * props:
 * label: Show the title of the form
 * placeholderUsername
 * insertUserName: insert user name handler function
 * placeholderPassword
 * buttonTitle
 */
class LoginForm extends Component {
    render() {
        return (
            <div className='AllForms' onKeyUp={this.onKeyEnterHandler}>
                <label>{this.props.label}</label>
                <input type='text' placeholder={this.props.placeholderUsername}
                       onChange={this.props.insertUserName}/>
                <input type='password' placeholder={this.props.placeholderPassword}
                       onChange={this.props.insertPassword}/>
                <Button title={this.props.buttonTitle} clicked={this.props.loginHandler}/>
                <Link to={'/passwordchange'}>{this.props.linkTitle}</Link>
            </div>
        )
    }

    onKeyEnterHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.props.loginHandler();
        }
    }
}

export default LoginForm;
