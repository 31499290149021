import * as actionTypes from '../actions/actionTypes';
import {removeDeletedRequirement} from '../../utility/RequirementHandler';

const initialState = {
    approvedRequirements: {},
    customerRequirements: {},
    adminRequirements: [],
    loading: false,
    successUpdateRequirement: '',
    redirect: false,
    statusList: [],
    error: ''
};

const requirementReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HTTP_STATUS_UNAUTHORIZED:
            return {
                approvedRequirements: {},
                customerRequirements: {},
                loading: false,
                successUpdateRequirement: '',
                redirect: true,
                statusList: []
            }
        case actionTypes.LOAD_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                approvedRequirements: action.requirements,
                loading: false
            };
        case actionTypes.CREATE_REQUIREMENT_SUCCESS:
            return {
                ...state,
                customerRequirements: insertCustomerRequirements(action, state),
                status: action.status,
                loading: false
            };
        case actionTypes.LOAD_CUSTOMER_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                customerRequirements: action.customerRequirements,
                loading: false
            };
        case actionTypes.UPDATE_REQUIREMENT_SUCCESS:
            return {
                ...state,
                customerRequirements: changeUpdatedRequirement(action.updatedRequirement, state),
                loading: false
            };
        case actionTypes.REMOVE_REQUIREMENT_ASSOCIATION_SUCCESS:
            return {
                ...state,
                customerRequirements: removeDeletedRequirement(action.categoryId, action.removedRequirementId, state.customerRequirements),
                loading: false
            };
        case actionTypes.SUBMIT_REQUIREMENT_SUCCESS:
            return {
                ...state,
                adminRequirements: changeOrInsertRequirement(action.submittedRequirement, state.adminRequirements)
            };
        case actionTypes.DELETE_REQUIREMENT_SUCCESS:
            return {
                ...state,
                adminRequirements: removeDeletedAdminRequirement(action.removedRequirementIds, state.adminRequirements)
            }
        case actionTypes.ADMIN_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                adminRequirements: action.adminRequirements
            }
        case actionTypes.REMOVE_REQUIREMENT_ASSOCIATION_START:
        case actionTypes.SAVE_REQUIREMENT_ASSOCIATION_START:
        case actionTypes.UPDATE_REQUIREMENT_START:
        case actionTypes.LOAD_CUSTOMER_REQUIREMENTS_START:
        case actionTypes.LOAD_REQUIREMENTS_START:
        case actionTypes.SUBMIT_REQUIREMENT_START:
        case actionTypes.CREATE_REQUIREMENT_START:
        case actionTypes.ADMIN_REQUIREMENTS_START:
        case actionTypes.LOAD_REQUIREMENT_STATUS_START:
            return {
                ...state,
                loading: true,
                redirect: false
            };
        case actionTypes.SAVE_REQUIREMENT_ASSOCIATION_SUCCESS:
        case actionTypes.SAVE_REQUIREMENT_ASSOCIATION_FAIL:
        case actionTypes.REMOVE_REQUIREMENT_ASSOCIATION_FAIL:
        case actionTypes.LOAD_CUSTOMER_REQUIREMENTS_FAIL:
        case actionTypes.LOAD_REQUIREMENTS_FAIL:
        case actionTypes.UPDATE_REQUIREMENT_FAIL:
        case actionTypes.CREATE_REQUIREMENT_FAIL:
        case actionTypes.ADMIN_REQUIREMENTS_FAIL:
        case actionTypes.CLEAR_SAVED_REQUIREMENT_ASSOCIATIONS:
        case actionTypes.CLEAR_SAVED_REQUIREMENT_MESSAGE:
        case actionTypes.LOAD_REQUIREMENT_STATUS_FAIL:
            return {
                ...state,
                loading: false
            };
        case actionTypes.MESSAGE_ACCEPTED:
            return {
                ...state,
                error: ''
            }
        case actionTypes.SUBMIT_REQUIREMENT_FAIL:
        case actionTypes.DELETE_REQUIREMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case actionTypes.LOAD_REQUIREMENT_STATUS_SUCCESS:
            return {
                ...state,
                statusList: action.statusList
            }
        default:
            return state;
    }
};

function copyRequirementList(state, categoryId) {
    if (!state.customerRequirements.hasOwnProperty(categoryId)) {
        return [];
    }
    return [...state.customerRequirements[categoryId]];
}

function insertCustomerRequirements(action, state) {
    const requirement = action.createdRequirement;
    const copiedRequirementList = copyRequirementList(state, requirement.categoryId);
    copiedRequirementList.push(requirement);
    const newCustomerRequirements = {...state.customerRequirements};
    newCustomerRequirements[requirement.categoryId] = copiedRequirementList;
    return newCustomerRequirements;
}

//Split in two actions
export function changeUpdatedRequirement(updatedRequirement, state) {
    if (updatedRequirement === undefined || updatedRequirement === null || state === undefined || state === null) {
        throw new Error('Wrong use of function. Please insert updated requirement and state');
    }
    const isUpdatedRequirement = (requirement) => requirement.id === updatedRequirement.id;

    let reqList;
    for (const categoryId in state.customerRequirements) {
        reqList = copyRequirementList(state, categoryId);
        const index = reqList.findIndex(isUpdatedRequirement);
        if (index !== -1 && categoryId === updatedRequirement.categoryId.toString()) {
            reqList[index] = updatedRequirement;
            const newCustomerRequirements = {...state.customerRequirements};
            newCustomerRequirements[updatedRequirement.categoryId] = reqList;
            return newCustomerRequirements;
        }
        if (index !== -1 && categoryId !== updatedRequirement.categoryId.toString()) {
            const matchingReqList = copyRequirementList(state, updatedRequirement.categoryId);
            matchingReqList.push(updatedRequirement);
            reqList.splice(index, 1);
            const newCustomerRequirements = {...state.customerRequirements};
            newCustomerRequirements[categoryId] = reqList;
            newCustomerRequirements[updatedRequirement.categoryId] = matchingReqList;
            return newCustomerRequirements;
        }
    }
}

export function changeOrInsertRequirement(submittedRequirement, adminRequirements) {
    if (submittedRequirement === undefined || submittedRequirement === null || adminRequirements === undefined || adminRequirements === null) {
        throw new Error('Wrong use of function. submittedRequirement or administrator requirement list must be insert!');
    }
    let copyAdminRequirements = [...adminRequirements];
    let index = copyAdminRequirements.findIndex(requirement => requirement.id === submittedRequirement.id);
    if (index != -1) {
        copyAdminRequirements[index] = submittedRequirement;
        return copyAdminRequirements;
    }
    copyAdminRequirements.push(submittedRequirement);
    return copyAdminRequirements;
}

export function removeDeletedAdminRequirement(removedRequirementIds, adminRequirements) {
    if (removedRequirementIds === undefined || removedRequirementIds === null || adminRequirements === undefined || adminRequirements === null) {
        throw new Error('Wrong use of function. removedRequirementId or administrator requirement list must be insert!');
    }
    let copiedRequirementList = [...adminRequirements];
    for (let i = 0; i < removedRequirementIds.length; i++) {
        copiedRequirementList = removeFromRequirementList(copiedRequirementList, removedRequirementIds[i]);
    }
    return copiedRequirementList;
}

function removeFromRequirementList(adminRequirements, removedRequirementId) {
    const copiedRequirementList = [...adminRequirements];
    const isRemovedRequirement = (requirement) => requirement.id === removedRequirementId;
    copiedRequirementList.splice(copiedRequirementList.findIndex(isRemovedRequirement), 1);
    return copiedRequirementList;
}

export default requirementReducer;
