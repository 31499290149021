import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';
import {ACCOUNT} from '../../GlobalConstants';

export const loginWithPasswordChange = (loginInformation, newPassword) => {
    return async (dispatch) => {
        dispatch(loginStart());
        try {
            const checkUserResponse = await checkUser(loginInformation);
            if (checkUserResponse.status !== 200) {
                dispatch(loginFail(checkUserResponse));
                return;
            }
            const responseChange = await change(newPassword);
            if (responseChange.status !== 200) {
                dispatch(loginFail(responseChange));
                return;
            }
            const user = await responseChange.json();
            localStorage.setItem(ACCOUNT, JSON.stringify(user));
            dispatch(loginSuccess(user))
        } catch (error) {
            dispatch(loginFail(error));
        }
    }
}

async function change(newPassword) {
    return await fetch(SERVER_URL + '/userInformation/changepassword', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: newPassword
    });
}


export const login = (loginInformation) => {
    return async (dispatch) => {
        dispatch(loginStart());
        try {
            const response = await checkUser(loginInformation);
            if (response.status !== 200) {
                dispatch(loginFail(response));
                return;
            }
            const user = await response.json();
            localStorage.setItem(ACCOUNT, JSON.stringify(user));
            dispatch(loginSuccess(user))
        } catch (error) {
            dispatch(loginFail(error))
        }
    };
};

function loginStart() {
    return {
        type: actionTypes.LOGIN_START
    };
}

function loginSuccess(user) {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        valid: true,
        account: user
    };
}

function loginFail(error) {
    return {
        valid: false,
        loading: false,
        type: actionTypes.LOGIN_FAIL,
        error: error
    };
}

async function checkUser(loginInformation) {
    const response = await fetch(SERVER_URL + '/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({"username": loginInformation.username, "password": loginInformation.password})
    });
    return response;
}

export const authCheckState = () => {
    return dispatch => {
        //TODO: Here must be expire check
        const account = JSON.parse(localStorage.getItem(ACCOUNT));
        dispatch(loginSuccess(account));
    }
}
