export const CUSTOMER_MAIN_ROUTE_PATH = '/customer';
export const CUSTOMER_REQUIREMENTS_ROUTE_PATH = '/customer/requirements';
export const CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH = '/customer/myrequirements';
export const CUSTOMER_PRODUCER_LIST_ROUTE_PATH = '/customer/producerlist';
export const CUSTOMER_MY_PROFILE_ROUTE_PATH = '/customer/myprofile';
export const CUSTOMER_PRODUCER_PROFILE_ROUTE_PATH = '/customer/producerprofile/:producer';
export const CUSTOMER_LOGOUT_ROUTE_PATH = '/customer/logout';

export const PRODUCER_MAIN_ROUTE_PATH = '/producer';
export const PRODUCER_REQUIREMENTS_ROUTE_PATH = '/producer/requirements';
export const PRODUCER_MY_PROFILE_ROUTE_PATH = '/producer/myprofile';
export const PRODUCER_CUSTOMER_LIST_ROUTE_PATH = '/producer/customerlist';
export const PRODUCER_LOGOUT_ROUTE_PATH = '/producer/logout';
export const PRODUCER_MY_FAVORITE_REQUIREMENTS_ROUTE_PATH = '/producer/myfavoriterequirements';

export const ADMINISTRATOR_MAIN_ROUTE_PATH = '/admin';
export const ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH = '/admin/requirements';
export const ADMINISTRATOR_CATEGORIES_ROUTE_PATH = '/admin/categories';
export const ADMINISTRATOR_PRODUCT_AREA_ROUTE_PATH = '/admin/productarea';
export const ADMINISTRATOR_ACCOUNTS_ROUTE_PATH = '/admin/accounts';
export const ADMINISTRATOR_COUNTRIES_ROUTE_PATH = '/admin/countries';
export const ADMINISTRATOR_LOGOUT_ROUTE_PATH = '/admin/logout';

export const PASSWORD_CHANGE_ROUTE_PATH = '/passwordchange';
export const LOGIN_ROUTE_PATH = '/';
