import React from 'react';
import './Documents.css';

import {ReactComponent as Del} from '../../../assets/images/trash-alt-regular.svg';
import {ReactComponent as Download} from '../../../assets/images/download-solid.svg';

const Documents = (props) => {

    const documentDeleteHandler = (documentId) => {
            props.deleteDocument(documentId, props.ownerId);
    }

    const download = (documentId, documentName) => {
        props.downloadDocument(documentId, documentName);
    }

    return (
        <ul className="documents_list">
            {props.documents ? props.documents.map(document => (
                <li key={document.id} className="document">
                    <div className="document-download">
                        <button onClick={() => download(document.id, document.name)} className="down-document">
                            <Download className="icon_download"/>
                        </button>
                    </div>
                    <div className="document_name" onClick={() => download(document.id, document.name)}>
                        {document.name}
                    </div>
                    {props.editable ? (
                        <div className="delete-button">
                            <button onClick={() => documentDeleteHandler(document.id)} className="delete-document">
                                <Del className="icon_del"/>
                            </button>
                        </div>) : ''}


                </li>
            )) : ""}

        </ul>
    )
};

export default Documents;
