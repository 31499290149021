import React, {Component} from 'react';

/**
 * placeholder
 * name
 * row
 * eventHandler: got name and value
 */
class NamedTextArea extends Component {

    handleChange = (event) => {
        this.props.eventHandler(this.props.name, event.target.value);
    }

    render() {
        return (
            <>
                <textarea
                    key={this.props.name}
                    placeholder={this.props.placeholder}
                    rows={this.props.row}
                    onChange={this.handleChange}>
                    {this.props.children}
                </textarea>
            </>
        );
    }
}

export default NamedTextArea;
