import React from 'react';
import {NavLink} from "react-router-dom";

import './NavigationLink.css';

const navigationLink = (props) =>(
    //For producer and customer menubar, change color depending on profilePublished true/false, readyForContact true/ false.
    <li className='NavigationLink'>
        <NavLink
            to= { props.link }
            activeClassName = { props.sublineOrange ? 'orange' : 'green'}
        >{ props.children }</NavLink>
    </li>
)



export default navigationLink;
