import {Redirect, Route, Switch} from 'react-router-dom';
import ProducerList from '../containers/Customer/ProducerList/ProducerList';
import React from 'react';
import RequirementSelector from '../containers/Customer/CustomerMainView';
import MyRequirements from '../containers/Customer/MyRequirements/MyRequirements';
import CustomerProfile from '../containers/Customer/CustomerProfile/CustomerProfile';
import Profile from '../containers/Producer/Profile/Profile';
import Logout from '../containers/Logout/Logout';
import {
    CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH,
    CUSTOMER_REQUIREMENTS_ROUTE_PATH,
    CUSTOMER_PRODUCER_LIST_ROUTE_PATH,
    CUSTOMER_MY_PROFILE_ROUTE_PATH,
    CUSTOMER_PRODUCER_PROFILE_ROUTE_PATH,
    CUSTOMER_LOGOUT_ROUTE_PATH
} from './RoutingPaths';

const customerRouting = () => {
    return (
        <Switch>
            <Route path={CUSTOMER_REQUIREMENTS_ROUTE_PATH} component={RequirementSelector}/>
            <Route path={CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH} component={MyRequirements}/>
            <Route path={CUSTOMER_PRODUCER_LIST_ROUTE_PATH} component={ProducerList}/>
            <Route path={CUSTOMER_MY_PROFILE_ROUTE_PATH} component={CustomerProfile}/>
            <Route path={CUSTOMER_PRODUCER_PROFILE_ROUTE_PATH} component={Profile}/>
            <Route path={CUSTOMER_LOGOUT_ROUTE_PATH} component={Logout}/>
            <Redirect to={CUSTOMER_REQUIREMENTS_ROUTE_PATH}/>
        </Switch>
    );
}

export default customerRouting;
