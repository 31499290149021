import React, {Component} from 'react';
import Redirect from 'react-router-dom/es/Redirect';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {LANGUAGE_CODE} from '../../GlobalConstants';

class RedirectLogin extends Component {

    changeToUserLanguage() {
        const {i18n} = this.props;
        i18n.changeLanguage(this.props.account.user.languageCode);
        localStorage.setItem(LANGUAGE_CODE, this.props.account.user.languageCode);
    }

    render() {
        this.changeToUserLanguage();
        if (this.props.account.userType === 'CUSTOMER') {
            return <Redirect to='/customer'/>;
        }

        if (this.props.account.userType === 'PRODUCER') {
            return <Redirect to='/producer'/>;
        }

        return <Redirect to='/admin'/>
    }
}

const mapStateToProps = state => {
    return {
        account: state.login.account
    }
};

export default withTranslation()(connect(mapStateToProps)(RedirectLogin));
