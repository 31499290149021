import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {showInfo} from '../../store/actions/MessageAction';
import {sendDownloadRequirementsActivity, sendDownloadResponsesActivity} from '../../activity/ActivityNotifier';
import ExcelExportDropDownMenu from './ExcelExportDropDownMenu';
import AngleButton from '../UI/Button/AngleButton';
import {USER_TYPE_CUSTOMER} from '../../GlobalConstants';

class ExcelExport extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        const columnWidth = {wpx: 150};
        const columnStyle = {
            fill: {patternType: "solid", fgColor: {rgb: "0189b0"}},
            font: {sz: "11", bold: true},
            alignment: {wrapText: true},
            border: {
                top: {style: 'thin'},
                bottom: {style: 'thick'},
                left: {style: 'thin'},
                right: {style: 'thin'}
            }
        };
        this.categoryColumn = {
            title: t('EXCEL_EXPORT_REQUIREMENT_AREA'),
            width: columnWidth,
            style: columnStyle
        };
        this.descriptionColumn = {
            title: t('EXCEL_EXPORT_REQUIREMENT_DESCRIPTION'),
            width: columnWidth,
            style: columnStyle
        };
        this.producerColumn = {
            title: t('EXCEL_EXPORT_SOFTWARE_PRODUCER'),
            width: columnWidth,
            style: columnStyle
        };
        this.systemAvailableColumn = {
            title: t('EXCEL_EXPORT_SYSTEM_AVAILABLE'),
            width: columnWidth,
            style: columnStyle
        };
        this.configurableColumn = {
            title: t('EXCEL_EXPORT_CONFIGURABLE'),
            width: columnWidth,
            style: columnStyle
        };
        this.answerDescriptionColumn = {
            title: t('EXCEL_EXPORT_DESCRIPTION'),
            width: columnWidth,
            style: columnStyle
        };
        this.answerWithAttachmentColumn = {
            title: t('EXCEL_EXPORT_WITH_ATTACHMENT'),
            width: columnWidth,
            style: columnStyle
        };
        this.answerLastUpdateColumn = {
            title: t('EXCEL_EXPORT_LAST_UPDATE'),
            width: columnWidth,
            style: columnStyle
        };
    }

    buildExcelData() {
        return [
            {
                columns: [
                    this.categoryColumn,
                    this.descriptionColumn
                ],
                data: this.props.excelExportRequirements
            }
        ];
    }

    buildExcelDataWithResponses() {
        return [
            {
                columns: [
                    this.categoryColumn,
                    this.descriptionColumn,
                    this.producerColumn,
                    this.systemAvailableColumn,
                    this.configurableColumn,
                    this.answerDescriptionColumn,
                    this.answerWithAttachmentColumn,
                    this.answerLastUpdateColumn
                ],
                data: this.props.excelExportRequirementsWithResponses
            }
        ];
    }

    render() {
        const {t} = this.props;
        return (
            <>
                {this.requirementsSelected() ?
                    <ExcelExportDropDownMenu title={t('EXCEL_EXPORT_DOWNLOAD')}
                                             requirements={this.buildExcelData()}
                                             requirementsWithResponses={this.buildExcelDataWithResponses(t)}
                                             activityCountDownloadedRequirements={this.props.userType === USER_TYPE_CUSTOMER ? () => sendDownloadRequirementsActivity(this.props.selectedRequirementsForActivityCount) : ''}
                                             activityCountExportedRequirementsAndAnswers={this.props.userType === USER_TYPE_CUSTOMER ? () => sendDownloadResponsesActivity(this.props.selectedRequirementsForActivityCount, this.props.selectedResponsesForActivityCount) : ''}/> :
                    <AngleButton title={t('EXCEL_EXPORT_DOWNLOAD')}
                                 clicked={() => this.props.onShowNoRequirementSelectedMessage(t('EXCEL_EXPORT_INFORMATION_MSG'))}
                                 notRotate={true}/>
                }
            </>);
    }

    requirementsSelected() {
        return this.props.excelExportRequirements.length > 0;
    }
}

const mapStateToProps = state => {
    return {
        excelExportRequirements: state.selectedRequirement.excelExportRequirements,
        excelExportRequirementsWithResponses: state.selectedRequirement.excelExportRequirementsWithResponses,
        selectedRequirementsForActivityCount: state.selectedRequirement.selectedRequirementIds,
        selectedResponsesForActivityCount: state.selectedRequirement.selectedResponseIds,
        userType: state.login.account.userType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowNoRequirementSelectedMessage: (text) => dispatch(showInfo(true, text))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExcelExport));
