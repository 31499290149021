import * as actionTypes from './actionTypes';
import {SERVER_URL} from '../../config';
import {requirementJSONToRequirementMap} from '../../utility/RequirementHandler';

export function producerFavorites(accountId, languageCode) {
    return async (dispatch) => {
        dispatch(loadProducerFavoritesStart());
        try {
            const response = await loadFavorites(accountId, languageCode);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(loadProducerFavoritesSuccess(requirementJSONToRequirementMap(await response.json())));
        } catch (error) {
            dispatch(loadProducerFavoritesFail(error));
        }
    }
}

function loadProducerFavoritesStart() {
    return {
        type: actionTypes.LOAD_PRODUCERS_FAVORITES_START
    }
}

function loadProducerFavoritesSuccess(response) {
    return {
        type: actionTypes.LOAD_PRODUCERS_FAVORITES_SUCCESS,
        producerFavorites: response
    }
}

function loadProducerFavoritesFail(error) {
    return {
        type: actionTypes.LOAD_PRODUCERS_FAVORITES_FAIL,
        error: error
    }
}

async function loadFavorites(accountId, languageCode) {
    return await fetch(SERVER_URL + '/requirements/producer/favorites?id=' + accountId + '&languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}

/*requirementsIds must be an array like [1,2,3]*/
export function saveToProducersFavorites(requirementIds) {
    return async (dispatch) => {
        dispatch(saveProducersFavoritesStart());
        try {
            const response = await saveProducersFavorites(requirementIds);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(saveProducersFavoritesSuccess(response));
        } catch (error) {
            dispatch(saveProducersFavoritesFail(error))
        }
    };
}

function saveProducersFavoritesStart() {
    return {
        type: actionTypes.SAVE_TO_PRODUCERS_FAVORITES_START
    };
}

function saveProducersFavoritesFail(error) {
    return {
        type: actionTypes.SAVE_TO_PRODUCERS_FAVORITES_FAIL,
        error: error
    };
}

function saveProducersFavoritesSuccess(response) {
    return {
        type: actionTypes.SAVE_TO_PRODUCERS_FAVORITES_SUCCESS,
        newProducerFavorites: response
    };
}

async function saveProducersFavorites(requirementIds) {
    return await fetch(SERVER_URL + '/favorites/producer/createAssociation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "requirementIds": requirementIds
        })
    });
}

export function removeFromProducerFavorites(requirementId, categoryId) {
    return async (dispatch) => {
        dispatch(removeProducerFavoritesStart());
        try {
            const response = await removeProducersFavorites(requirementId);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(removeProducerFavoritesSuccess(requirementId, categoryId));
            dispatch(removeFilteredProducerFavorites(requirementId, categoryId));
        } catch (error) {
            dispatch(removeProducerFavoritesFail(error))
        }
    };
}

function removeProducerFavoritesStart() {
    return {
        type: actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_START
    };
}

function removeProducerFavoritesFail(error) {
    return {
        type: actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_FAIL,
        error: error
    };
}

function removeProducerFavoritesSuccess(requirementId, categoryId) {
    return {
        type: actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_SUCCESS,
        removedRequirementId: requirementId,
        removedCategoryId: categoryId
    };
}

function removeFilteredProducerFavorites(requirementId, categoryId) {
    return {
        type: actionTypes.REMOVE_FROM_FILTERED_REQUIREMENTS,
        removedRequirementId: requirementId,
        removedCategoryId: categoryId
    };
}

async function removeProducersFavorites(requirementId) {
    return await fetch(SERVER_URL + '/favorites/producer/deleteAssociation', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "requirementId": requirementId
        })
    });
}


/*requirementsIds must be an array like [1,2,3]*/
export function saveRequirementAssociation(requirementIds) {
    return async (dispatch) => {
        dispatch(saveRequirementAssociationStart());
        try {
            const response = await saveAssociation(requirementIds);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(saveRequirementAssociationSuccess(response));
            dispatch(requirementAssociationSuccessMessage());
        } catch (error) {
            dispatch(saveRequirementAssociationFail(error))
        }
    };
}

function saveRequirementAssociationStart() {
    return {
        type: actionTypes.SAVE_REQUIREMENT_ASSOCIATION_START
    };
}

function saveRequirementAssociationSuccess(response) {
    return {
        type: actionTypes.SAVE_REQUIREMENT_ASSOCIATION_SUCCESS,
        savedRequirementAssociations: response.status === 201,
        allRequirementAssociationsInDatabase: response.status === 204
    };
}

function requirementAssociationSuccessMessage() {
    return {
        type: actionTypes.SHOW_INFO,
        text: 'SAVED_TO_MY_REQUIREMENT_MSG',
        info: true
    }
}

function saveRequirementAssociationFail(error) {
    return {
        type: actionTypes.SAVE_REQUIREMENT_ASSOCIATION_FAIL,
        error: error
    };
}

async function saveAssociation(requirementIds) {
    return await fetch(SERVER_URL + '/favorites/customer/createAssociation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "requirementIds": requirementIds
        })
    });
}

export function removeRequirementAssociation(requirementId, categoryId) {
    return async (dispatch) => {
        dispatch(removeRequirementAssociationStart());
        try {
            const response = await removeAssociation(requirementId);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(removeRequirementAssociationSuccess(requirementId, categoryId));
        } catch (error) {
            dispatch(removeRequirementAssociationFail(error))
        }
    };
}

function removeRequirementAssociationStart() {
    return {
        type: actionTypes.REMOVE_REQUIREMENT_ASSOCIATION_START
    };
}

function removeRequirementAssociationSuccess(requirementId, categoryId) {
    return {
        type: actionTypes.REMOVE_REQUIREMENT_ASSOCIATION_SUCCESS,
        categoryId: categoryId,
        removedRequirementId: requirementId
    };
}

function removeRequirementAssociationFail(error) {
    return {
        type: actionTypes.SHOW_ERROR,
        error: error
    };
}

async function removeAssociation(requirementId) {
    return await fetch(SERVER_URL + '/favorites/customer/deleteAssociation/', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "requirementId": requirementId
        })
    });
}

export function clearSavedRequirementAssociations() {
    return {
        type: actionTypes.CLEAR_SAVED_REQUIREMENT_ASSOCIATIONS,
        savedRequirementAssociations: false,
        allRequirementAssociationsInDatabase: false
    };
}

function redirectUnauthorized() {
    return {
        type: actionTypes.HTTP_STATUS_UNAUTHORIZED
    };
}
