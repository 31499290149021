import * as actionTypes from '../actions/actionTypes';
const initialState = {
    customerList: null,
    profile: {},
    loading: true,
    error: null
};

const producerReducer = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.LOAD_PRODUCER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.LOAD_PRODUCER_SUCCESS:
            return {
                ...state,
                profile: action.profile,
                loading: false,
                error: null
            };
        case actionTypes.LOAD_PRODUCER_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case actionTypes.LOAD_CUSTOMER_LIST:
            return {
                ...state,
                customerList: action.customerList
            }
        default:
            return state;
    }
};
export default producerReducer;