import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import './AdminMainView.css';
import RequirementViewBox from '../../components/Requirements/RequirementViewBox/RequirementViewBox';
import {
    submitRequirement,
    administratorRequirements,
    requirementStatus
} from '../../store/actions/RequirementAction';
import {productAreas} from '../../store/actions/ProductAreaAction';
import {categories} from '../../store/actions/CategoryAction';
import {supportedLanguages} from '../../store/actions/SupportedLanguagesAction';
import Button from '../../components/UI/Button/Button';
import NamedTextArea from '../../components/NamedTextArea/NamedTextArea';
import RequirementDropDownForm from '../../components/Requirements/RequirementForm/RequirementDropDownForm';
import cross from '../../assets/images/plus-circle-solid.svg';
import cancel from '../../assets/images/window-close-solid.svg';


class AdminMainView extends Component {

    state = {
        filteredRequirements: '',
        selectedProductAreaName: 'All',
        selectedCategoryName: 'All',
        selectedStatus: 'All',
        saveProductAreaName: '',
        saveCategoryName: '',
        saveStatus: '',
        textareaValue: {},
        newRequirement: ''
    }

    componentDidMount() {
        this.props.onLoadingRequirement();
        this.props.onLoadingRequirementStatus();
        this.props.onLoadingProductAreas(this.props.account.user.languageCode);
        this.props.onLoadingCategories(this.props.account.user.languageCode);
        this.props.onLoadingSupportedLanguages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requirements !== this.props.requirements) {
            this.filterRequirements();
        }
    }

    handleSubmit = (event) => {
        this.filterRequirements();
        event.preventDefault();
    }

    filterRequirements() {
        let requirements = this.state.selectedProductAreaName !== 'All' ? this.props.requirements.filter(requirement => requirement.productArea.name === this.state.selectedProductAreaName) : this.props.requirements;
        requirements = this.state.selectedCategoryName !== 'All' ? requirements.filter(requirement => requirement.category.name === this.state.selectedCategoryName) : requirements;
        requirements = this.state.selectedStatus !== 'All' ? requirements.filter(requirement => requirement.status === this.state.selectedStatus) : requirements;
        this.setState({filteredRequirements: requirements});
    }

    handleProductNameChange = (event) => {
        this.setState({selectedProductAreaName: event.target.value})
    }

    handleCategoryNameChange = (event) => {
        this.setState({selectedCategoryName: event.target.value})
    }

    handleStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value})
    }

    handleProductNameSave = (event) => {
        this.setState({saveProductAreaName: event.target.value})
    }

    handleCategoryNameSave = (event) => {
        this.setState({saveCategoryName: event.target.value})
    }

    handleStatusSave = (event) => {
        this.setState({saveStatus: event.target.value})
    }

    saveRequirement = () => {
        const selectedProductArea = this.state.saveProductAreaName ? this.state.saveProductAreaName : this.props.productAreas[0].name;
        const selectedCategory = this.state.saveCategoryName ? this.state.saveCategoryName : this.props.categories[0].name;
        const selectedStatus = this.state.saveStatus ? this.state.saveStatus : this.props.statusList[0];
        const requirement = {
            status: selectedStatus,
            productArea: this.props.productAreas.find(productArea => productArea.name === selectedProductArea),
            category: this.props.categories.find(category => category.name === selectedCategory),
            languageCode: this.props.account.user.languageCode,
            description: this.state.textareaValue[this.props.account.user.languageCode],
            multiLanguageDescriptions: this.state.textareaValue
        }
        this.props.onSubmitRequirement(requirement);
        this.handleNewRequirement();
    }

    changeTextAreaHandler = (name, value) => {
        const textareaState = {...this.state.textareaValue};
        textareaState[name] = value;
        this.setState({textareaValue: textareaState});
    }

    handleNewRequirement() {
        this.setState({newRequirement: !this.state.newRequirement});
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                {this.buildFilterMenu(t)}
                {this.state.newRequirement ? this.buildNewRequirement(t) :
                    <img className='Cross' src={cross} alt='' onClick={() => this.handleNewRequirement()}/>}
                {this.buildRequirements()}
            </div>
        );
    }

    buildNewRequirement() {
        return <div className='NewRequirement'>
            <RequirementDropDownForm
                selectedProductArea={this.state.saveProductAreaName}
                productAreaValues={this.props.productAreas}
                productAreaChange={() => this.handleProductNameSave}
                selectedCategory={this.state.saveCategoryName}
                categoriesValue={this.props.categories}
                categoriesChange={() => this.handleCategoryNameSave}
                selectedStatus={this.state.saveStatus}
                statusValues={this.props.statusList.map((value, index) => ({
                    id: index + 1,
                    name: value
                }))}
                statusChange={() => this.handleStatusSave}
            />
            <div className='MultiLanguageDescriptions'>
                {this.props.languages.map(language => <NamedTextArea placeholder={language}
                                                                     name={language}
                                                                     row={10}
                                                                     eventHandler={this.changeTextAreaHandler}/>)}
            </div>
            <div>
                <img className='Cancel' src={cancel} alt='' onClick={() => this.handleNewRequirement()}/>
                <Button title='save' clicked={this.saveRequirement}/>
            </div>
        </div>
    }

    buildFilterMenu() {
        return <div className='RequirementFilter'>
            <form onSubmit={this.handleSubmit}>
                <RequirementDropDownForm
                    selectedProductArea={this.state.selectedProductAreaName}
                    productAreaValues={this.pushAllToDropDownValues(this.props.productAreas)}
                    productAreaChange={() => this.handleProductNameChange}
                    selectedCategory={this.state.selectedCategoryName}
                    categoriesValue={this.pushAllToDropDownValues(this.props.categories)}
                    categoriesChange={() => this.handleCategoryNameChange}
                    selectedStatus={this.state.selectedStatus}
                    statusValues={this.prepareStatusForDropDown()}
                    statusChange={() => this.handleStatusChange}
                />
                <Button type="submit" title="OK"/>
            </form>
        </div>;
    }

    prepareStatusForDropDown() {
        const mappedStatus = this.props.statusList.map((value, index) => ({id: index + 1, name: value}));
        return this.pushAllToDropDownValues(mappedStatus);
    }

    buildRequirements() {
        if (this.state.filteredRequirements) {
            return this.state.filteredRequirements.map(requirement => <RequirementViewBox key={requirement.id}
                                                                                          requirement={requirement}/>)
        }
        return this.props.requirements.map(requirement => <RequirementViewBox key={requirement.id}
                                                                              requirement={requirement}/>);
    }

    pushAllToDropDownValues(values) {
        let copyValues = [...values];
        copyValues.push({id: 0, name: 'All'})
        return copyValues;
    }
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        requirements: state.requirementsData.adminRequirements,
        statusList: state.requirementsData.statusList,
        productAreas: state.productAreaData.productAreas,
        categories: state.categoryData.categories,
        languages: state.supportedLanguages.languages
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingRequirement: () => dispatch(administratorRequirements()),
        onEditRequirement: (requirement) => dispatch(submitRequirement(requirement)),
        onLoadingRequirementStatus: () => dispatch(requirementStatus()),
        onLoadingProductAreas: (languageCode) => dispatch(productAreas(languageCode)),
        onLoadingCategories: (languageCode) => dispatch(categories(languageCode)),
        onLoadingSupportedLanguages: () => dispatch(supportedLanguages()),
        onSubmitRequirement: (requirement) => dispatch(submitRequirement(requirement))
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AdminMainView));
