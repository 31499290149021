import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {requirements} from '../../store/actions/RequirementAction';
import {requirementResponses} from '../../store/actions/RequirementResponseAction';
import {calculateEditStatus} from '../../utility/CategoryStatusCalculator';
import {buildRequirementMapWithResponses} from '../../utility/RequirementHandler';
import {loadProducerInfo} from '../../store/actions/ProducerAction';
import {saveToProducersFavorites} from '../../store/actions/FavoritesAction';
import {
    clearRequirementSelection,
    selectRequirement,
    selectAllRequirement
} from '../../store/actions/RequirementSelectionAction';
import {showError, showInfo} from '../../store/actions/MessageAction';
import {topScroll} from '../../utility/UxHelper';

import ProducerFilterArea from '../../components/Filter/ProducerFilterArea/ProducerFilterArea';
import ProducerToggleRequirementView
    from '../../components/Requirements/ProducerToggleRequirementView/ProducerToggleRequirementView';
import RequirementToolbar from '../../components/Toolbar/RequirementToolbar';
import {createExcelTranslationMap} from '../../utility/UiHelper';
import {clearToggleAllRequirement} from '../../store/actions/ToggleRequirementAction';
import {clearToggleAllCategories} from '../../store/actions/ToggleCategoriesAction';


class ProducerMainView extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props
        this.translationMap = createExcelTranslationMap(t);

    }

    state = {
        requirementMapWithResponses: {},
        requirementsEditStatus: {}
    }

    componentDidMount() {
        if (this.props.account.productArea !== undefined) {
            this.props.onLoadingRequirement(this.props.account.productArea.id, this.props.account.user.languageCode);
            this.props.onLoadingRequirementResponses(this.props.account.id, this.props.account.productArea.id, this.props.account.user.languageCode);
            this.props.onLoadProducerInfo(this.props.account.id);
        }
        this.props.onClearToggleAllCategories();
        this.props.onClearToggleAllRequirements();
        topScroll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let requirementMapWithResponses;
        if (prevProps.requirementResponseList !== this.props.requirementResponseList) {
            requirementMapWithResponses = this.buildRequirementMapWithResponses();
            this.setState({requirementMapWithResponses: requirementMapWithResponses});
        }
    }

    buildRequirementMapWithResponses() {
        let requirementMapWithResponses = {};
        if (this.props.requirementMap === undefined) {
            return;
        }
        requirementMapWithResponses = buildRequirementMapWithResponses(this.props.requirementMap, this.props.requirementResponseList);
        this.changeRequirementsEditStatus(requirementMapWithResponses);
        return requirementMapWithResponses;
    }

    changeRequirementsEditStatus(requirementMapWithResponses) {
        let categoryRequirementsEditStatus = {};
        for (const categoryKey in requirementMapWithResponses) {
            categoryRequirementsEditStatus[categoryKey] = calculateEditStatus(requirementMapWithResponses[categoryKey]);
        }
        this.setState({requirementsEditStatus: categoryRequirementsEditStatus});
    }

    saveToFavorites(t) {
        if (this.props.selectedRequirementIds.length === 0) {
            this.props.showSavingRequirementsToFavoritesError(t('MY_REQUIREMENTS_INFORMATION_MSG'));
            return;
        }
        this.props.onSavingRequirementsToFavorites(this.props.selectedRequirementIds);
        this.props.showSavingRequirementMsg(t('SAVE_TO_MY_FAVORITES_SUCCESS_MSG'));
        this.props.onClearRequirementSelection();
    }

    selectAllRequirements() {
        let requirementCollection = [];
        const masterMap = (this.props.filteredMap ? this.props.filteredMap : this.state.requirementMapWithResponses);
        for (const key in masterMap) {
            const element = masterMap[key];
            requirementCollection = requirementCollection.concat(...element)
        }
        this.props.onAllRequirementSelection(requirementCollection, this.props.allRequirementSelected, this.translationMap);
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <RequirementToolbar saveToFavoritesButtonTitle={t('SAVE_TO_MY_FAVORITES')}
                                    saveToMyRequirements={() => this.saveToFavorites(t)}/>
                <ProducerFilterArea requirementMapWithResponses={this.state.requirementMapWithResponses}/>
                <ProducerToggleRequirementView requirementsWithResponses={this.state.requirementMapWithResponses}
                                               categoryEditStatus={this.state.requirementsEditStatus}
                                               selectRequirementAction={(requirement) => this.props.onRequirementSelection(requirement, this.translationMap)}
                                               selectedRequirementIds={this.props.selectedRequirementIds}
                                               selectAllRequirementAction={() => this.selectAllRequirements()}
                                               allRequirementSelected={this.props.allRequirementSelected}
                />
            </>
        );
    };
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        requirementMap: state.requirementsData.approvedRequirements,
        requirementResponseList: state.requirementResponses.allRequirementResponses,
        loading: state.requirementResponses.loading,
        filter: state.filteredRequirement.filter,
        filteredMap: state.filteredRequirement.filteredRequirements,
        selectedRequirementIds: state.selectedRequirement.selectedRequirementIds,
        allRequirementSelected: state.selectedRequirement.allRequirementSelected,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingRequirement: (productAreaId, languageCode) => dispatch(requirements(productAreaId, languageCode)),
        onLoadingRequirementResponses: (accountId, productAreaId, languageCode) => dispatch(requirementResponses(accountId, productAreaId, languageCode)),
        onLoadProducerInfo: (id) => dispatch(loadProducerInfo(id)),
        onSavingRequirementsToFavorites: (requirementIds) => dispatch(saveToProducersFavorites(requirementIds)),
        showSavingRequirementsToFavoritesError: (text) => dispatch(showError({message: text}, text)),
        showSavingRequirementMsg: (text) => dispatch(showInfo({message: text}, text)),
        onClearRequirementSelection: () => dispatch(clearRequirementSelection()),
        onRequirementSelection: (requirement, translationMap) => dispatch(selectRequirement(requirement, translationMap)),
        onAllRequirementSelection: (requirementCollection, allSelected, translationMap) => dispatch(selectAllRequirement(requirementCollection, allSelected, translationMap)),
        onClearToggleAllRequirements: () => dispatch(clearToggleAllRequirement()),
        onClearToggleAllCategories: () => dispatch(clearToggleAllCategories())
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProducerMainView));
