import React from 'react';

import './Logo.css'
import atCrisLogo from '../../assets/images/ATCRIS_CONNECT.png';

const logo = () => (
    <div className='StickyTop'>
        <img className='Logo' src={atCrisLogo} alt='AT CRIS Consulting & Implementation'/>
    </div>
);

export default logo;
