import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';
import {showUploadErrorMessage,extractedAssetFrom} from '../../utility/AssetHandler';

export const loadProducerInfo = (id) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await getProducerInfo(id);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const producer = await response.json();
                dispatch(loadingSuccess(producer));
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
//update after table content update, documents update
const loadProducerInfoAfterUpdate = (id) => {
    return async (dispatch) => {
        try {

            const response = await getProducerInfo(id);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const producer = await response.json();
                dispatch(loadingSuccess(producer));
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const updateProducerInfo = (id, body) => {
    return async (dispatch) => {
        try {
            const response = await editProducerInfo(id, body);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const producer = await response.json();

                dispatch(loadingSuccess(producer));
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const updateContactPerson = (producerId,contactPersonId, body) => {
    return async (dispatch) => {
        try {
            const response = await editPerson(contactPersonId, body);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const person = await response.json();
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const addContactPerson = (producerId,body) => {
    return async (dispatch) => {
        try {
            const response = await addPerson(body);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const person = await response.json();
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const deleteContactPerson = (id, producerId) => {
    return async (dispatch) => {
        try {
            const response = await deletePerson(id);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const downloadDocument = (id, documentName) => {
    return async (dispatch) => {
        try {
            const response = await downloadDoc(id);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                extractedAssetFrom(response.body.getReader(), documentName);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const addDocument = (id, body) => {
    return async (dispatch) => {
        try {
            const response = await addDoc(body);
            if (response.status >= 400) {
                const message = await response.json();
                dispatch(showUploadErrorMessage(message));
            } else {

                await loadProducerInfoAfterUpdate(id)(dispatch);
            }
        } catch (error) {
             dispatch(showUploadErrorMessage({message: 'ERROR_UPLOAD_DOCUMENT'}));
        }
    }
}

export const uploadLogo = (id, body, type) => {
    return async (dispatch) => {
        try {
            const response = await uploadLogoRequest(id, body, type);
            if (response.status >= 400) {
                const message = await response.json();
                if (message.LOGO_TYPES && message.LOGO_MAX_SIZE) {
                    dispatch(showUploadErrorMessage('ERROR_LOGO_TYPES_MAX_SIZE'));

                } else if (message.message) {
                    dispatch(showUploadErrorMessage(message.message));
                } else if (message.LOGO_TYPES) {
                    dispatch(showUploadErrorMessage('ERROR_LOGO_TYPE'));
                } else {
                    dispatch(showUploadErrorMessage('ERROR_LOGO_MAX_SIZE'));
                }

            } else {
                await loadProducerInfoAfterUpdate(id)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}



export const deleteDocument = (docId, prodId) => {
    return async (dispatch) => {
        try {
            const response = await deleteDoc(docId, prodId);
            if (response.status >= 400) {
                dispatch(loadingFail(response.statusText));
            } else {
                await loadProducerInfoAfterUpdate(prodId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const deleteLogo = (id, type) => {
    return async (dispatch) => {
        try {
            const response = await deleteLogoRequest(id, type);
            if (response.status >= 400) {
                dispatch(loadingFail(response.statusText));
            } else {
                await loadProducerInfoAfterUpdate(id)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const addPrice = (body, producerId) => {
    return async (dispatch) => {
        try {
            const response = await createPrice(body);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const person = await response.json();
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const updatePrice = (body, producerId) => {
    return async (dispatch) => {
        try {
            const response = await editPrice(body);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const person = await response.json();
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const deletePrice = (id, producerId) => {
    return async (dispatch) => {
        try {
            const response = await delPrice(id);
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                await loadProducerInfoAfterUpdate(producerId)(dispatch);
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const loadInterestedInList = () => {
    return async (dispatch) => {
        try {
            const response = await customerList();
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                const interestedInList = await response.json();
                dispatch(loadingCustomerList(interestedInList))
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const updateProducerLoginInformation = (object) => {
    return async (dispatch) => {
        try {
            dispatch(updateProducerInformation(object));
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

function loadingStart() {
    return {
        type: actionTypes.LOAD_PRODUCER_START
    }
}

function loadingSuccess(producerProfile) {
    return {
        type: actionTypes.LOAD_PRODUCER_SUCCESS,
        profile: producerProfile
    }
}

function loadingFail(error) {
    return {
        type: actionTypes.LOAD_PRODUCER_FAIL,
        error: error
    }
}

function loadingCustomerList(list) {
    return {
        type: actionTypes.LOAD_CUSTOMER_LIST,
        customerList: list
    }
}

function updateProducerInformation(user) {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        valid: true,
        account: user
    };
}


async function getProducerInfo(id) {
    const response = await fetch(`${SERVER_URL}/producers/load/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    })
    return response
}

async function editProducerInfo(id, body) {
    const response = await fetch(`${SERVER_URL}/producers?producerId=${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}

async function editPerson(id, body) {
    const response = await fetch(`${SERVER_URL}/persons?personId=${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}

async function addPerson(body) {
    const response = await fetch(`${SERVER_URL}/persons`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}


async function deletePerson(id) {
    const response = await fetch(`${SERVER_URL}/persons?personId=${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    })
    return response
}

async function downloadDoc(id) {
    const response = await fetch(`${SERVER_URL}/assets/download?assetId=` + id + '&assetType=ProducerProfile', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
        },
        credentials: 'include'
    })
    return response
}


async function addDoc(body) {
    const response = await fetch(`${SERVER_URL}/assets/upload`, {
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache'
        },
        credentials: 'include',
        body: body
    })
    return response
}

async function deleteDoc(id, producerId) {
    const response = await fetch(`${SERVER_URL}/assets/delete?assetId=${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            ownerId: producerId,
            assetType: 'ProducerProfile'
        })
    })
    return response
}

async function createPrice(body) {
    const response = await fetch(`${SERVER_URL}/prices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}

async function editPrice(body) {
    const response = await fetch(`${SERVER_URL}/prices`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}

async function delPrice(id) {
    const response = await fetch(`${SERVER_URL}/prices?priceId=${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    })
    return response
}

async function uploadLogoRequest(id, body, type) {
    const response = await fetch(`${SERVER_URL}/logo`, {
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'X-ProducerId': `${id}`,
            'X-LogoType': `${type}`
        },
        credentials: 'include',
        body: body
    })
    return response
}

async function customerList() {
    const response = await fetch(`${SERVER_URL}/customers`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    })
    return response
}

async function deleteLogoRequest(id, type) {
    const response = await fetch(`${SERVER_URL}/logo?producerId=${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-ProducerId': `${id}`,
            'X-LogoType': `${type}`
        },
        credentials: 'include'
    })
    return response
}
