import React from 'react';
import MenuItem from '../UI/DropDownMenu/MenuItem/MenuItem';
import ReactExport from 'react-data-export';

/**
 *
 * @param props:
 * title: for the excel export button
 * dataSet: information for the excel file
 * name: of the excel sheet
 * @returns {JSX.Element}
 */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const requirementExcelExportFile = (props) => {
    return <ExcelFile element={<MenuItem title={props.title}/>}>
        <ExcelSheet dataSet={props.dataSet} name={props.name}/>
    </ExcelFile>;
}

export default requirementExcelExportFile;
