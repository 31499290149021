import React from 'react';

const aboutLink = (props) => {

    return (
        <>
            <a target="_blank" rel="noopener noreferrer" href={props.href}
               style={link}>{props.text}
                {props.children}
            </a>
        </>
    )
}

const link = {
    textDecoration: 'none',
    color: 'black'
};

export default aboutLink;
