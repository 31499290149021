import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import '../UI/DropDownMenu/DropDownMenu.css'
import AngleButton from '../UI/Button/AngleButton';

import RequirementExcelExportFile from './RequirementExcelExportFile';

/**
 * props:
 */
class ExcelExportDropDownMenu extends Component {
    state = {
        showDrop: false
    }

    onClickedDropHandler = () => {
        this.setState({showDrop: !this.state.showDrop});
    }

    render() {
        const {t} = this.props;
        return (
            <div className='DropDownMenu'>
                <div style={title}>
                    <AngleButton title={this.props.title} clicked={this.onClickedDropHandler}
                                 direction={this.state.showDrop}/>
                </div>
                {this.state.showDrop ?
                    <div className='Menu' onMouseLeave={this.onClickedDropHandler}>
                        <div onClick={this.props.activityCountDownloadedRequirements}>
                            <RequirementExcelExportFile title={t('EXCEL_EXPORT_WITHOUT_RESPONSES')}
                                                        dataSet={this.props.requirements}
                                                        name={t('EXCEL_EXPORT_DATA_SHEET')}/>
                        </div>
                        <div onClick={this.props.activityCountExportedRequirementsAndAnswers}>
                            <RequirementExcelExportFile title={t('EXCEL_EXPORT_WITH_RESPONSES')}
                                                        dataSet={this.props.requirementsWithResponses}
                                                        name={t('EXCEL_EXPORT_DATA_SHEET')}/>
                        </div>
                    </div>
                    : ''}
            </div>
        )
    }

}

const title = {
    display: 'flex'
,
    flexDirection: 'row'
}

export default withTranslation()(ExcelExportDropDownMenu);
