import React from 'react';
import Modal from 'react-modal';
import {useTranslation} from 'react-i18next';
import '../AllModal.css';
import GeneralModal from '../GeneralModal';
import AboutInfo from './AboutInfo/AboutInfo';

Modal.setAppElement('#root');
const infoModal = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();
    return (
        <div>
            <GeneralModal header='ABOUT_CONNECT'
                          isOpen={true}
                          close={props.showAbout}>
                <AboutInfo/>
            </GeneralModal>
        </div>
    )
}

export default infoModal;
