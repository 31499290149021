import React, {Component} from 'react';

import './AssetHandler.css';
import Documents from './Documents/Documents';
import AssetInput from './AssetInput';

/**
 * props:
 * documents: all documents shown for user
 * ownerId: id from the owner of the documents
 * editable: boolean documents editable
 * documentTyp: shows to what kind of typ the document belongs. E.g. ProducerProfile or RequirementResponses
 * uploadDocumentAction: to load the right document
 * deleteDocument: delete assets
 * downloadDocument: download assets
 * requirementId: id to get the match between response and requirement
 */
class AssetHandler extends Component {

    state = {
    }

    render() {
        return (<>
            <div className='UploadDocument'>
                {this.props.editable ? this.assetInput() : ''}
                <Documents documents={this.props.documents}
                           ownerId={this.props.ownerId}
                           editable={this.props.editable}
                           deleteDocument={this.props.deleteDocument}
                           downloadDocument={this.props.downloadDocument}/>
            </div>
        </>)
    }

    assetInput() {
        return <AssetInput uploadAsset={this.props.uploadDocumentAction}
                           ownerId={this.props.ownerId}
                           assetType={this.props.documentTyp}
                           requirementId={this.props.requirementId}/>;
    }
}

export default AssetHandler;
