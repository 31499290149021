import {SERVER_URL} from "../config";


export function sendFilterActivity(producerIds, account) {
    try {
        const countryId = (account.countryId != null && account.countryId > 0 ? account.countryId : 1);
        const response = fetch(`${SERVER_URL}/activity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "customerCountryId": countryId,
                    "affectedProducerIds": producerIds,
                    "affectedRequirementIds": null,
                    "customerId": account.id,
                    "eventType": 'EVENT_FILTER_PRODUCER'
                })
            }
        );
        if (response.status >= 400) {
            console.log(response.statusText);
            //Das kann man dem Anwender nicht melden. Jedoch dürfte die Anwendung gleich nicht mehr funktionieren.
        }
    } catch (error) {
        console.log(error); //Tja. Traue nur der Statistik, die du selbst gefälscht hast.
    }
}

// + 1 Eintrag in activitycount wenn ein requirement via XLS exportiert wurde
// (Datum, Requirement, country des customer, customer, Aktion=EVENT_DOWNLOAD_REQUIREMENT)
export function sendDownloadRequirementsActivity(affectedRequirementIds) {
    console.log("sendDownloadRequirementsActivity:" + affectedRequirementIds);
    if (!affectedRequirementIds || affectedRequirementIds.length === 0) return null;
    try {
        const response = fetch(`${SERVER_URL}/activity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "affectedRequirementIds": affectedRequirementIds,
                    "eventType": 'EVENT_DOWNLOAD_REQUIREMENT'
                })
            }
        );
        if (response.status >= 400) {
            //Das kann man dem Anwender nicht melden. Jedoch dürfte die Anwendung gleich nicht mehr funktionieren.
            console.log(response.statusText);
        }
    } catch (error) {
        console.log(error); //Tja. Traue nur der Statistik, die du selbst gefälscht hast.
    }
}

//+ 1 Eintrag in activitycount wenn eine Antwort eines producer via XLS exportiert wurde
// (Datum, Requirement, country des customer, customer, producer, Aktion=EVENT_DOWNLOAD_RESPONSE)
export function sendDownloadResponsesActivity(affectedRequirementIds, affectedResponseIds) {
    sendDownloadRequirementsActivity(affectedRequirementIds);
    console.log("sendDownloadResponsesActivity:" + affectedResponseIds);
    if (!affectedResponseIds || affectedResponseIds.length === 0) return null;
    try {
        const response = fetch(`${SERVER_URL}/activity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "affectedResponseIds": affectedResponseIds,
                    "eventType": 'EVENT_DOWNLOAD_RESPONSE'
                })
            }
        );
        if (response.status >= 400) {
            console.log(response.statusText);
            //Das kann man dem Anwender nicht melden. Jedoch dürfte die Anwendung gleich nicht mehr funktionieren.
        }
        return response;
    } catch (error) {
        console.log(error); //Tja. Traue nur der Statistik, die du selbst gefälscht hast.
    }
    return null;
}

// 1 Eintrag in activitycount wenn eine Antwort eines producer gelesen wurde
// (Datum, Requirement, producer, country des customer, customer, Aktion=EVENT_READ_RESPONSE_PRODUCER)
export function sendReadResponseActivity(responseIds) {
    try {
        const response = fetch(`${SERVER_URL}/activity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "customerCountryId": null,
                    "affectedProducerIds": null,
                    "affectedRequirementIds": null,
                    "affectedResponseIds": responseIds,
                    "customerId": null,
                    "eventType": 'EVENT_READ_RESPONSE'
                })
            }
        );
        if (response.status >= 400) {
            console.log(response.statusText);
            //Das kann man dem Anwender nicht melden. Jedoch dürfte die Anwendung gleich nicht mehr funktionieren.
        }
    } catch (error) {
        console.log(error); //Tja. Traue nur der Statistik, die du selbst gefälscht hast.
    }
}
