import React, {Component} from 'react';

import './ToggleView.css';
import check from '../../../assets/images/check-solid.svg';
import pen from '../../../assets/images/pen-solid.svg';
import exclamation from '../../../assets/images/exclamation-solid.svg';
import {
    STILL_ONE_REQUIREMENT_NOT_EDIT,
    STILL_ONE_REQUIREMENT_NOT_PUBLISHED
} from '../../../utility/CategoryStatusCalculator';

/**
 * @params props
 * color= default color is a light grey. Other options are color='Toggle-Orange'
 * openToggleHandler= Method to toggle the header
 * iconStatus= shows the status of the processed requirements
 */
class ToggleView extends Component {

    statusIcon() {
        if (this.props.iconStatus === '' || this.props.iconStatus === undefined) {
            return;
        }
        if (this.props.iconStatus === STILL_ONE_REQUIREMENT_NOT_EDIT) {
            return <img className='StatusIcon StatusExclamation' src={exclamation} alt=''/>;
        }
        if (this.props.iconStatus === STILL_ONE_REQUIREMENT_NOT_PUBLISHED) {
            return <img className='StatusIcon StatusPen' src={pen} alt=''/>;
        }
        return <img className='StatusIcon StatusCheck' src={check} alt=''/>;
    }

    getClassName() {
        if (this.props.toggle) {
            return ['Toggle-Head Toggle-Active', this.props.color].join(' ');
        }
        return ['Toggle-Head', this.props.color].join(' ');
    }

    getDisplayStyle() {
        if (this.props.toggle) {
            return 'block';
        }
        return 'none'
    }

    render() {
        return (
            <div className='Toggle'>
                <div className={this.getClassName()} onClick={this.props.openToggleHandler}>
                    <h3>{this.props.heading}</h3>
                    {this.statusIcon()}
                    <span className={['Toggle-Icon', this.props.color].join(' ')}/>
                </div>
                <div className='Toggle-Content' style={{display: this.getDisplayStyle()}}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default ToggleView;
