import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './CustomerList.css';
import { useTranslation } from 'react-i18next';

import { loadInterestedInList } from '../../../store/actions/ProducerAction';
import { loadCountries } from '../../../store/actions/CountriesAction';
import * as UxHelper from '../../../utility/UxHelper';

const InterestedInProductList = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(()=>{
        dispatch( loadInterestedInList() )
    }, [])

    const countries = useSelector(state => state.countries);
    const customerList = useSelector( state=> state.producer.customerList );
    useEffect(() =>{
        if(!countries) dispatch(loadCountries());
    },[]);

    useEffect(() =>{
        UxHelper.topScroll();
    },[]);

    return (
        <div className = "interested-ind-list-container" >
            <table>
                <thead>
                    <tr>
                        <th>
                            {t("CUSTOMER_LIST_INTERESTED_IN_PRODUCT")}
                        </th>
                        <th>
                            {t("CUSTOMER_LIST_COUNTRY")}
                        </th>
                        <th>
                            {t("CUSTOMER_LIST_PERSON_COUNT")}
                        </th>
                        <th>
                            {t("CUSTOMER_LIST_CONTACT_DETAILS")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        customerList ?
                            customerList.map((customer)=>
                                <tr key = {customer.id} >
                                    <td>
                                        { customer.name }
                                    </td>
                                    <td>
                                        <select  disabled   value = { customer.countryId ? customer.countryId  : "" } >
                                          {countries?
                                            countries.map(country=>(
                                              <option key={country.id} value = {country.id}>
                                                {
                                                  i18n.exists(country.name) ? (
                                                    t(country.name)
                                                  ): country.name
                                                }
                                              </option>
                                            )): null
                                          }
                                        </select>
                                    </td>
                                    <td>
                                        { customer.personCount }
                                    </td>
                                    <td>
                                        { customer.email }
                                    </td>
                                </tr>
                            ) : null
                    }
                </tbody>
            </table>
        </div>
    )
}

export default InterestedInProductList;
