import * as actionTypes from '../actions/actionTypes';
const initialState = {
    producerList: null,
    profile: null,
    error: null
};

const customerInfoReducer = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.LOAD_PRODUCER_LIST:
            return {
                ...state,
                producerList: action.producerList
            }
        case actionTypes.LOAD_CUSTOMER_PROFILE:
            return {
                ...state,
                profile: action.profile
            }
        case actionTypes.LOAD_CUSTOMER_INFO_FAIL:
            return {
                ...state,
                error: action.error
            }
        case actionTypes.CLEAR_PRODUCER_LIST:
            return {
                ...state,
                producerList: null
            }
        default:
            return state;
    }
};
export default customerInfoReducer;