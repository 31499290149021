import React, {Component} from 'react';
import {connect} from 'react-redux';

import Filter from '../Filter';
import FilterSection from '../FilterSection';
import FilterDateSection from '../FilterDateSection';
import {
    producerFilterSet,
    filterSectionChanged,
    filterItemChanged,
    loadProducerMainViewFilter, clearFilter
} from '../../../store/actions/RequirementFilterAction';

/**
 * requirementMapWithResponses: Map with all requirements and the corresponding answers with categories as key
 */
class ProducerFilterArea extends Component {

    componentDidMount() {
        this.prepareFilter();
    }

    prepareFilter() {
        if (this.props.filter == null) {
            this.props.onLoadFilter();
            return;
        }
        this.clearFilterSections();
    }

    clearFilterSections() {
        for (const filterSection in this.props.filter) {
            this.props.onFilterSectionChange(this.props.filter[filterSection].name, false);
        }
    }

    applyFilter() {
        this.props.onApplyFilter(this.props.filter, this.props.requirementMapWithResponses);
    }

    resetAll() {
        //direct call of props.onResetAll hs no effect.
        for (const filterSection in this.props.filter) {
            this.props.onFilterSectionChange(this.props.filter[filterSection].name, false);
        }
        this.props.onApplyFilter(this.props.filter, this.props.requirementMapWithResponses);
        this.props.onClearFilter();
    }

    render() {
        return (
            <>
                {(this.props.filter ?
                    <Filter title="FILTER_DEFAULT_TITLE" applyFilter={() => this.applyFilter()}
                            resetFilter={() => this.resetAll()}>
                        <FilterSection isSubsection={false} title=" "
                                       filterList={this.props.filter[0].filterList}
                                       handleFilter={(filterSection, event) => this.props.onFilterItemChange(filterSection, event)}/>
                        <FilterDateSection
                            title={this.props.filter[1].name}
                            filterList={this.props.filter[1].filterList}
                            handleFilter={(filterSection, event) => this.props.onFilterItemChange(filterSection, event)}/>
                    </Filter>
                    : '')}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        filter: state.filteredRequirement.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadFilter: () => dispatch(loadProducerMainViewFilter()),
        onFilterItemChange: (filterSection, event) => dispatch(filterItemChanged(filterSection, event)),
        onApplyFilter: (filter, requirementMap) => dispatch(producerFilterSet(filter, requirementMap)),
        onFilterSectionChange: (filterSection, checked) => dispatch(filterSectionChanged(filterSection, checked)),
        onClearFilter: () => dispatch(clearFilter())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProducerFilterArea);
