import {LOAD_CATEGORY_SUCCESS, LOAD_CATEGORY_START, LOAD_CATEGORY_FAIL} from '../actions/actionTypes';

const initialState = {
    categories: [],
    loading: false
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CATEGORY_START:
            return {
                ...state,
                loading: true
            }
        case LOAD_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.categories
            }
        case LOAD_CATEGORY_FAIL:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}

export default categoryReducer;
