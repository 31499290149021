import React from 'react';

import './Footer.css';
import {useTranslation} from 'react-i18next';
import PriceTable from './PriceTable/PriceTable';
import ContactPersonTable from './ContactPersonTable/ContactPersonTable';

const Footer = ({props, providerInfo, state}) => {
  const {t} = useTranslation();

    return (  
      <>
        <div className="Details-Container">
          <div className="Details-Name">
            <label >{t('PRODUCER_DETAILS')}:</label>
          </div>
          <div className="Details-Textarea">
            <textarea className="producer-profile-main-fields"  rows="7"  onChange={props.handleFields("productDescription")}  value={ state.profile.productDescription  ? state.profile.productDescription : "" } id="Details" disabled={!state.editable} />   
          </div>
        </div>
        <div className="Price-Container">
          <div className="Price-Table-Name">
            <label >
              {t("PRODUCER_PRICES")}:
            </label>
          </div>
          <div className="Price-Table">
            <PriceTable handleEvent={props.handleEvent} state={state} />
          </div>
        </div>
        <div className="Contact-Person-Container">
          <div className="Contac-Table-Name">
            <label >
              {t("PRODUCER_CONTACT_PERSONS")}
            </label>
          </div>
          <div className="Contact-Person-Table">
            <ContactPersonTable handleEvent={props.handleEvent}  state={state}  />
          </div>
        </div>
        <div className="Buttons-Space">
        </div>
      </>
    )
};
export default Footer;