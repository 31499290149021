import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import RequirementsMainView from '../RequirementsMainView';
import {
    toggleRequirementInformationList
} from '../../../store/actions/ToggleRequirementAction';
import {
    toggleCategoriesInformationList
} from '../../../store/actions/ToggleCategoriesAction';
import Checkbox from '../../UI/Checkbox/Checkbox';
import {clearRequirementSelection} from '../../../store/actions/RequirementSelectionAction';
import {clearFilter} from '../../../store/actions/RequirementFilterAction';


class ProducerToggleRequirementView extends Component {

    componentDidMount() {
        this.props.onClearRequirementSelection();
        this.props.onClearFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.buildToggleInformationLists(prevProps)) {
            this.prepareToggleCategoriesInformationList(this.props.requirementsWithResponses);
            this.prepareToggleRequirementInformationList(this.props.requirementsWithResponses);
        }
    }

    buildToggleInformationLists(prevProps) {
        return prevProps.requirementsWithResponses !== this.props.requirementsWithResponses
            && this.toggleListsEmpty();
    }

    toggleListsEmpty() {
        return this.props.toggleCategoriesList.length === 0
            && this.props.toggleRequirementList.length === 0
    }

    prepareToggleCategoriesInformationList(requirementMapWithResponses) {
        let categoryIds = [];
        for (const key in requirementMapWithResponses) {
            categoryIds = categoryIds.concat(key);
        }
        this.props.onToggleCategoriesInformationList(categoryIds);
    }

    prepareToggleRequirementInformationList(requirementMapWithResponses) {
        let requirementIds = [];
        for (const key in requirementMapWithResponses) {
            const requirements = requirementMapWithResponses[key];
            requirementIds = requirementIds.concat(requirements.map(requirement => requirement.id));
        }
        this.props.onToggleRequirementList(requirementIds);
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <Checkbox shadowBox='ShadowBox'
                          label={t('SELECT_ALL_REQUIREMENTS')}
                          clicked={this.props.selectAllRequirementAction}
                          checked={this.props.allRequirementSelected}/>
                <RequirementsMainView responseEditable={true}
                                      requirementsWithResponses={this.props.requirementsWithResponses}
                                      requirementsEditStatus={this.props.categoryEditStatus}
                                      requirementDeletable={this.props.requirementDeletable}
                                      deleteRequirement={this.props.deleteRequirement}
                                      checkboxClicked={this.props.selectRequirementAction}
                                      checkboxChecked={this.props.selectedRequirementIds}
                />
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        toggleCategoriesList: state.toggleCategories.toggleCategoriesInformationList,
        toggleRequirementList: state.toggleRequirement.toggleRequirementList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClearRequirementSelection: () => dispatch(clearRequirementSelection()),
        onClearFilter: () => dispatch(clearFilter()),
        onToggleCategoriesInformationList: (categoryIds) => dispatch(toggleCategoriesInformationList(categoryIds)),
        onToggleRequirementList: (requirementIds) => dispatch(toggleRequirementInformationList(requirementIds))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProducerToggleRequirementView));
