import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import './RequirementViewBox.css';
import RequirementDropDownForm from '../RequirementForm/RequirementDropDownForm';
import pen from '../../../assets/images/pen-solid.svg';
import cross from '../../../assets/images/window-close-solid.svg';
import trash from '../../../assets/images/trash-alt-regular.svg';
import LabeledValue from '../../Requirements/RequirementViewBox/LabeledValue/LabeledValue';
import {deleteRequirements, submitRequirement} from '../../../store/actions/RequirementAction';
import {showError} from '../../../store/actions/MessageAction';
import NamedTextArea from '../../NamedTextArea/NamedTextArea';
import Button from '../../UI/Button/Button';

/**
 * productAreaValues = product area names for the drop down
 * categoryNamesValues = category names for the drop down
 * statusNameValues = status names for the drop down
 */
class RequirementViewBox extends Component {

    state = {
        edit: false,
        selectedProductAreaName: '',
        selectedCategoryName: '',
        selectedStatus: '',
        textareaValue: {}
    }

    handleEditRequirement() {
        this.setState({edit: !this.state.edit});
    }

    handleDeleteRequirement(requirement, t) {
        const result = window.confirm(t('DELETE_REQUIREMENT_PROMPT'));
        if (result) this.props.onDeleteRequirements([requirement.id]);
    }

    handleProductAreaChange = (event) => {
        this.setState({selectedProductAreaName: event.target.value});
    }

    handleCategoryChange = (event) => {
        this.setState({selectedCategoryName: event.target.value});
    }

    handleStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    changeTextAreaHandler = (name, value) => {
        const textareaState = {...this.state.textareaValue};
        textareaState[name] = value;
        this.setState({textareaValue: textareaState});
    }

    updateRequirement = () => {
        const selectedProductArea = this.state.selectedProductAreaName ? this.state.selectedProductAreaName : this.props.requirement.productArea.name;
        const selectedCategory = this.state.selectedCategoryName ? this.state.selectedCategoryName : this.props.requirement.category.name;
        const selectedStatus = this.state.selectedStatus ? this.state.selectedStatus : this.props.requirement.status;
        const requirement = {
            ...this.props.requirement,
            status: selectedStatus,
            productArea: this.props.productAreas.find(productArea => productArea.name === selectedProductArea),
            category: this.props.categories.find(category => category.name === selectedCategory),
            multiLanguageDescriptions: {
                ...this.props.requirement.multiLanguageDescriptions,
                ...this.state.textareaValue
            }
        }
        this.props.onSubmitRequirement(requirement);
        this.handleEditRequirement();
    }

    render() {
        const {t} = this.props;
        if (this.props.requirementAssociated) {
            this.props.onShowError(t('REQUIREMENT_IS_ASSOCIATED'));
        }
        return (
            <div className='RequirementViewBox'>
                {this.state.edit ? this.editRequirement(t) : this.showRequirement(t)}
                <div className='RequirementButtons'>
                    {this.state.edit ?
                        <img className='Icon' src={cross} alt='' onClick={() => this.handleEditRequirement()}/> :
                        <img className='Icon' src={pen} alt='' onClick={() => this.handleEditRequirement()}/>}
                    <img className='Icon' src={trash} alt=''
                         onClick={() => this.handleDeleteRequirement(this.props.requirement, t)}/>
                </div>
            </div>
        );
    }

    editRequirement() {
        return <>
            <div className='RequirementProperties'>
                <div>
                    <RequirementDropDownForm
                        selectedProductArea={this.state.selectedProductAreaName ? this.state.selectedProductAreaName : this.props.requirement.productArea.name}
                        productAreaValues={this.props.productAreas}
                        productAreaChange={() => this.handleProductAreaChange}
                        selectedCategory={this.state.selectedCategoryName ? this.state.selectedCategoryName : this.props.requirement.category.name}
                        categoriesValue={this.props.categories}
                        categoriesChange={() => this.handleCategoryChange}
                        selectedStatus={this.state.selectedStatus ? this.state.selectedStatus : this.props.requirement.status}
                        statusValues={this.props.statusList.map((value, index) => ({
                            id: index,
                            name: value
                        }))}
                        statusChange={() => this.handleStatusChange}/>
                </div>
                <div className='RequirementDescription'>
                    {this.buildMultiLanguageDescriptionEdit()}
                </div>
                <Button title='save' clicked={this.updateRequirement}/>
            </div>
        </>;
    }

    buildMultiLanguageDescriptionEdit() {
        let descriptions = null;
        for (const key in this.props.requirement.multiLanguageDescriptions) {
            const value = this.props.requirement.multiLanguageDescriptions[key]
            descriptions = (
                <>
                    {descriptions}
                    <label className='LanguageLabel'>{key}</label>
                    <NamedTextArea
                        name={key}
                        row={10}
                        eventHandler={this.changeTextAreaHandler}>{value}</NamedTextArea>
                </>
            );
        }
        return <div className='Description'>{descriptions}</div>;
    }

    showRequirement(t) {
        return <>
            <div className='RequirementShow'>
                <div className='Property'>
                    <LabeledValue text={(t('PRODUCT_AREA_NAME'))} info={this.props.requirement.productArea.name}/>
                    <LabeledValue text={(t('CATEGORY_NAME'))} info={this.props.requirement.category.name}/>
                    <LabeledValue text={(t('STATUS_NAME'))} info={this.props.requirement.status}/>
                </div>
                <div className='RequirementDescription'>
                    {this.buildMultiLanguageDescriptionLabel()}
                </div>
            </div>
        </>
    }

    buildMultiLanguageDescriptionLabel() {
        let descriptions = null;
        for (const key in this.props.requirement.multiLanguageDescriptions) {
            const value = this.props.requirement.multiLanguageDescriptions[key]
            descriptions = (
                <>
                    {descriptions}
                    <label className='LanguageLabel'>{key}</label>
                    <label className='DescriptionValue'>{value}</label>
                </>
            );
        }
        return <div className='Description'>{descriptions}</div>;
    }

}

const mapStateToProps = state =>
{
    return {
        account: state.login.account
        ,
        requirementAssociated: state.requirementsData.error
        ,
        statusList: state.requirementsData.statusList
        ,
        productAreas: state.productAreaData.productAreas
        ,
        categories: state.categoryData.categories
    }
}

const mapDispatchToProps = dispatch =>
{
    return {
        onDeleteRequirements: (requirements) => dispatch(deleteRequirements(requirements)),
        onShowError: (text) => dispatch(showError({message: text}, text)),
        onSubmitRequirement: (requirement) => dispatch(submitRequirement(requirement))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequirementViewBox));
