import React from 'react';

import './Button.css';
import AngleRotationIcon from '../AngleRotationIcon/AngleRotationIcon';

/**
 *
 * @param props
 * * title='title of the button'
 * * color='default color of button is green. Other options are: color='Blue', color='DarkBlue', color='Orange'
 * * clicked={function which the button should call}
 * @returns {*}
 */
const angleButton = (props) => (
    <button className={['Button', props.color].join(' ')} onClick={props.clicked}>
        <div>
            {props.title}
        </div>
        <AngleRotationIcon clicked={props.clicked} notRotate={props.notRotate} direction={props.direction}/>
    </button>
);

export default angleButton;
