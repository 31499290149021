export const WRONG_PARAMETER_ERROR_MSG = 'Please insert values for function parameter and not undefined or null!';
export const STILL_ONE_REQUIREMENT_NOT_EDIT = 'nothingEdit';
export const STILL_ONE_REQUIREMENT_NOT_PUBLISHED = 'editInProgress';
export const ALL_REQUIREMENTS_PUBLISHED = 'allRequirementsPublished';

export function calculateEditStatus(requirements) {
    if (requirements === null || requirements === undefined) {
        throw new Error(WRONG_PARAMETER_ERROR_MSG);
    }
    if (isARequirementNotEditYet(requirements)) {
        return STILL_ONE_REQUIREMENT_NOT_EDIT;
    }
    if (isARequirementInDraftStatus(requirements)) {
        return STILL_ONE_REQUIREMENT_NOT_PUBLISHED;
    }
    return ALL_REQUIREMENTS_PUBLISHED;
}

function isARequirementNotEditYet(requirements) {
    return requirements.filter(requirement => requirement.responses === undefined || requirement.responses.length === 0).length !== 0;
}

function isARequirementInDraftStatus(requirements) {
    return requirements.filter(requirement => requirement.responses !== undefined).filter(requirement => requirement.responses[0].status === 'DRAFT').length !== 0;
}
