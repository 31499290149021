import {
    LOAD_PRODUCT_AREAS_SUCCESS,
    LOAD_PRODUCT_AREAS_START,
    LOAD_PRODUCT_AREAS_FAIL
} from './actionTypes';
import {SERVER_URL} from '../../config';

export function productAreas(languageCode) {
    return async (dispatch) => {
        dispatch(loadProductAreasStart())
        try {
            const response = await loadProductAreaNames(languageCode);
            const productAreaJson = await response.json();
            dispatch(loadProductAreasSuccess(productAreaJson.map(convertProductArea)));
        } catch (error) {
            dispatch(loadProductAreasFail(error))
        }
    }
}

function convertProductArea(productArea) {
    return {
        id: productArea.id,
        name: productArea.name
    };
}

function loadProductAreasSuccess(productAreas) {
    return {
        type: LOAD_PRODUCT_AREAS_SUCCESS,
        productAreas: productAreas
    }
}

function loadProductAreasStart() {
    return {
        type: LOAD_PRODUCT_AREAS_START
    }
}

function loadProductAreasFail(error) {
    return {
        type: LOAD_PRODUCT_AREAS_FAIL,
        error: error
    }
}

async function loadProductAreaNames(languageCode) {
    return await fetch(SERVER_URL + '/productarea/allBy?languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}
