import React from 'react';

import './MenuItem.css'

/**
 *
 * @param props
 * * title='title of menu item'
 * * clicked='action of menu item'
 * @returns {*}
 */
const menuItem = (props) => (
    <button className='MenuItem' onClick={props.clicked}>
        <div>
            {props.title}
        </div>
    </button>
);

export default menuItem;
