import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

/**
 * props
 * editable: it is possible to select an radio button
 */
class TripleRadioButton extends Component {

    render() {
        const {t} = this.props;
        return (
            <div>
                <form>
                    <label style={buttonGap}>
                        <input name='triple' type='radio' value='YES'
                               checked={this.props.currentChecked === 'YES'}
                               onChange={this.props.changed()}
                               disabled={!this.props.editable}/>
                        {t('RADIO_BUTTON_YES')}
                    </label>
                    <label style={buttonGap}>
                        <input name='triple' type='radio' value='NO'
                               checked={this.props.currentChecked === 'NO'}
                               onChange={this.props.changed()}
                               disabled={!this.props.editable}/>
                        {t('RADIO_BUTTON_NO')}
                    </label>
                    <label style={buttonGap}>
                        <input name='triple' type='radio' value='NOT_APPLICABLE'
                               checked={this.props.currentChecked === 'NOT_APPLICABLE'}
                               onChange={this.props.changed()}
                               disabled={!this.props.editable}/>
                        {t('RADIO_BUTTON_NOT_APPLICABLE')}
                    </label>
                </form>
            </div>
        )
    }
}

const buttonGap = {
    marginRight: '50px'
};

export default withTranslation()(TripleRadioButton);
