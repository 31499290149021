import React from 'react';

import pen from '../../../../assets/images/pen-solid.svg';
import trash from '../../../../assets/images/trash-alt-regular.svg';
import exclamation from '../../../../assets/images/exclamation-solid.svg';
import check from '../../../../assets/images/check-solid.svg';
import AngleRotationIcon from '../../../UI/AngleRotationIcon/AngleRotationIcon';

const requirementIconControl = (props) => {

    function statusIcon(responseStatus) {
        return <>
            {responseStatus === '' ?
                <img className='Exclamation' src={exclamation} alt=''/> : ''}
            {responseStatus === 'DRAFT' ?
                <img className='Pen' src={pen} alt=''/> : ''}
            {responseStatus === 'PUBLISHED' ?
                <img className='Icon' src={check} alt=''/> : ''}
        </>;
    }

    return (
        <div className='RequirementIconControl'>
            {props.showAnsweredRequirementStatus ? statusIcon(props.responseStatus) : ''}
            {props.editable ? <img className='Icon' src={pen} alt='' onClick={props.onClickEdit}/> : ''}
            {props.deletable ? <img className='Icon' src={trash} alt='' onClick={props.onClickDelete}/> : ''}
            {props.showAngleIcon ?
                <AngleRotationIcon clicked={props.onClickToggle} direction={props.showAnswer}/> : ''}
        </div>);
}

export default requirementIconControl
