import React, {Component} from 'react';

import './DropDown.css';

/**
 * selectedValue= value which is shown in dropdown
 * values: array of all values shown in dropdown
 * handleChange: event change
 * @param props
 * @returns {JSX.Element}
 */
class Dropdown extends Component {

    render() {
        return (
            <select className='DropDown' onChange={this.props.handleChange()} value={this.props.selectedValue}>
                {this.props.values.map(value => <option key={value.id} value={value.name}>{value.name}</option>)}
            </select>
        );
    }

    createKey(value) {
        return value + Math.floor(Math.random() * 100);
    }
}

export default Dropdown;
