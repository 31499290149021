import React, {Component} from 'react';

import './RequirementControl.css';
import RequirementIconControl from './RequirementIconControl/RequirementIconControl';
import RequirementCheckbox from './RequirementCheckbox/RequirementCheckbox';
import AnswerBox from '../../AnswerBox/AnswerBox';

/**
 *
 * @param props
 * *name='name of the input element'
 * *clicked={function }
 * *checked=boolean is checkbox checked
 * *label='text which is shown in the checkbox'
 * *deletable=boolean if the delete image is shown in checkbox
 * *delete={function to delete the information in checkbox}
 * *editable=boolean if the text in the checkbox is editable
 * *edit={function to edit the information}
 * *showAllResponses=show the answer box(es) for the requirement
 * *toggleResponses=function to show or hide the answer box for the requirement
 * *availableRequirementResponses=all possible responses for the requirement
 * responseEditable: Responses can be editable.
 * toggleResponsesInformationList
 * @returns {*}
 */
class RequirementControl extends Component {

    showRequirementText() {
        return <RequirementCheckbox id={this.props.name}
                                    label={this.props.label}
                                    clicked={this.props.clicked}
                                    checked={this.props.checked}
                                    editable={this.props.responseEditable}
        />
    }

    /**only possible for producer at the moment**/
    requirementResponseStatus(availableRequirementResponse) {
        if (availableRequirementResponse === undefined || availableRequirementResponse.length === 0) {
            return '';
        }
        return availableRequirementResponse[0].status;
    }

    iconControl() {
        return <RequirementIconControl editable={this.props.editable} onClickEdit={this.props.edit}
                                       deletable={this.props.deletable} onClickDelete={this.props.delete}
                                       showAnsweredRequirementStatus={this.props.responseEditable}
                                       showAnswer={this.props.showAllResponses}
                                       onClickToggle={this.props.toggleRequirement}
                                       responseStatus={this.requirementResponseStatus(this.props.availableRequirementResponses)}
                                       showAngleIcon={this.showAngleIcon()}/>;
    }

    showAngleIcon() {
        if (this.props.responseEditable) {
            return true;
        }
        return this.props.availableRequirementResponses !== undefined && this.props.availableRequirementResponses.length !== 0;
    }

    showAnswerBoxes() {
        if (this.props.responseEditable) {
            return <AnswerBox showAnswer={this.props.showAllResponses}
                              availableRequirementResponse={this.props.availableRequirementResponses[0]}
                              requirementId={this.props.requirementId}
                              assets={this.getAssets(this.props)}
                              editable={this.props.responseEditable}
                              comment={this.responseComment(this.props)}
            />
        }
        /*needed for customer. Show only all responses if needed*/
        return this.props.showAllResponses ?
            this.requirementResponses(this.props)
            : '';
    }

    toggleResponse(id) {
        const responsesToggleInfo = this.props.toggleResponsesInformationList.find(toggleInfo => id === toggleInfo.id);
        if (responsesToggleInfo === undefined) {
            return false;
        }
        return responsesToggleInfo.toggle;
    }

    requirementResponses(props) {
        return props.availableRequirementResponses ? props.availableRequirementResponses.map(response =>
            <AnswerBox showAnswer={this.toggleResponse(response.id)}
                       availableRequirementResponse={response}
                       comment={response.comment}
                       requirementId={props.requirementId}
                       assets={response.documents}
                       showAngle={!props.responseEditable}
                       editable={props.responseEditable}/>
        ) : '';
    }

    /*need to insert assets separately because of shallow equals from react*/
    getAssets(props) {
        if (props.availableRequirementResponses) {
            return props.availableRequirementResponses[0] ? props.availableRequirementResponses[0].documents : '';
        }
        return '';
    }

    responseComment(props) {
        if (props.availableRequirementResponses) {
            return props.availableRequirementResponses[0] ? props.availableRequirementResponses[0].comment : '';
        }
        return '';
    }

    render() {
        return (
            <div className='RequirementControl'>
                <div style={layoutCheckboxAndIcon}>
                    {this.showRequirementText()}
                    {this.iconControl()}
                </div>
                {this.showAnswerBoxes()}
            </div>
        )
    }
}

const layoutCheckboxAndIcon = {
    display: 'flex',
    justifyContent: 'space-between'
}

export default RequirementControl;
