export function buildRequirementMapWithResponses(requirementMap, requirementResponseList) {
    let requirementMapWithResponses = {};
    if (requirementMap === null || requirementMap === undefined || (Object.keys(requirementMap).length === 0 && requirementMap.constructor === Object)) {
        return requirementMapWithResponses;
    }
    for (const categoryKey in requirementMap) {

        requirementMapWithResponses[categoryKey] = addResponses(requirementMap[categoryKey], requirementResponseList);

    }
    return requirementMapWithResponses;
}

function addResponses(requirementArray, requirementResponseList) {
    return requirementArray.map(requirement => {
        return {
            ...requirement,
            responses: getResponses(requirement)
        }
    });

    function getResponses(requirement) {
        if (requirementResponseList === null || requirementResponseList === undefined) {
            return [];
        }
        return requirementResponseList.filter(response => response.requirementId === requirement.id);
    }
}

export function deepCopyResponse(response) {
    return {
        id: response.id,
        requirementId: response.requirementId,
        status: response.status,
        producerId: response.producerId,
        producerName: response.producerName,
        lastUpdated: response.lastUpdated,
        languageCode: response.languageCode,
        comment: response.comment,
        available: response.available,
        configurable: response.configurable,
        productArea: response.productArea,
        documents: response.documents
    }
}

export function deepCopyRequirement(requirement, newResponseList) {
    let requirementCopy = {
        id: requirement.id,
        label: requirement.label,
        categoryId: requirement.categoryId,
        categoryName: requirement.categoryName,
        status: requirement.status
    }
    //Use optional parameter newResponseList to replace existing requirement.responses
    if (newResponseList) requirementCopy.responses = newResponseList.map(requirement => deepCopyResponse(requirement));
    else if (requirement.responses) requirementCopy.responses = requirement.responses.map(requirement => deepCopyResponse(requirement));

    return requirementCopy;
}

export function deepCopyRequirementCategory(requirementList) {
    return requirementList.map(requirement => deepCopyRequirement(requirement));
}

export function deepCopyAll(allRequirements) {
    let requirementsMap = {};
    for (const category in allRequirements) {
        requirementsMap[category] = deepCopyRequirementCategory(allRequirements[category]);
    }
    return requirementsMap;
}

/**
 * Requirement map: Use category id as key and add converted requirement to this map
 * @param requirementsJSON
 * @returns {*}
 */
export function requirementJSONToRequirementMap(requirementsJSON) {
    if (requirementsJSON === null || requirementsJSON.length === 0) {
        return [];
    }
    return createMap(requirementsJSON.map(convertRequirement));
}

function convertRequirement(requirement) {
    return {
        id: requirement.id,
        label: requirement.description,
        categoryName: requirement.category.name,
        categoryId: requirement.category.id,
        status: requirement.status
    };
}

export function createMap(requirementList) {
    let map = {};
    if (requirementList === null) {
        return map;
    }
    for (const requirement of requirementList) {
        if (!map[requirement.categoryId]) {

            map[requirement.categoryId] = [{
                id: requirement.id,
                label: requirement.label,
                categoryId: requirement.categoryId,
                categoryName: requirement.categoryName,
                status: requirement.status
            }]
        } else {
            let value = map[requirement.categoryId];
            value.push({
                id: requirement.id,
                label: requirement.label,
                categoryId: requirement.categoryId,
                categoryName: requirement.categoryName,
                status: requirement.status
            });
        }
    }
    return map;
}

export function removeDeletedRequirement(categoryId, removedRequirementId, favoredRequirements) {
    const copiedRequirementList = copyRequirementList(favoredRequirements, categoryId);
    const isRemovedRequirement = (requirement) => requirement.id === removedRequirementId;
    copiedRequirementList.splice(copiedRequirementList.findIndex(isRemovedRequirement), 1);

    const newCustomerRequirements = {...favoredRequirements};
    newCustomerRequirements[categoryId] = copiedRequirementList;

    return newCustomerRequirements;
}

function copyRequirementList(favoredRequirements, categoryId) {
    if (!favoredRequirements.hasOwnProperty(categoryId)) {
        return [];
    }
    return [...favoredRequirements[categoryId]];
}

