import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';

import {toggleCategory} from '../../store/actions/ToggleCategoriesAction';
import {toggleRequirement} from '../../store/actions/ToggleRequirementAction';

import Spinner from '../UI/Spinner/Spinner';
import Error from '../UI/Error/Error';
import ApprovedRequirementsView from '../Requirements/ApprovedRequirementsView/ApprovedRequirementsView';

/**
 * props
 * responseEditable: Responses can be editable.
 */
class RequirementsMainView extends Component {

    buildCategoriesToggleView() {
        return <ApprovedRequirementsView
            requirements={(this.props.filteredMap ? this.props.filteredMap : (this.props.requirementsWithResponses))}
            checkboxClicked={this.props.checkboxClicked}
            checkboxChecked={this.props.checkboxChecked}
            openToggleHandler={(categoryId) => this.props.onToggleCategory(categoryId)}
            toggleInformationList={this.props.toggleCategoriesInformationList}
            toggleRequirementInformationList={this.props.toggleRequirementList}
            toggleRequirement={(requirementId) => this.props.onToggleRequirement(requirementId)}
            requirementIconStatus={this.props.requirementsEditStatus}
            responseEditable={this.props.responseEditable}
            toggleResponsesInformationList={this.props.toggleResponseList}
            requirementDeletable={this.props.requirementDeletable}
            deleteRequirement={this.props.deleteRequirement}
        />;
    }

    render() {
        const {t} = this.props;
        if (this.props.redirect) {
            return <Redirect to='/'/>
        }
        if (this.props.loading) {
            return <Spinner/>;
        }
        if (this.props.requirementsWithResponses.length === 0) {
            return <Error msg={t('ERROR_LOADING_REQUIREMENTS')}/>
        }
        return (
            <>
                {this.buildCategoriesToggleView(t)}
            </>
        );
    };
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        filteredMap: state.filteredRequirement.filteredRequirements,
        loading: state.requirementsData.loading,
        redirect: state.requirementsData.redirect,
        toggleCategoriesInformationList: state.toggleCategories.toggleCategoriesInformationList,
        toggleRequirementList: state.toggleRequirement.toggleRequirementList,
        toggleResponseList: state.toggleResponse.toggleResponsesList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleCategory: (categoryId) => dispatch(toggleCategory(categoryId)),
        onToggleRequirement: (requirementId) => dispatch(toggleRequirement(requirementId))
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequirementsMainView));
