import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';


import PasswordChangeForm from '../../../components/Forms/PasswordChangeForm/PasswordChangeForm';
import * as loginAction from '../../../store/actions/LoginAction';
import * as messageAction from '../../../store/actions/MessageAction';
import Spinner from '../../../components/UI/Spinner/Spinner';
import {identicalPasswords, isStrongPassword} from '../../../utility/PasswordValidationHandler';
import RedirectLogin from '../RedirectLogin';

class PasswordChange extends Component {

    state = {
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    }

    insertUserNameHandler(event) {
        this.setState({username: event.target.value});
    }

    insertCurrentPasswordHandler(event) {
        this.setState({currentPassword: event.target.value});
    }

    insertNewPasswordHandler(event) {
        this.setState({newPassword: event.target.value});
    }

    insertRepeatNewPasswordHandler(event) {
        this.setState({confirmNewPassword: event.target.value});
    }

    loginAndChange = () => {
        if (this.state.username === '' || this.state.currentPassword === '') {
            this.props.onShowError('LOGIN_USERNAME_OR_PASSWORD_EMPTY');
            return;
        }
        if (this.state.newPassword === '') {
            this.props.onShowError('LOGIN_NEW_PASSWORD_IS_EMPTY');
            return;
        }
        if (!isStrongPassword(this.state.newPassword)) {
            this.props.onShowError('LOGIN_NEW_PASSWORD_MUST_HAVE_EIGHT_SIGNS');
            return;
        }
        if (!identicalPasswords(this.state.newPassword, this.state.confirmNewPassword)) {
            this.props.onShowError('LOGIN_NEW_PASSWORD_NOT_IDENTICAL');
            return;
        }
        this.props.onLoginWithPasswordChange({
            username: this.state.username,
            password: this.state.currentPassword
        }, this.state.newPassword);
    }

    inputColor(password) {
        if (password === '') {
            return '';
        }
        if (isStrongPassword(password)) {
            return 'StrongPassword';
        }
        return 'WrongPassword';
    }

    inputConfirmPasswordColor(password) {
        if (password === '') {
            return '';
        }
        if (identicalPasswords(this.state.newPassword, password)) {
            return 'StrongPassword';
        }
        return 'WrongPassword';
    }


    render() {
        const {t} = this.props;
        if (this.props.error) {
            this.props.onShowError(this.props.error, 'LOGIN_UNAUTHORIZED');
        }
        if (this.props.valid) {
            this.props.onShowInfo('LOGIN_PASSWORD_CHANGED');
            return <RedirectLogin/>;
        }
        if (this.props.loading) {
            return <Spinner/>
        }
        return (
            <>
                <PasswordChangeForm
                    label={t('LOGIN_CHANGE_PASSWORD')}
                    placeholderUsername={t('LOGIN_USERNAME')}
                    insertUserNameHandler={(event) => this.insertUserNameHandler(event)}
                    placeholderPassword={t('LOGIN_CURRENT_PASSWORD')}
                    insertCurrentPasswordHandler={(event) => this.insertCurrentPasswordHandler(event)}
                    placeholderNewPassword={t('LOGIN_NEW_PASSWORD')}
                    newPasswordHandler={(event) => this.insertNewPasswordHandler(event)}
                    newPasswordInputColor={this.inputColor(this.state.newPassword)}
                    confirmPasswordHandler={(event) => this.insertRepeatNewPasswordHandler(event)}
                    confirmPasswordInputColor={this.inputConfirmPasswordColor(this.state.confirmNewPassword)}
                    buttonTitle={t('LOGIN_CHANGE_PASSWORD_BUTTON')}
                    loginAndChange={this.loginAndChange}
                    linkTitle={t('BACK_TO_LOGIN')}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        valid: state.login.valid,
        error: state.login.error,
        loading: state.login.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoginWithPasswordChange: (loginInformation, newPassword) => dispatch(loginAction.loginWithPasswordChange(loginInformation, newPassword)),
        onShowError: (error, text) => dispatch(messageAction.showError(error, text)),
        onShowInfo: (text) => dispatch(messageAction.showInfo({message: text}, text))
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PasswordChange));
