import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './Filter.css';

const Filter = (props) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="default-filter-bar">
                <div>
                    <span className='default-filter-bar-title'>
                    {t(props.title)}
                    </span>
                </div>
                <form method="post">
                <div>
                    {props.children}
                </div>
                <div>
                    <button type = "reset" className="reset-section-button list-button" onClick={props.resetFilter}>
                        <div>
                            {t("PRODUCER_LIST_RESET_SELECTION")}
                        </div>
                    </button>
                </div>
            </form>
                <div>
                    <button className="list-button" onClick={props.applyFilter}>
                        <div>
                            {t("PRODUCER_LIST_APPLY_FILTER")}
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
};

export default Filter;