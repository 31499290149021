import * as actionTypes from '../actions/actionTypes';
import {changeToggleInformationFor, setToggleAll, toggleAllInList} from '../../utility/ToggleHandler';

const initialState = {
    toggleResponsesList: [],
    toggleAllResponses: false,
    error: ''
};

const toggleResponseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_START:
            return {
                ...state
            };
        case actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_SUCCESS:
            return {
                ...state,
                toggleResponsesList: action.toggleResponsesList,
                error: ''
            };
        case actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_FAIL:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.TOGGLE_RESPONSE:
            return {
                ...state,
                toggleResponsesList: changeToggleInformationFor(action.responseId, [...state.toggleResponsesList]),
                toggleAllResponses: setToggleAll([...state.toggleResponsesList], state.toggleAllResponses),
            };
        case actionTypes.TOGGLE_RESPONSES:
            return {
                ...state,
                toggleResponsesList: toggleAllInList(action.toggleAllResponses, [...state.toggleResponsesList]),
                toggleAllResponses: action.toggleAllResponses,
            }
        case actionTypes.CLEAR_TOGGLE_ALL_RESPONSES:
            return {
                ...state,
              ...initialState
            }
        default:
            return state;
    }
}

export default toggleResponseReducer;
