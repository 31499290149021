import React from 'react';
import {useTranslation} from 'react-i18next';

import TripleRadioButton from './TripleRadioButton/TripleRadioButton';
import ArrowLabel from './ArrowLabel/ArrowLabel';

/**
 *
 * @param props
 * editable={props.editable} Radiobuttons editable
 * @returns {JSX.Element}
 */
const quickAnswerBox = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();
    return (
        <>
            <div style={mainStyle}>
                <div>
                    <ArrowLabel label={t('VISIBLE_IN_SYSTEM')}/>
                    <ArrowLabel label={t('CONFIGURABLE')}/>
                </div>
                <div>
                    <TripleRadioButton currentChecked={props.systemAvailable}
                                       changed={props.changedSystemAvailable}
                                       editable={props.editable}/>
                    <TripleRadioButton currentChecked={props.configurable}
                                       changed={props.changedConfigurable}
                                       editable={props.editable}/>
                </div>
            </div>
        </>
    );
}

const mainStyle = {
    display: 'flex',
    flexDirection: 'row'
};

export default quickAnswerBox;
