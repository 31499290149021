import * as actionTypes from '../actions/actionTypes';
import {removeDeletedRequirement} from '../../utility/RequirementHandler';

const initialState = {
    producerFavorites: [],
    loadingInProgress: false,
    savingInProgress: false,
    savedToFavorites: false,
    removingInProgress: false,
    error: ''
};

const favoritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_TO_PRODUCERS_FAVORITES_START: {
            return {
                ...state,
                savingInProgress: true
            }
        }
        case actionTypes.SAVE_TO_PRODUCERS_FAVORITES_SUCCESS: {
            return {
                ...state,
                savingInProgress: false,
                savedToFavorites: true
            }
        }
        case actionTypes.SAVE_TO_PRODUCERS_FAVORITES_FAIL: {
            return {
                ...state,
                savingInProgress: false,
                error: action.error
            }
        }
        case actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_START: {
            return {
                ...state,
                removingInProgress: true
            }
        }
        case actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_SUCCESS: {
            return {
                ...state,
                removingInProgress: false,
                producerFavorites: removeDeletedRequirement(action.removedCategoryId, action.removedRequirementId, state.producerFavorites)
            }
        }
        case actionTypes.REMOVE_FROM_PRODUCER_FAVORITES_FAIL: {
            return {
                ...state,
                removingInProgress: false,
                error: action.error
            }
        }
        case actionTypes.LOAD_PRODUCERS_FAVORITES_START: {
            return {
                ...state,
                loadingInProgress: true
            }
        }
        case actionTypes.LOAD_PRODUCERS_FAVORITES_SUCCESS: {
            return {
                ...state,
                loadingInProgress: false,
                producerFavorites: action.producerFavorites
            }
        }
        case actionTypes.LOAD_PRODUCERS_FAVORITES_FAIL: {
            return {
                ...state,
                loadingInProgress: false
            }
        }
        case actionTypes.HTTP_STATUS_UNAUTHORIZED: {
            return undefined;
        }
        default:
            return state;
    }
}

export default favoritesReducer;
