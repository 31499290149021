import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import AdminMainView from '../containers/Admin/AdminMainView';
import {
    ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH,
    ADMINISTRATOR_LOGOUT_ROUTE_PATH,
    ADMINISTRATOR_CATEGORIES_ROUTE_PATH,
    ADMINISTRATOR_PRODUCT_AREA_ROUTE_PATH,
    ADMINISTRATOR_ACCOUNTS_ROUTE_PATH,
    ADMINISTRATOR_COUNTRIES_ROUTE_PATH
} from './RoutingPaths';
import Logout from '../containers/Logout/Logout';


const adminRouting = () => {
    return (
        <Switch>
            <Route path={ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH} component={AdminMainView}/>
            <Route path={ADMINISTRATOR_CATEGORIES_ROUTE_PATH} component={null}/>
            <Route path={ADMINISTRATOR_PRODUCT_AREA_ROUTE_PATH} component={null}/>
            <Route path={ADMINISTRATOR_ACCOUNTS_ROUTE_PATH} component={null}/>
            <Route path={ADMINISTRATOR_COUNTRIES_ROUTE_PATH} component={null}/>
            <Route path={ADMINISTRATOR_LOGOUT_ROUTE_PATH} component={Logout}/>
            <Redirect to={ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH}/>
        </Switch>
    )
}

export default adminRouting;
