import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import './ButtonAnswerRow.css';
import Button from '../../UI/Button/Button';


class ButtonAnswerRow extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className='ButtonAnswerRow'>
                <Button title={t('REQUIREMENT_ANSWER_SAVE_AS_DRAFT')} color='Orange' clicked={this.props.saveDraft}/>
                <Button title={t('REQUIREMENT_ANSWER_SAVE_AND_PUBLISH')} clicked={this.props.savePublish}/>
            </div>
        );
    }
}

export default withTranslation()(ButtonAnswerRow);
