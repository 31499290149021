import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';

export const loadProducerList = (list) => {
    return async (dispatch) => {
        try{
            const response = await getProducerList(list);
            if(response.status>=400){
                dispatch(loadingFail(response.status));
            } else{
                const prodList= await response.json();
                dispatch(loadingProducerListSuccess(prodList));
            } 
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const loadCustomerProfile = (id) => {
    return async (dispatch) => {
        try{
            const response = await getCustomerProfile(id);
            if(response.status>=400){
                dispatch(loadingFail(response.status));
            } else{
                const customerProfile = await response.json();
                dispatch(loadingCustomerProfile(customerProfile));
            } 
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export const updateCustomerProfile = (id, body) => {
    return async (dispatch) => {
        try{
            const response = await updateProfile(id, body);
            if(response.status>=400){
                dispatch(loadingFail(response.status));
            } else{
                const customerProfile= await response.json();
                dispatch(loadingCustomerProfile(customerProfile));
            } 
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export const updateCustomerLoginInformation = (object) => {
    return async (dispatch) => {
        try{
            dispatch(updateCustomerInformation(object));
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}
export function resetList(){
    return {
        type: actionTypes.CLEAR_PRODUCER_LIST
    }
}
function loadingProducerListSuccess(list){
    return {
        type: actionTypes.LOAD_PRODUCER_LIST,
        producerList: list
    }
}
function loadingFail(error){
    return {
        type: actionTypes.LOAD_CUSTOMER_INFO_FAIL,
        error: error
    }
}
function loadingCustomerProfile(profile){
    return {
        type: actionTypes.LOAD_CUSTOMER_PROFILE,
        profile: profile
    }
}
function updateCustomerInformation(user) {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        valid: true,
        account: user
    };
}

async function getProducerList(list){
    const response = await fetch(`${SERVER_URL}/producers/list`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body:  JSON.stringify(list)
    })
    return response
}
async function getCustomerProfile(id){
    const response = await fetch(`${SERVER_URL}/customers/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    return response
}
async function updateProfile(id, body){
    const response = await fetch(`${SERVER_URL}/customers?customerId=${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    })
    return response
}