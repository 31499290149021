import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import '../../MenuBar.css';
import NavigationLink from '../../../components/Navigation/NavigationLink/NavigationLink';
import {ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH,ADMINISTRATOR_LOGOUT_ROUTE_PATH} from '../../../routing/RoutingPaths';

class AdminMenuBar extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className='MenuBar'>
                <ul className='NavigationBar'>
                    <NavigationLink link={ADMINISTRATOR_REQUIREMENTS_ROUTE_PATH}>
                        {t('ADMIN_MANAGE_REQUIREMENTS')}
                    </NavigationLink>
                    <NavigationLink link={ADMINISTRATOR_LOGOUT_ROUTE_PATH}>
                        {t('LOGOUT')}
                    </NavigationLink>
                </ul>
            </div>
        );
    }
}

export default withTranslation()(AdminMenuBar);
