import React from 'react';

import './Checkbox.css';

/**
 *
 * @param props
 * shadowBox= to show the checkbox in a shadow box please insert 'ShadowBox'. It is a css class which shows a shadow box.
 * name='name of the input element'
 * clicked={function }
 * checked=boolean is checkbox checked
 * label='text which is shown in the checkbox'
 *
 * @returns {*}
 */
const checkbox = (props) => (
    <div className={props.shadowBox}>
        <input name={props.name} type="checkbox" className='Checkbox' onClick={props.clicked} checked={props.checked}/>
        {props.label}
    </div>
);

export default checkbox;
