import React from 'react';
import './InfoLabel.css';

const infoLabel = (props) => {
    return (
        <div className='InfoLabel'>
            <label>{props.text}</label>
        </div>
    );
}

export default infoLabel;
