import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import * as loginAction from '../../store/actions/LoginAction';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from '../../hoc/Message/Message';
import * as messageAction from "../../store/actions/MessageAction";
import Cookies from 'universal-cookie';
import RedirectLogin from './RedirectLogin';

class Login extends Component {
    state = {
        username: '',
        password: '',
        cookieAccepted: false,
        bannerHide: false
    };

    //to avoid error: number of maximum depth call exceeded, which can result in infinite loop.
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        const found = cookies.get('atcrisconnect-cookies-accepted', {path: '/'});
        this.state.cookieAccepted = (found === "accept-essential");
        this.state.bannerHide = (this.state.cookieAccepted === true);
    }

    cookieAcceptedHandler() {
        const cookies = new Cookies();
        let expiryDate = new Date(Number(new Date()) + 31536000000);
        cookies.set('atcrisconnect-cookies-accepted', 'accept-essential', {path: '/', expires: expiryDate});
        this.setState({cookieAccepted: true, bannerHide: true});
    }

    insertUserNameHandler(event) {
        this.setState({username: event.target.value});
    }

    insertPasswordHandler(event) {
        this.setState({password: event.target.value});
    }

    prepareLogin = () => {
        if (this.state.username === '' || this.state.password === '') {
            this.props.showError(true, 'LOGIN_UNAUTHORIZED');
            return;
        }
        const user = {username: this.state.username, password: this.state.password};
        this.props.onLogin(user);
    };

    showLoginInfos() {
        const {t} = this.props;

        if (this.props.error) {
            this.props.showError(this.props.error, 'LOGIN_UNAUTHORIZED');
        }
        if (this.props.valid) {
            return <RedirectLogin/>;
        }
        if (this.props.loading) {
            return <Spinner/>
        }
        return (
            <div>
                {this.state.bannerHide ? '' : <Message text={t('COOKIE_BANNER_TITLE')}
                                                       hide={() => this.cookieAcceptedHandler()}
                                                       buttonTitle={t('COOKIE_BANNER_BUTTON')}
                />}
                <LoginForm label={t('LOGIN_LABEL')}
                           placeholderUsername={t('LOGIN_USERNAME')}
                           insertUserName={(event) => this.insertUserNameHandler(event)}
                           placeholderPassword={t('LOGIN_PASSWORD')}
                           insertPassword={(event) => this.insertPasswordHandler(event)}
                           buttonTitle={t('LOGIN_BUTTON')}
                           loginHandler={this.prepareLogin}
                           linkTitle={t('LOGIN_CHANGE_PASSWORD')}
                />
            </div>);
    };

    render() {
        return (
            <>
                {this.showLoginInfos()}
            </>
        );
    };
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        valid: state.login.valid,
        error: state.login.error,
        loading: state.login.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (user) => dispatch(loginAction.login(user)),
        showError: (error, text) => dispatch(messageAction.showError(error, text))
    }
};

const LoginWithRedux = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withTranslation()(LoginWithRedux);
