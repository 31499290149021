import * as actionTypes from '../actions/actionTypes';

const initialState = {
    account: '',
    error: '',
    valid: false,
    loading: false
};

const loginReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.HTTP_STATUS_UNAUTHORIZED:
            return {
                account: '',
                error: '',
                valid: false,
                loading: false
            };
        case actionTypes.MESSAGE_ACCEPTED:
            return {
                ...state,
                error: '',
                loading: false
            };
        case actionTypes.LOGIN_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                valid: action.valid,
                account: action.account,
                loading: false
            };
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                error: action.error,
                valid: false,
                loading: false
            };
        default:
            return state;
    }
};

export default loginReducer;
