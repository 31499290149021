export function identicalPasswords(newPassword, repeatNewPassword) {
    return newPassword === repeatNewPassword;
}

export const WRONG_PARAMETER = 'Wrong parameter. Parameter can not be null or undefined';

export function isStrongPassword(password) {
    if (password === undefined || password === null) {
        throw new Error(WRONG_PARAMETER);
    }
    return password.length >= 8
}
