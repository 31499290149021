import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';
import {requirementJSONToRequirementMap} from '../../utility/RequirementHandler';

export function requirementStatus() {
    return async (dispatch) => {
        dispatch(loadRequirementStatusStart());
        try {
            const response = await loadRequirementStatus();
            dispatch(loadRequirementStatusSuccess(await response.json()));
        } catch (error) {
            dispatch(loadRequirementStatusFail(error));
        }
    }
}

function loadRequirementStatusStart() {
    return {
        type: actionTypes.LOAD_REQUIREMENT_STATUS_START
    }
}

function loadRequirementStatusSuccess(status) {
    return {
        type: actionTypes.LOAD_REQUIREMENT_STATUS_SUCCESS,
        statusList: status
    }
}

function loadRequirementStatusFail(error) {
    return {
        type: actionTypes.LOAD_REQUIREMENT_STATUS_FAIL,
        error: error
    }
}

async function loadRequirementStatus() {
    return await fetch(SERVER_URL + '/requirements/admin/visiblestatus', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}


export function editRequirement(id, languageCode, description, status) {
    return async (dispatch) => {
        dispatch(updateRequirementStart());
        try {
            const response = await updateRequirement(id, languageCode, description, status);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(updateRequirementSuccess(convertRequirement(await response.json())));
        } catch (error) {
            dispatch(updateRequirementFail(error))
        }
    };
}

function updateRequirementFail(error) {
    return {
        type: actionTypes.UPDATE_REQUIREMENT_FAIL,
        error: error
    }
}

function updateRequirementSuccess(response) {
    return {
        type: actionTypes.UPDATE_REQUIREMENT_SUCCESS,
        updatedRequirement: response
    }
}

function updateRequirementStart() {
    return {
        type: actionTypes.UPDATE_REQUIREMENT_START
    }
}

async function updateRequirement(id, languageCode, description, status) {
    const multiDescriptionMap = new Map();
    multiDescriptionMap.set(languageCode,description);
    return await fetch(SERVER_URL + '/requirements/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "id": id,
            "multiLanguageDescriptions": Object.fromEntries(multiDescriptionMap),
            "languageCode": languageCode,
            "status": status
        })
    });
}

export const saveRequirement = (productArea, languageCode, description, accountId, status) => {
    return async (dispatch) => {
        dispatch(createRequirementStart());
        try {
            const response = await createRequirement(productArea, languageCode, description, accountId, status);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(createRequirementSuccess(convertRequirement(await response.json()), status));
            dispatch(requirementSuccessMessage(status));
        } catch (error) {
            dispatch(createRequirementFail(error))
        }
    };
}

function createRequirementFail(error) {
    return {
        type: actionTypes.CREATE_REQUIREMENT_FAIL,
        error: error
    }
}

function createRequirementSuccess(requirement, status) {
    return {
        type: actionTypes.CREATE_REQUIREMENT_SUCCESS,
        createdRequirement: requirement,
        status: status
    }
}

function requirementSuccessMessage(status) {
    return {
        type: actionTypes.SHOW_INFO,
        text: (status === 'FOR_APPROVAL' ? 'REQUIREMENT_SEND_TO_VALIDATION' : 'REQUIREMENT_SAVED_AS_DRAFT'),
        info: true
    }
}

function createRequirementStart() {
    return {
        type: actionTypes.CREATE_REQUIREMENT_START
    }
}

async function createRequirement(productArea, languageCode, description, accountId, status) {
    const descriptionMap = new Map();
    descriptionMap.set(languageCode,description);
    return await fetch(SERVER_URL + '/requirements/create/' + accountId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "productArea": productArea,
            "multiLanguageDescriptions": Object.fromEntries(descriptionMap),
            "languageCode": languageCode,
            "status": status
        })
    });
}

export const requirements = (productAreaId, languageCode) => {
    return async (dispatch) => {
        dispatch(loadRequirementsStart());
        try {
            const response = await loadRequirements(productAreaId, languageCode);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(loadRequirementsSuccess(requirementJSONToRequirementMap(await response.json())))
        } catch (error) {
            dispatch(loadRequirementsFail(error))
        }
    };
};

function loadRequirementsStart() {
    return {
        type: actionTypes.LOAD_REQUIREMENTS_START
    };
}

function loadRequirementsSuccess(allRequirements) {
    return {
        type: actionTypes.LOAD_REQUIREMENTS_SUCCESS,
        requirements: allRequirements
    };
}

function loadRequirementsFail(error) {
    return {
        type: actionTypes.LOAD_REQUIREMENTS_FAIL,
        error: error
    };
}

async function loadRequirements(productAreaId, languageCode) {
    try {
        return await fetchRequirements(productAreaId, languageCode);
    } catch (error) {
        throw error;
    }
}

async function fetchRequirements(productAreaId, languageCode) {
    return await fetch(SERVER_URL + '/requirements?productAreaId=' + productAreaId + '&languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}

export const customerRequirements = (customerId, languageCode) => {

    return async (dispatch) => {
        dispatch(loadCustomerRequirementsStart());
        try {
            const response = await loadCustomerRequirements(customerId, languageCode);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(loadCustomerRequirementsSuccess(requirementJSONToRequirementMap(await response.json())))
        } catch (error) {
            dispatch(loadCustomerRequirementsFail(error))
        }
    };
};

function loadCustomerRequirementsStart() {
    return {
        type: actionTypes.LOAD_CUSTOMER_REQUIREMENTS_START
    };
}

function loadCustomerRequirementsSuccess(allRequirements) {
    return {
        type: actionTypes.LOAD_CUSTOMER_REQUIREMENTS_SUCCESS,
        customerRequirements: allRequirements
    };
}

function loadCustomerRequirementsFail(error) {
    return {
        type: actionTypes.LOAD_CUSTOMER_REQUIREMENTS_FAIL,
        error: error
    };
}

async function loadCustomerRequirements(customerId, languageCode) {
    try {
        return await fetchCustomerRequirements(customerId, languageCode);
    } catch (error) {
        throw error;
    }
}

async function fetchCustomerRequirements(customerId, languageCode) {
    return await fetch(SERVER_URL + '/requirements/customer?id=' + customerId + '&languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}

export function clearSavedRequirementsMessage() {
    return {
        type: actionTypes.CLEAR_SAVED_REQUIREMENT_MESSAGE
    };
}

function redirectUnauthorized() {
    return {
        type: actionTypes.HTTP_STATUS_UNAUTHORIZED
    };
}

function convertRequirement(requirement) {
    return {
        id: requirement.id,
        label: requirement.description,
        categoryName: requirement.category.name,
        categoryId: requirement.category.id,
        status: requirement.status
    };
}


/*Administrator actions*/
export function administratorRequirements() {
    return async (dispatch) => {
        dispatch(administratorRequirementsStart());
        try {
            const response = await fetchAdminRequirements();
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            const requirementJSON = await response.json();
            dispatch(administratorRequirementsSuccess(requirementJSON.map(convertAdminRequirement)));
        } catch (error) {
            dispatch(administratorRequirementsFail(error))
        }
    };
}

function administratorRequirementsFail(error) {
    return {
        type: actionTypes.ADMIN_REQUIREMENTS_FAIL,
        error: error
    }
}

function administratorRequirementsSuccess(response) {
    return {
        type: actionTypes.ADMIN_REQUIREMENTS_SUCCESS,
        adminRequirements: response
    }
}

function administratorRequirementsStart() {
    return {
        type: actionTypes.ADMIN_REQUIREMENTS_START
    }
}

async function fetchAdminRequirements() {
    return await fetch(SERVER_URL + '/requirements/admin/all', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}

export function submitRequirement(requirement) {
    return async (dispatch) => {
        dispatch(submitRequirementStart());
        try {
            const response = await saveAdminRequirement(requirement);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            if (response.status === 409) {
                dispatch(submitRequirementFail(response));
                return;
            }
            dispatch(submitRequirementSuccess(convertAdminRequirement(await response.json())));
        } catch (error) {
            dispatch(submitRequirementFail(error))
        }
    };
}

function convertAdminRequirement(requirement) {
    return {
        id: requirement.id,
        status: requirement.status,
        productArea: requirement.productArea,
        category: requirement.category,
        languageCode: requirement.languageCode,
        description: requirement.description ? requirement.description : '',
        multiLanguageDescriptions: requirement.multiLanguageDescriptions

    };
}

function submitRequirementFail(error) {
    return {
        type: actionTypes.SUBMIT_REQUIREMENT_FAIL,
        error: error
    }
}

function submitRequirementSuccess(response) {
    return {
        type: actionTypes.SUBMIT_REQUIREMENT_SUCCESS,
        submittedRequirement: response
    }
}

function submitRequirementStart() {
    return {
        type: actionTypes.SUBMIT_REQUIREMENT_START
    }
}

async function saveAdminRequirement(requirement) {
    return await fetch(SERVER_URL + '/requirements/submit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "id": requirement.id ? requirement.id : '',
            "productArea": requirement.productArea,
            "description": requirement.description,
            "languageCode": requirement.languageCode,
            "status": requirement.status,
            "category": requirement.category,
            "multiLanguageDescriptions": requirement.multiLanguageDescriptions
        })
    });
}

/**
 *requirements must be an array with ids [1,2,3]
 */
export function deleteRequirements(requirements) {
    return async (dispatch) => {
        dispatch(deleteRequirementStart());
        try {
            const response = await removeRequirements(requirements);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            if (response.status === 409) {
                dispatch(deleteRequirementFail(response));
                return;
            }
            dispatch(deleteRequirementSuccess(requirements));
        } catch (error) {
            dispatch(deleteRequirementFail(error))
        }
    };
}

function deleteRequirementFail(error) {
    return {
        type: actionTypes.DELETE_REQUIREMENT_FAIL,
        error: error
    }
}

function deleteRequirementSuccess(ids) {
    return {
        type: actionTypes.DELETE_REQUIREMENT_SUCCESS,
        removedRequirementIds: ids
    }
}

function deleteRequirementStart() {
    return {
        type: actionTypes.DELETE_REQUIREMENT_START
    }
}

async function removeRequirements(requirements) {
    return await fetch(SERVER_URL + '/requirements/delete', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            "requirementIds": requirements
        })
    });
}

