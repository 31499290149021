import React, {useEffect, useState} from 'react';
import './HelpButton.css'
import helpButtonFile from '../../assets/images/help.png';
import DetailsModal from "../Modal/DetailsModal/DetailsModal";
import {useTranslation} from "react-i18next";

const HelpButton = (props) => {

    const { t } = useTranslation();
    const helpText = t(props.helpContext);
    const [detailsIsOpen, setDetailsIsOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const closeDetails = () => {
        setDetailsIsOpen(false)
    }
    const openDetails = (details) => {
        setDetails(details)
    }

    useEffect(() => {
        if (!detailsIsOpen) setDetails(null);
    }, [detailsIsOpen])

    useEffect(() => {
        if (details) setDetailsIsOpen(true);
    }, [details])

        return   <div className='StickyBottom'>
            <img className='mini' src={helpButtonFile} alt='Click to get help.'
                 onClick={openDetails}/>
            <DetailsModal header='HELP_DEFAULT_HEADER' isOpen={detailsIsOpen} close={closeDetails} details={helpText}/>
        </div>
};

export default HelpButton;
