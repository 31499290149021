import React, {Component} from 'react';

import '../AllModal.css';
import Button from '../../UI/Button/Button';
import cross from '../../../assets/images/times-solid.svg';
import info from '../../../assets/images/info-solid.svg'
import {withTranslation} from 'react-i18next';
import * as requirementAction from '../../../store/actions/RequirementAction';
import {connect} from 'react-redux';

class NewRequirementModal extends Component {

    state = {
        textareaValue: this.props.textValue
    }

    changeTextAreaHandler = (event) => {
        this.setState({textareaValue: event.target.value})
    }

    saveRequirementForApproval() {
        this.props.onSavingRequirement(this.props.account.productArea, this.props.account.user.languageCode, this.state.textareaValue, this.props.account.id, 'FOR_APPROVAL');
        this.props.hide();
    }

    updateRequirementForApproval() {
        this.props.onUpdatingRequirement(this.props.requirementId, this.props.account.user.languageCode, this.state.textareaValue, 'FOR_APPROVAL');
        this.props.hide();
    }

    saveRequirementsForDraft() {
        this.props.onSavingRequirement(this.props.account.productArea, this.props.account.user.languageCode, this.state.textareaValue, this.props.account.id, 'DRAFT');
        this.props.hide();
    }

    updateRequirementForDraft() {
        this.props.onUpdatingRequirement(this.props.requirementId, this.props.account.user.languageCode, this.state.textareaValue, 'DRAFT');
        this.props.hide();
    }

    render() {
        const {t} = this.props;
        return (
            <div className='ModalOverlay'>
                <div className='DefaultModal'>
                    <div className='ModalLabel'>
                        <label>{t('NEW_REQUIREMENT_HEADING')}</label>
                        <img src={cross} alt='' onClick={this.props.hide}/>
                    </div>
                    <div className='ModalContent'>
                        <div className='ModalInfoRow'>
                            <div className='ModalInfoBlackbox'><img src={info} alt=''/></div>
                            <div><label>{t('NEW_REQUIREMENT_INFORMATION_TEXT')}</label></div>
                        </div>
                        <textarea
                            className='ModalTextarea'
                            placeholder={t('NEW_REQUIREMENT_INPUT_PLACEHOLDER')}
                            rows={10}
                            onChange={this.changeTextAreaHandler}>{this.state.textareaValue}</textarea>
                        <div className='ModalButtonRow'>
                            <Button title={t('NEW_REQUIREMENT_SAVE_FOR_APPROVAL')}
                                    clicked={this.props.editRequirement ? () => this.updateRequirementForApproval() : () => this.saveRequirementForApproval()}/>
                            <Button title={t('NEW_REQUIREMENT_SAVE_AS_DRAFT')} color='DarkBlue'
                                    clicked={this.props.editRequirement ? () => this.updateRequirementForDraft() : () => this.saveRequirementsForDraft()}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        account: state.login.account
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSavingRequirement: (productArea, languageCode, description, accountId, status) => dispatch(requirementAction.saveRequirement(productArea, languageCode, description, accountId, status)),
        onUpdatingRequirement: (id, languageCode, description, status) => dispatch(requirementAction.editRequirement(id, languageCode, description, status))
    }
};

const NewRequirementModalWithRedux = connect(mapStateToProps, mapDispatchToProps)(NewRequirementModal);
export default withTranslation()(NewRequirementModalWithRedux);
