import * as actionTypes from '../actions/actionTypes';

export const logout = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutUser());
        } catch (error) {
            //TODO implement proper error handling connected to reducer
        }
    };
};

function logoutUser() {
    localStorage.clear();
    return {
        type: actionTypes.USER_LOGOUT,
    };
}
