import * as actionTypes from './actionTypes';
import {SERVER_URL} from '../../config';
import {convertRequirementResponses} from '../../utility/RequirementResponseHandler';

function loadRequirementResponsesStart() {
    return {
        type: actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_START
    }
}

function loadRequirementResponsesSuccess(requirementResponseList) {
    return {
        type: actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_SUCCESS,
        requirementResponseList: requirementResponseList
    }
}

function loadRequirementResponsesFail(error) {
    return {
        type: actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_START,
        error: error
    }
}

async function loadRequirementResponses(accountId, productAreaId, languageCode) {
    return await fetch(SERVER_URL + '/requirementresponse/all/' + accountId + '?productAreaId=' + productAreaId + '&languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}



const NO_CONTENT = 204;
export const requirementResponses = (accountId, productAreaId, languageCode) => {
    return async (dispatch) => {
        dispatch(loadRequirementResponsesStart());
        try {
            const response = await loadRequirementResponses(accountId, productAreaId, languageCode);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            if (response.status === NO_CONTENT) {
                dispatch(loadRequirementResponsesSuccess([]))
                return;
            }
            const requirementResponseJSON = await response.json();
            const requirementResponseList = requirementResponseJSON.map(convertRequirementResponses);
            dispatch(loadRequirementResponsesSuccess(requirementResponseList))
        } catch (error) {
            dispatch(loadRequirementResponsesFail(error))
        }
    };
}

function loadProductAreaRequirementResponsesStart() {
    return {
        type: actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_START
    }
}

function loadProductAreaRequirementResponsesSuccess(requirementResponseList) {
    return {
        type: actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_SUCCESS,
        requirementResponseList: requirementResponseList
    }
}

function loadProductAreaRequirementResponsesFail(error) {
    return {
        type: actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_FAIL,
        error: error
    }
}


async function loadProductAreaRequirementResponses(productAreaId, languageCode) {
    return await fetch(SERVER_URL + '/requirementresponse/allcustomer/' + productAreaId + '?languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}

export const productAreaRequirementResponses = (productAreaId, languageCode) => {
    return async (dispatch) => {
        dispatch(loadProductAreaRequirementResponsesStart());
        try {
            const response = await loadProductAreaRequirementResponses(productAreaId, languageCode);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            if (response.status === NO_CONTENT) {
                dispatch(loadProductAreaRequirementResponsesSuccess([]))
                return;
            }
            const requirementResponseJSON = await response.json();
            const requirementResponseList = requirementResponseJSON.map(convertRequirementResponses);
            dispatch(loadProductAreaRequirementResponsesSuccess(requirementResponseList))
        } catch (error) {
            dispatch(loadProductAreaRequirementResponsesFail(error))
        }
    };
}


export function saveRequirementResponse(systemAvailable, configurable, responseComment, status, responseId, requirementId, account) {
    return async (dispatch) => {
        dispatch(saveRequirementResponseStart());
        try {
            const response = await saveRequirementResponsesFor(systemAvailable, configurable, responseComment, status, responseId, requirementId, account);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            const requirementResponseJSON = await response.json();
            dispatch(saveRequirementResponseSuccess(convertRequirementResponses(requirementResponseJSON)));
        } catch (error) {
            dispatch(saveRequirementResponseFail(error));
        }
    };
}

function saveRequirementResponseStart() {
    return {
        type: actionTypes.SAVE_REQUIREMENT_RESPONSE_START
    }
}

export function saveRequirementResponseSuccess(response) {
    return {
        type: actionTypes.SAVE_REQUIREMENT_RESPONSE_SUCCESS,
        requirementResponse: response
    }
}

function saveRequirementResponseFail(error) {
    return {
        type: actionTypes.SAVE_REQUIREMENT_RESPONSE_FAIL,
        error: error
    }
}

function createBody(account, responseId, requirementId, status, responseComment, systemAvailable, configurable) {
    let value = {
        "productArea": {
            "id": account.productArea.id,
            "name": account.productArea.name
        },
        "id": responseId,
        "requirementId": requirementId,
        "status": status,
        "languageCode": account.user.languageCode,
        "responseComment": responseComment,
    };
    value.available = systemAvailable ? systemAvailable : null;
    value.configurable = configurable ? configurable : null;
    return JSON.stringify(value);
}

async function saveRequirementResponsesFor(systemAvailable, configurable, responseComment, status, responseId, requirementId, account) {
    return await fetch(SERVER_URL + '/requirementresponse/submit/' + account.id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: createBody(account, responseId, requirementId, status, responseComment, systemAvailable, configurable)
    });
}

function redirectUnauthorized() {
    return {
        type: actionTypes.HTTP_STATUS_UNAUTHORIZED
    };
}
