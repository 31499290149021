import * as actionTypes from '../actions/actionTypes';

const countriesReducer = (state = null, action) => {
    switch (action.type){
        case actionTypes.LOAD_COUNTRIES:
            return action.countries;
        default:
            return state;
    }
};
export default countriesReducer;