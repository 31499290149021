import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';

import * as requirementAction from '../../../store/actions/RequirementAction';
import * as requirementSelectionAction from '../../../store/actions/RequirementSelectionAction';
import * as toggleCategoriesAction from '../../../store/actions/ToggleCategoriesAction';
import * as messageAction from '../../../store/actions/MessageAction';
import * as filterAction from "../../../store/actions/RequirementFilterAction";

import {buildRequirementMapWithResponses} from '../../../utility/RequirementHandler';
import {topScroll} from '../../../utility/UxHelper';
import {createExcelTranslationMap} from '../../../utility/UiHelper';

import ToggleView from '../../../components/UI/ToggleView/ToggleView';
import RequirementControl from '../../../components/Requirements/RequirementControl/RequirementControl';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Error from '../../../components/UI/Error/Error';
import NewRequirementModal from '../../../components/Modal/NewRequirementModal/NewRequirementModal';
import ApprovedRequirementsView
    from '../../../components/Requirements/ApprovedRequirementsView/ApprovedRequirementsView';
import RequirementToolbar from '../../../components/Toolbar/RequirementToolbar';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Filter from "../../../components/Filter/Filter";
import FilterSection from "../../../components/Filter/FilterSection";
import * as toggleRequirementAction from '../../../store/actions/ToggleRequirementAction';
import * as toggleResponseAction from '../../../store/actions/ToggleResponseAction';
import {removeRequirementAssociation} from '../../../store/actions/FavoritesAction';

class MyRequirements extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props
        this.translationMap = createExcelTranslationMap(t);
    }

    state = {
        newRequirement: false,
        requirementDescription: '',
        requirementId: '',
        editRequirement: false,
        myRequirementMapWithResponses: {}
    };

    componentDidMount() {
        this.props.onClearRequirementSelection();
        this.props.onClearToggleAllCategories();
        this.props.onClearToggleAllRequirements();
        this.props.onClearToggleAllResponses();
        if (this.props.account.id !== undefined) {
            this.props.onLoadingCustomerRequirements(this.props.account.id, this.props.account.user.languageCode);
        }
        if (this.props.filter == null) this.props.onLoadFilter();
        else this.clearFilterSections();
        topScroll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let myRequirementMapWithResponses;
        if (prevProps.requirementResponseList !== this.props.requirementResponseList || prevProps.myRequirements !== this.props.myRequirements) {
            myRequirementMapWithResponses = buildRequirementMapWithResponses(this.props.myRequirements, this.props.requirementResponseList);
            this.setState({myRequirementMapWithResponses: myRequirementMapWithResponses});
        }
        if (this.buildToggleInformationLists(prevProps)) {
            this.prepareToggleCategoriesInformationList(myRequirementMapWithResponses);
            this.prepareToggleRequirementInformationList(myRequirementMapWithResponses);
            this.prepareToggleResponsesInformationList(myRequirementMapWithResponses);
        }
        if (myRequirementMapWithResponses != null
            && myRequirementMapWithResponses !== prevState.myRequirementMapWithResponses
            && prevProps.myRequirements !== this.props.myRequirements && this.props.filter) {
            this.props.onApplyFilter(this.props.filter, myRequirementMapWithResponses, this.props.account, this.props.selectedRequirementIds, this.translationMap, this.props.allRequirementSelected);
        }
    }


    buildToggleInformationLists(prevProps) {
        return prevProps.myRequirements !== this.props.myRequirements
            && this.toggleListsEmpty();
    }

    toggleListsEmpty() {
        return this.props.toggleCategoriesInformationList.length === 0
            && this.props.toggleRequirementList.length === 0
            && this.props.toggleResponseList.length === 0;
    }

    prepareToggleCategoriesInformationList(myRequirementMapWithResponses) {
        let categoryIds = [];
        for (const key in myRequirementMapWithResponses) {
            categoryIds = categoryIds.concat(key);
        }
        this.props.onToggleCategoriesInformationList(categoryIds);
    }

    prepareToggleRequirementInformationList(myRequirementMapWithResponses) {
        let requirementIds = [];
        for (const key in myRequirementMapWithResponses) {
            const requirements = myRequirementMapWithResponses[key];
            requirementIds = requirementIds.concat(requirements.map(requirement => requirement.id));
        }
        this.props.onToggleRequirementList(requirementIds);
    }

    prepareToggleResponsesInformationList(myRequirementMapWithResponses) {
        let responseIds = [];
        for (const key in myRequirementMapWithResponses) {
            const requirements = myRequirementMapWithResponses[key];
            const responses = requirements.flatMap(requirement => requirement.responses);
            responseIds = responseIds.concat(responses.map(response => response.id));
        }
        this.props.onToggleResponseList(responseIds);
    }

    myAddedRequirementsView(t) {
        return <>
            {this.waitingForApprovalRequirements(t, this.filterRequirementStatus('FOR_APPROVAL'))}
            {this.draftRequirements(t, this.filterRequirementStatus('DRAFT'))}
        </>;
    }

    filterRequirementStatus(status) {
        for (const categoryId in this.props.myRequirements) {
            const filter = this.props.myRequirements[categoryId].filter(requirement => requirement.status === status);
            if (filter.length !== 0) {
                return {categoryId: categoryId, requirements: filter};
            }
        }
        return {categoryId: 0, requirements: []};
    }

    draftRequirements(t, draftRequirements) {
        const draft = draftRequirements.requirements.map(requirement =>
            <RequirementControl key={requirement.id}
                                requirementId={requirement.id}
                                label={requirement.label}
                                deletable={true}
                                delete={() => this.remove(requirement)}
                                editable={true}
                                edit={() => this.openNewRequirementModal(requirement.id, requirement.label, true)}
                                clicked={() => this.props.onRequirementSelection(requirement, this.translationMap)}
                                checked={this.props.selectedRequirementIds.includes(requirement.id)}
            />);
        return <ToggleView heading={t('MY_REQUIREMENT_SAVE_AS_DRAFT')}
                           content={draft}
                           color='Toggle-Orange'
                           openToggleHandler={() => this.props.onToggleCategory(draftRequirements.categoryId)}
                           toggle={this.getToggle(draftRequirements.categoryId)}
        />;
    }

    waitingForApprovalRequirements(t, forApproval) {
        const waitingForApproval = forApproval.requirements.map(requirement =>
            <RequirementControl key={requirement.id}
                                name={requirement.id}
                                label={requirement.label}
                                clicked={() => this.props.onRequirementSelection(requirement, this.translationMap)}
                                checked={this.props.selectedRequirementIds.includes(requirement.id)}
            />);
        return <ToggleView heading={t('MY_REQUIREMENT_SAVE_FOR_APPROVAL')}
                           content={waitingForApproval}
                           color='Toggle-Orange'
                           openToggleHandler={() => this.props.onToggleCategory(forApproval.categoryId)}
                           toggle={this.getToggle(forApproval.categoryId)}
        />;
    }

    getToggle(categoryId) {
        if (this.props.toggleCategoriesInformationList === undefined) {
            return false;
        }
        const categoryToggleInfo = this.props.toggleCategoriesInformationList.find(toggleInfo => categoryId === toggleInfo.id);
        if (categoryToggleInfo === undefined) {
            return false;
        }
        return categoryToggleInfo.toggle;
    }

    openNewRequirementModal(id, value, edit) {
        this.props.onCloseShowSaveMessage();
        this.setState({requirementId: id})
        this.setState({requirementDescription: value});
        this.setState({newRequirement: true});
        this.setState({editRequirement: edit});
    }

    closeNewRequirementModal() {
        this.props.onCloseShowSaveMessage();
        this.setState({newRequirement: false});
    }

    selectAllRequirements() {
        let requirementCollection = [];
        const masterMap = (this.props.filteredMap ? this.props.filteredMap : this.state.myRequirementMapWithResponses);
        for (const key in masterMap) {
            const element = masterMap[key];
            requirementCollection = requirementCollection.concat(...element)
        }
        this.props.onAllRequirementSelection(requirementCollection, this.props.allRequirementSelected, this.translationMap);
    }

    remove(requirement) {
        this.props.onRemoveAssociation(requirement.id, requirement.categoryId);
        if (this.props.selectedRequirementIds.includes(requirement.id)) {
            this.props.onRequirementDeselection(requirement, this.translationMap);
        }
    }

    getSaveMessageText() {
        if (this.props.requirementStatus === 'FOR_APPROVAL') {
            return 'REQUIREMENT_SEND_TO_VALIDATION';
        }
        return 'REQUIREMENT_SAVED_AS_DRAFT';
    }   ///////////////////////////////////////////
    // Filtering methods start
    handleFilter(filterSection, event) {
        this.props.onFilterItemChange(filterSection, event);
    }

    handleSection(filterSection, checked) {
        this.props.onFilterSectionChange(filterSection, checked)
    }

    clearFilterSections() {
        for (const filterSection in this.props.filter) {
            this.props.onFilterSectionChange(this.props.filter[filterSection].name, false);
        }
    }

    resetAll() {
        this.clearFilterSections();
        this.props.onApplyFilter(this.props.filter, this.state.myRequirementMapWithResponses);
    }

    applyFilter() {
        //load filtered Requirements
        this.props.onApplyFilter(this.props.filter, this.state.myRequirementMapWithResponses, this.props.account, this.props.selectedRequirementIds, this.translationMap, this.props.allRequirementSelected);
    }

// Filtering methods end
///////////////////////////////////////////

    render() {
        if (this.props.redirect) {
            return <Redirect to='/'/>
        }
        const {t} = this.props;
        if (this.props.myRequirements === undefined || this.props.loading) {
            return <Spinner/>;
        }
        if (this.props.myRequirements.length === 0) {
            return <Error msg={t('ERROR_LOADING_REQUIREMENTS')}/>
        }

        return (
            <>
                {this.state.newRequirement ? <NewRequirementModal hide={() => this.closeNewRequirementModal()}
                                                                  textValue={this.state.requirementDescription}
                                                                  editRequirement={this.state.editRequirement}
                                                                  requirementId={this.state.requirementId}/> : ''}
                <RequirementToolbar
                    excelExportRequirements={this.props.excelExportRequirements}
                    clicked={() => this.openNewRequirementModal()}
                />
                <Checkbox shadowBox='ShadowBox'
                          label={t('SELECT_ALL_REQUIREMENTS')}
                          clicked={() => this.selectAllRequirements()}
                          checked={this.props.allRequirementSelected}/>

                {(this.props.filter ? <Filter title="FILTER_DEFAULT_TITLE" applyFilter={() => this.applyFilter()}
                                              resetFilter={() => this.resetAll()}>
                    <FilterSection isSubsection={true} allChecked={this.props.filter[0].allChecked}
                                   title="PRODUCER_LIST_PRODUCT_PROVIDER"
                                   filterList={this.props.filter[0].filterList}
                                   handleFilter={(filterSection, event) => this.handleFilter(filterSection, event)}
                                   setSection={(filterSection, checked) => this.handleSection(filterSection, checked)}/>
                    {/*<FilterSection isSubsection={false} title=" "
                                   filterList={this.props.filter[1].filterList}
                                   handleFilter={(filterSection, event) => this.handleFilter(filterSection, event)}
                                   setSection={(filterSection, checked) => this.handleSection(filterSection, checked)}/>*/}
                </Filter> : '')}
                {this.myAddedRequirementsView(t)}
                <ApprovedRequirementsView
                    requirements={(this.props.filteredMap ? this.props.filteredMap : this.state.myRequirementMapWithResponses)}
                    checkboxClicked={(requirement) => this.props.onRequirementSelection(requirement, this.translationMap)}
                    requirementDeletable={true}
                    deleteRequirement={(requirement) => this.remove(requirement)}
                    accountId={this.props.account.id}
                    checkboxChecked={this.props.selectedRequirementIds}
                    openToggleHandler={(categoryId) => this.props.onToggleCategory(categoryId)}
                    toggleInformationList={this.props.toggleCategoriesInformationList}
                    toggleRequirementInformationList={this.props.toggleRequirementList}
                    toggleRequirement={(requirementId) => this.props.onToggleRequirement(requirementId)}
                    toggleResponsesInformationList={this.props.toggleResponseList}
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        myRequirements: state.requirementsData.customerRequirements,
        loading: state.requirementsData.loading,
        redirect: state.requirementsData.redirect,
        requirementStatus: state.requirementsData.status,
        excelExportRequirements: state.selectedRequirement.excelExportRequirements,
        selectedRequirementIds: state.selectedRequirement.selectedRequirementIds,
        allRequirementSelected: state.selectedRequirement.allRequirementSelected,
        toggleCategoriesInformationList: state.toggleCategories.toggleCategoriesInformationList,
        filter: state.filteredRequirement.filter,
        filteredMap: state.filteredRequirement.filteredRequirements,
        requirementResponseList: state.requirementResponses.allRequirementResponses,
        toggleRequirementList: state.toggleRequirement.toggleRequirementList,
        toggleResponseList: state.toggleResponse.toggleResponsesList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingCustomerRequirements: (customerId, languageCode) => dispatch(requirementAction.customerRequirements(customerId, languageCode)),
        onRequirementSelection: (requirement, translationMap) => dispatch(requirementSelectionAction.selectRequirement(requirement, translationMap)),
        onClearRequirementSelection: () => dispatch(requirementSelectionAction.clearRequirementSelection()),
        onAllRequirementSelection: (requirementCollection, allSelected, translationMap) => dispatch(requirementSelectionAction.selectAllRequirement(requirementCollection, allSelected, translationMap)),
        onRemoveAssociation: (requirementId, categoryId) => dispatch(removeRequirementAssociation(requirementId, categoryId)),
        onRequirementDeselection: (requirement, translationMap) => dispatch(requirementSelectionAction.deselect(requirement, translationMap)),
        onShowSaveMessage: (text) => dispatch(messageAction.showInfo(true, text)),
        onCloseShowSaveMessage: () => dispatch(requirementAction.clearSavedRequirementsMessage()),
        onLoadFilter: () => dispatch(filterAction.loadCustomerMainViewFilter()),
        onFilterItemChange: (filterSection, event) => dispatch(filterAction.filterItemChanged(filterSection, event)),
        onFilterSectionChange: (filterSection, checked) => dispatch(filterAction.filterSectionChanged(filterSection, checked)),
        onApplyFilter: (filter, myRequirements, account, selectedRequirementsIds, translationMap, allRequirementSelected) => dispatch(filterAction.filterSet(filter, myRequirements, account, selectedRequirementsIds, translationMap, allRequirementSelected)),
        onToggleCategoriesInformationList: (categoryIds) => dispatch(toggleCategoriesAction.toggleCategoriesInformationList(categoryIds)),
        onToggleCategory: (categoryId) => dispatch(toggleCategoriesAction.toggleCategory(categoryId)),
        onClearToggleAllCategories: () => dispatch(toggleCategoriesAction.clearToggleAllCategories()),
        onToggleRequirementList: (requirementIds) => dispatch(toggleRequirementAction.toggleRequirementInformationList(requirementIds)),
        onToggleRequirement: (requirementId) => dispatch(toggleRequirementAction.toggleRequirement(requirementId)),
        onClearToggleAllRequirements: () => dispatch(toggleRequirementAction.clearToggleAllRequirement()),
        onToggleResponseList: (responseIds) => dispatch(toggleResponseAction.toggleResponseInformationList(responseIds)),
        onClearToggleResponses: () => dispatch(toggleResponseAction.clearToggleAllResponses()),
        onClearToggleAllResponses: () => dispatch(toggleResponseAction.clearToggleAllResponses()),
    }
};

const myRequirementsWithRedux = connect(mapStateToProps, mapDispatchToProps)(MyRequirements);
export default withTranslation()(myRequirementsWithRedux);
