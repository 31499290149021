import * as actionTypes from '../actions/actionTypes';
import {changeToggleInformationFor} from '../../utility/ToggleHandler';
import {setToggleAll} from '../../utility/ToggleHandler';
import {toggleAllInList} from '../../utility/ToggleHandler';

const initialState = {
    toggleRequirementList: [],
    toggleAllRequirementResponses: false,
    error: ''
};

const toggleRequirementReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_START:
            return {
                ...state
            };
        case actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_SUCCESS:
            return {
                ...state,
                toggleRequirementList: action.toggleRequirementList
            };
        case actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_FAIL:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.TOGGLE_REQUIREMENT:
            return {
                ...state,
                toggleRequirementList: changeToggleInformationFor(action.requirementId, [...state.toggleRequirementList]),
                toggleAllRequirementResponses: setToggleAll([...state.toggleRequirementList], state.toggleAllRequirementResponses)
            }
        case actionTypes.TOGGLE_REQUIREMENTS:
            return {
                ...state,
                toggleRequirementList: toggleAllInList(action.toggleAllRequirementResponses, [...state.toggleRequirementList]),
                toggleAllRequirementResponses: action.toggleAllRequirementResponses
            }
        case actionTypes.CLEAR_TOGGLE_ALL_REQUIREMENT:
            return {
                ...state,
              ...initialState
            }
        default:
            return state;
    }
}

export default toggleRequirementReducer;
