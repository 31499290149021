import * as actionTypes from './actionTypes';

export function toggleRequirementInformationList(ids) {
    return (dispatch) => {
        dispatch(buildInformationListStart());
        try {
            let toggleInformation = [];
            if (ids !== undefined) {
                const filterUndefinedIds = ids.filter(id => id !== undefined);
                const filterEmptyIds = filterUndefinedIds.filter(id => id !== '');
                toggleInformation = filterEmptyIds.map(id => ({id: id, toggle: false}));
            }
            dispatch(buildInformationListSuccess(toggleInformation))
        } catch (error) {
            dispatch(buildInformationListFail(error))
        }
    };
}

function buildInformationListFail(error) {
    return {
        type: actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_FAIL,
        error: error
    };
}

function buildInformationListSuccess(toggleInformation) {
    return {
        type: actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_SUCCESS,
        toggleRequirementList: toggleInformation
    };
}

function buildInformationListStart() {
    return {
        type: actionTypes.BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_START
    };
}

export function toggleRequirement(requirementId) {
    return (dispatch) => {
        dispatch(toggle(requirementId))
    };
}

function toggle(requirementId) {
    return {
        type: actionTypes.TOGGLE_REQUIREMENT,
        requirementId: requirementId
    };
}

export function toggleRequirements(toggleBoolean) {
    return (dispatch) => {
        dispatch(toggleAll(toggleBoolean))
    };
}

function toggleAll(toggleBoolean) {
    return {
        type: actionTypes.TOGGLE_REQUIREMENTS,
        toggleAllRequirementResponses: !toggleBoolean
    };
}

export function clearToggleAllRequirement() {
    return {
        type: actionTypes.CLEAR_TOGGLE_ALL_REQUIREMENT
    };
}
