import React, {Component} from 'react';

import './DropDownMenu.css'
import AngleButton from '../Button/AngleButton';
import MenuItem from './MenuItem/MenuItem';

/**
 * props:
 * dropDownTitle
 * menuItemContainer: Must be an arry with {title: 'title of menu item' action: () => fireMe}
 */
class DropDownMenu extends Component {
    state = {
        showDrop: false
    }

    onClickedDropHandler = () => {
        this.setState({showDrop: !this.state.showDrop});
    }

    render() {
        return (
            <div className='DropDownMenu'>
                <div style={title}>
                    <AngleButton title={this.props.dropDownTitle} clicked={this.onClickedDropHandler}
                                 direction={this.state.showDrop}/>
                </div>
                {this.state.showDrop ?
                    <div className='Menu' onMouseLeave={this.onClickedDropHandler}>
                        {this.buildMenu()}
                    </div>
                    : ''}
            </div>

        )
    }

    buildMenu() {
        return this.props.menuItemContainer.map(menuItem => <MenuItem title={menuItem.title}
                                                                      clicked={menuItem.action}/>)
    }
}

const title = {
    display: 'flex',
    flexDirection: 'row'

}

export default DropDownMenu;

