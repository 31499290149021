import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import * as requirementAction from '../../store/actions/RequirementAction';
import * as requirementSelectionAction from '../../store/actions/RequirementSelectionAction';
import * as messageAction from '../../store/actions/MessageAction';
import {buildRequirementMapWithResponses} from '../../utility/RequirementHandler';

import NewRequirementModal from '../../components/Modal/NewRequirementModal/NewRequirementModal';
import RequirementToolbar from '../../components/Toolbar/RequirementToolbar';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import * as UxHelper from '../../utility/UxHelper';
import {createExcelTranslationMap} from '../../utility/UiHelper'
import MainView from '../../components/Requirements/RequirementsMainView';

import * as filterAction from '../../store/actions/RequirementFilterAction';

import FilterSection from "../../components/Filter/FilterSection";
import Filter from "../../components/Filter/Filter";
import * as requirementResponse from '../../store/actions/RequirementResponseAction';
import * as toggleResponseAction from '../../store/actions/ToggleResponseAction';
import * as toggleRequirementAction from '../../store/actions/ToggleRequirementAction';
import * as toggleCategoriesAction from '../../store/actions/ToggleCategoriesAction';
import {saveRequirementAssociation, clearSavedRequirementAssociations} from '../../store/actions/FavoritesAction';
import Spinner from '../../components/UI/Spinner/Spinner';

class CustomerMainView extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props
        this.translationMap = createExcelTranslationMap(t);

    }

    state = {
        newRequirement: false,
        requirementMapWithResponses: {},
        withResponses: true,
        requirementsWithResponsesBuilt: false
    };

    componentWillUnmount() {
        this.setState({requirementsWithResponsesBuilt: false});
    }

    componentDidMount() {
        this.props.onClearRequirementSelection();
        this.props.onClearToggleAllCategories();
        this.props.onClearToggleAllRequirements();
        this.props.onClearToggleAllResponses();
        if (this.props.account.productArea !== undefined) {
            this.props.onLoadingRequirement(this.props.account.productArea.id, this.props.account.user.languageCode);
            this.props.onLoadingRequirementResponses(this.props.account.productArea.id, this.props.account.user.languageCode);
        }
        if (this.props.filter == null) this.props.onLoadFilter();
        else this.clearFilterSections();
        UxHelper.topScroll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let requirementMapWithResponses;
        if (!this.props.requirementLoading && !this.props.responsesLoading && !this.state.requirementsWithResponsesBuilt) {
            requirementMapWithResponses = buildRequirementMapWithResponses(this.props.requirementMap, this.props.requirementResponseList);
            this.setState({requirementMapWithResponses: requirementMapWithResponses});
            this.setState({requirementsWithResponsesBuilt: true});
        }
        if (this.buildToggleInformationLists(prevProps)) {
            this.prepareToggleCategoriesInformationList(requirementMapWithResponses);
            this.prepareToggleRequirementInformationList(requirementMapWithResponses);
            this.prepareToggleResponsesInformationList(requirementMapWithResponses);
        }
        if (requirementMapWithResponses != null && prevState.requirementMapWithResponses !== requirementMapWithResponses
            && prevProps.requirementResponseList && !(prevProps.requirementResponseList.length === 0)
            && this.props.filter) {
            this.props.onApplyFilter(this.props.filter, requirementMapWithResponses, this.props.account, this.props.selectedRequirementIds, this.translationMap, this.props.allRequirementSelected);
        }
    }

    buildToggleInformationLists(prevProps) {
        return (prevProps.requirementResponseList !== this.props.requirementResponseList
            && this.toggleListsEmpty())
            || (prevProps.requirementMap !== this.props.requirementMap
                && this.toggleListsEmpty());
    }

    toggleListsEmpty() {
        return this.props.toggleCategoriesList.length === 0
            && this.props.toggleRequirementList.length === 0
            && this.props.toggleResponseList.length === 0;
    }

    prepareToggleRequirementInformationList(requirementMapWithResponses) {
        let requirementIds = [];
        for (const key in requirementMapWithResponses) {
            const requirements = requirementMapWithResponses[key];
            requirementIds = requirementIds.concat(requirements.map(requirement => requirement.id));
        }
        this.props.onToggleRequirementList(requirementIds);
    }

    prepareToggleCategoriesInformationList(requirementMapWithResponses) {
        let categoryIds = [];
        for (const key in requirementMapWithResponses) {
            categoryIds = categoryIds.concat(key);
        }
        this.props.onToggleCategoriesInformationList(categoryIds);
    }


    prepareToggleResponsesInformationList(requirementMapWithResponses) {
        let responseIds = [];
        for (const key in requirementMapWithResponses) {
            const requirements = requirementMapWithResponses[key];
            const responses = requirements.flatMap(requirement => requirement.responses);
            responseIds = responseIds.concat(responses.map(response => response.id));
        }
        this.props.onToggleResponseList(responseIds);
    }


    openNewRequirementModal() {
        this.props.onCloseShowSaveMessage();
        this.setState({newRequirement: true});
    }

    closeNewRequirementModal() {
        this.setState({newRequirement: false});
    }

    saveToMyRequirements() {
        const {t} = this.props;
        if (this.props.selectedRequirementIds.length === 0) {
            this.props.showError(t('MY_REQUIREMENTS_INFORMATION_MSG'));
            return;
        }
        this.props.onSaveAssociation(this.props.selectedRequirementIds);
        this.props.onClearRequirementSelection();
    }

    selectAllRequirements() {
        let requirementCollection = [];
        const masterMap = (this.props.filteredMap ? this.props.filteredMap : this.state.requirementMapWithResponses);
        for (const key in masterMap) {
            const element = masterMap[key];
            requirementCollection = requirementCollection.concat(...element)
        }
        this.props.onAllRequirementSelection(requirementCollection, this.props.allRequirementSelected, this.translationMap);
    }

    ///////////////////////////////////////////
    // Filtering methods start
    handleFilter(filterSection, event) {
        this.props.onFilterItemChange(filterSection, event);
    }

    handleSection(filterSection, checked) {
        this.props.onFilterSectionChange(filterSection, checked)
    }

    clearFilterSections() {
        for (const filterSection in this.props.filter) {
            this.props.onFilterSectionChange(this.props.filter[filterSection].name, false);
        }
    }

    resetAll() {
        this.clearFilterSections();
        this.props.onApplyFilter(this.props.filter, (this.state.requirementMapWithResponses ? this.state.requirementMapWithResponses : this.props.requirementMap));
    }

    applyFilter() {
        //load filtered Requirements
        this.props.onApplyFilter(this.props.filter, (this.state.requirementMapWithResponses ? this.state.requirementMapWithResponses : this.props.requirementMap), this.props.account, this.props.selectedRequirementIds, this.translationMap, this.props.allRequirementSelected);
    }

// Filtering methods end
///////////////////////////////////////////

    render() {
        const {t} = this.props;
        if (this.props.requirementLoading || this.props.responsesLoading) {
            return <Spinner/>;
        }
        return (
            <>  {(this.state.newRequirement ? <NewRequirementModal hide={() => this.closeNewRequirementModal()}/> : '')}
                {(this.props.filter ? <Filter title="FILTER_DEFAULT_TITLE" applyFilter={() => this.applyFilter()}
                                              resetFilter={() => this.resetAll()}>
                    <FilterSection isSubsection={true} allChecked={this.props.filter[0].allChecked}
                                   title="PRODUCER_LIST_PRODUCT_PROVIDER"
                                   filterList={this.props.filter[0].filterList}
                                   handleFilter={(filterSection, event) => this.handleFilter(filterSection, event)}
                                   setSection={(filterSection, checked) => this.handleSection(filterSection, checked)}/>
                    {/* <FilterSection isSubsection={false} title=" "
                                   filterList={this.props.filter[1].filterList}
                                   handleFilter={(filterSection, event) => this.handleFilter(filterSection, event)}
                                   setSection={(filterSection, checked) => this.handleSection(filterSection, checked)}/>*/}
                </Filter> : '')}
                <RequirementToolbar
                    saveToMyRequirements={() => this.saveToMyRequirements()}
                    clicked={() => this.openNewRequirementModal()}
                    saveToFavoritesButtonTitle={t('SAVE_TO_MY_REQUIREMENT')}
                />
                <Checkbox shadowBox='ShadowBox'
                          label={t('SELECT_ALL_REQUIREMENTS')}
                          clicked={() => this.selectAllRequirements()}
                          checked={this.props.allRequirementSelected}/>
                <MainView
                    checkboxClicked={(requirement) => this.props.onRequirementSelection(requirement, this.translationMap)}
                    checkboxChecked={this.props.selectedRequirementIds}
                    requirementsWithResponses={(this.props.filteredMap ? this.props.filteredMap : this.state.requirementMapWithResponses)}
                />
            </>
        );
    }

;
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        requirementMap: state.requirementsData.approvedRequirements,
        savedRequirementAssociations: state.requirementsData.savedRequirementAssociations,
        allRequirementAssociationsInDatabase: state.requirementsData.allRequirementAssociationsInDatabase,
        requirementStatus: state.requirementsData.status,
        requirementLoading: state.requirementsData.loading,
        selectedRequirementIds: state.selectedRequirement.selectedRequirementIds,
        allRequirementSelected: state.selectedRequirement.allRequirementSelected,
        filter: state.filteredRequirement.filter,
        filteredMap: state.filteredRequirement.filteredRequirements,
        requirementResponseList: state.requirementResponses.allRequirementResponses,
        responsesLoading: state.requirementResponses.loading,
        toggleCategoriesList: state.toggleCategories.toggleCategoriesInformationList,
        toggleRequirementList: state.toggleRequirement.toggleRequirementList,
        toggleResponseList: state.toggleResponse.toggleResponsesList
    }
};

const mapDispatchToProps = dispatch =>
{
    return {
        onLoadingRequirement: (productAreaId, languageCode) => dispatch(requirementAction.requirements(productAreaId, languageCode)),
        onLoadingRequirementResponses: (productAreaId, languageCode) => dispatch(requirementResponse.productAreaRequirementResponses(productAreaId, languageCode)),
        onRequirementSelection: (requirement, translationMap) => dispatch(requirementSelectionAction.selectRequirement(requirement, translationMap)),
        onClearRequirementSelection: () => dispatch(requirementSelectionAction.clearRequirementSelection()),
        onAllRequirementSelection: (requirementCollection, allSelected, translationMap) => dispatch(requirementSelectionAction.selectAllRequirement(requirementCollection, allSelected, translationMap)),
        onSaveAssociation: (requirementIds) => dispatch(saveRequirementAssociation(requirementIds)),
        onCloseMessageBanner: () => dispatch(clearSavedRequirementAssociations()),
        onShowSaveMessage: (text) => dispatch(messageAction.showInfo(true, text)),
        showError: (text) => dispatch(messageAction.showError({message: text}, text)),
        onCloseShowSaveMessage: () => dispatch(requirementAction.clearSavedRequirementsMessage()),
        onLoadFilter: () => dispatch(filterAction.loadCustomerMainViewFilter()),
        onFilterItemChange: (filterSection, event) => dispatch(filterAction.filterItemChanged(filterSection, event)),
        onFilterSectionChange: (filterSection, checked) => dispatch(filterAction.filterSectionChanged(filterSection, checked)),
        onApplyFilter: (filter, requirementMap, account, selectedRequirementsIds, translationMap, allRequirementSelected) => dispatch(filterAction.filterSet(filter, requirementMap, account, selectedRequirementsIds, translationMap, allRequirementSelected)),
        onClearToggleAllRequirements: () => dispatch(toggleRequirementAction.clearToggleAllRequirement()),
        onClearToggleAllCategories: () => dispatch(toggleCategoriesAction.clearToggleAllCategories()),
        onClearToggleAllResponses: () => dispatch(toggleResponseAction.clearToggleAllResponses()),
        onToggleCategoriesInformationList: (categoryIds) => dispatch(toggleCategoriesAction.toggleCategoriesInformationList(categoryIds)),
        onToggleResponseList: (responseIds) => dispatch(toggleResponseAction.toggleResponseInformationList(responseIds)),
        onToggleRequirementList: (requirementIds) => dispatch(toggleRequirementAction.toggleRequirementInformationList(requirementIds))
    }
}
;

const RequirementSelectorWithRedux = connect(mapStateToProps, mapDispatchToProps)(CustomerMainView);
export default withTranslation()(RequirementSelectorWithRedux);
