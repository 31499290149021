import React, {Component} from 'react';
import './PriceTable.css';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {ReactComponent as Plus} from '../../../../../../assets/images/plus-circle-solid.svg';
import {ReactComponent as Pen} from '../../../../../../assets/images/pen-solid.svg';
import {ReactComponent as Del} from '../../../../../../assets/images/trash-alt-regular.svg';

import {addPrice, updatePrice, deletePrice} from '../../../../../../store/actions/ProducerAction';

class PriceTable extends Component {
        initialState = {
            individual: false,
            description: '',
            userRangeFrom: '',
            userRangeTo: '',
            period: 'once',
            category: 'hosting',
            priceType: ''
        }


    state = {
        price: this.initialState,
        priceArray: [],
        addPriceRow: false,
        editPrice: false
    }
    componentDidMount(){
        this.processPriceArray();
        window.addEventListener('mousedown', this.contactEv);
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps!==this.props){
            this.setState({priceArray: []},()=> this.processPriceArray());
            this.setProductId();
        }
    }
    UNSAFE_componentWillMount(){
        window.removeEventListener('mousedown', this.contactEv);
    }

    processPriceArray = () =>{
        if(this.props.profile.prices){
            this.props.profile.prices.map((price, index)=>this.setState(prev=>{
                return {...prev, priceArray: [...prev.priceArray, {id: index, value: price, edit: true}]}
            }))
        }

    };
    contactEv = (event) => {
        if(this.state.addPriceRow && this.props.state.event === "addPrice"){
            this.priceAddEvent(event.target.className);
        }else if(this.state.editPrice && this.props.state.event === "editPrice"){
            this.priceEditEvent(event.target.className);
        }else{
           return
        }
    }

    setProductId = () => {
        this.setState({price: {...this.state.price, productId: this.props.profile.productId}});
    }

    priceAddEvent = (className) => {
        const clickSet = new Set([
            'add-new-price',
            'edit-column-price add-new-price',
            'edit-price add-new-price icon-cancel'
        ]);
        if(!clickSet.has(className)){
            this.props.addPrice(this.state.price, this.props.producerId);
            this.setState({addPriceRow: false})
            this.setState({price: {...this.state.price, ... this.initialState}})
            this.props.handleEvent(null);
        }
    };

    priceEditEvent = (className) => {
        const clickSet = new Set([
            'edit-price-field',
            'edit-column-price edit-price-field',
            'edit-price add-new-price icon-cancel'
        ]);
        if(!clickSet.has(className)){
            this.setState({editPrice: false})
            this.props.updatePrice(this.state.price, this.props.producerId)
            this.setState({price: {...this.state.price, ... this.initialState}});
            this.props.handleEvent(null);
        }
    };
    cancelAdd = () => {
        this.props.handleEvent(null).then(()=>{
            this.setState({addPriceRow: false});
            this.setState({price: {...this.state.price, ... this.initialState}});
        });
    };

    handleEdit = (id) => {
        if(this.props.state.event===null){
                this.props.handleEvent("editPrice")
                .then(()=>{
                    let editPrice =this.state.priceArray.filter(price=>price.id===id)[0];
                    this.setState({price: editPrice.value});
                    let other = this.state.priceArray.filter(price=>price.id!==id);
                    this.setState(prev=>{
                        return {...prev, priceArray: [...other, {value: editPrice.value, id:id, edit: false}]}
                    })
                    this.setState({editPrice: true});
                });
        }
    };

    handleDelete = (id)=>{
        const {t} = this.props;
        let result = window.confirm(t("PRICE_TABLE_DELETE_CONFIRMATION"));
        if(result)this.props.deletePrice(id, this.props.producerId);
    };

    handleIndividual = (event) => {
    this.setState({price: {...this.state.price, individual: event.target.value}});
    };

    handleDescription = (event) => {
        this.setState({price: {...this.state.price, description: event.target.value}});
    };


    handleUserRangeFrom = (event) => {
        const reg = /\D+/;
        if(!reg.test(event.target.value)){
            this.setState({price: {...this.state.price, userRangeFrom: event.target.value}});
        }
    };

    handleUserRangeTo = (event) => {
        const reg = /\D+/;
        if(!reg.test(event.target.value)){
            this.setState({price: {...this.state.price, userRangeTo: +event.target.value}});
        }
    }

    handlePeriod = (event) => {
        this.setState({price: {...this.state.price, period: event.target.value}});
    }



    handleCategory= (event) => {
        this.setState({price: {...this.state.price, category: event.target.value}});
    }




    handlePriceType = (event) => {
        const reg = /\D+/;
        if(!reg.test(event.target.value)){
            this.setState({price: {...this.state.price, priceType: event.target.value}});
        }
    }

    addPrice = () => {
        this.props.handleEvent("addPrice")
            .then(()=>this.setState({addPriceRow: true}))
    }

    render(){
        const {t} = this.props;

        const categoryTranslation = (key) => {
            if(key === "hosting"){
                return t("PRICE_TABLE_HOSTING")
            } else if (key === "implementation"){
                return t("PRICE_TABLE_IMPLEMENTATION")
            } else if (key === "support"){
                return t("PRICE_TABLE_SUPPORT")
            } else if (key === "license"){
                return t("PRICE_TABLE_LICENSE")
            } else if (key === "other"){
                return t("PRICE_TABLE_OTHER")
            } else {
                return
            }

        }
        const periodTranslation  = (key) => {
            if(key === "once"){
                return t("PRICE_TABLE_ONCE")
            } else if(key === "yearly"){
                return t("PRICE_TABLE_YEARLY")
            } else{
                return
            }

        }

        return(
            <table className="Price-Table" >
                <tbody>
                <tr>
                    <th className="Th-Category" >{t("PRICE_TABLE_CATEGORY")}</th>
                    <th className="Th-UserRangeFrom" >{t("PRICE_TABLE_USER_RANGE_FROM")}</th>
                    <th className="Th-UserRangeTo" >{t("PRICE_TABLE_USER_RANGE_TO")}</th>
                    <th className="Th-Period" >{t("PRICE_TABLE_Period")}</th>
                    <th className="Th-PriceType" >{t("PRICE_TABLE_PRICE")}</th>
                    <th className="Th-Individual">{t("PRICE_TABLE_INDIVIDUAL")}</th>
                    <th className="Th-Description" >{t("PRICE_TABLE_DESCRIPTION")}</th>
                    {
                        this.props.state.editable?(<th className="Th-PriceType" >{t("PRICE_TABLE_ACTIONS")}</th>):null
                    }
                </tr>
                    {this.state.priceArray?this.state.priceArray.sort((a,b)=>a.id-b.id).map(row=>(
                        <tr className={!row.edit?'edit-price-field':''}  key={row.value.id}>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit ? categoryTranslation(row.value.category) : (
                                <select  defaultValue = {row.value.category} onChange={this.handleCategory}  className={!row.edit?'edit-price-field':''} >
                                    <option  className={!row.edit?'edit-price-field':''} value='hosting' >{t("PRICE_TABLE_HOSTING")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value='implementation' >{t("PRICE_TABLE_IMPLEMENTATION")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value='support' >{t("PRICE_TABLE_SUPPORT")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value='license' >{t("PRICE_TABLE_LICENSE")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value='other' >{t("PRICE_TABLE_OTHER")}</option>
                                </select>)}
                            </td>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit?row.value.userRangeFrom:(<input className={!row.edit?'edit-price-field':''} onChange={this.handleUserRangeFrom} value={this.state.price.userRangeFrom} type="text"  />)}</td>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit?row.value.userRangeTo:(<input className={!row.edit?'edit-price-field':''} onChange={this.handleUserRangeTo} value={this.state.price.userRangeTo} type="text"/>)}</td>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit? periodTranslation(row.value.period) :(
                                <select  defaultValue = {row.value.period} onChange={this.handlePeriod}  className={!row.edit?'edit-price-field':''} >
                                    <option  className={!row.edit?'edit-price-field':''} value='once' >{t("PRICE_TABLE_ONCE")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value='yearly' >{t("PRICE_TABLE_YEARLY")}</option>
                                </select>)}
                            </td>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit?row.value.priceType:(<input className={!row.edit?'edit-price-field':''} onChange={this.handlePriceType} value={this.state.price.priceType} type="text"/>)}</td>
                            <td className={!row.edit?'edit-price-field':''}>{row.edit?row.value.individual?t("PRICE_TABLE_INDIVIDUAL"):t("PRICE_TABLE_NOT_INDIVIDUAL"):(
                                <select  defaultValue = {row.value.individual} onChange={this.handleIndividual}  className={!row.edit?'edit-price-field':''} id="individual-select" >
                                    <option  className={!row.edit?'edit-price-field':''} value={true}>{t("PRICE_TABLE_INDIVIDUAL")}</option>
                                    <option  className={!row.edit?'edit-price-field':''} value={false}>{t("PRICE_TABLE_NOT_INDIVIDUAL")}</option>
                                </select>)}
                            </td>
                            <td className="price-table-description">{row.edit?row.value.description:(<textarea className={!row.edit?'edit-price-field':''} rows="4" onChange={this.handleDescription} defaultValue={this.state.price.description}/>)}</td>
                            {
                                this.props.state.editable?(
                                <td>
                                    <div className={!row.edit?'edit-column-price edit-price-field':'edit-column-price'}>
                                    {!row.edit?(
                                        <button className="edit-price add-new-price icon-cancel" title={t("TABLE_CANCEL_BUTTON_TITLE")}   onClick={()=>this.cancelAdd()} />
                                    ):(
                                        <>
                                            <button onClick={()=>this.handleEdit(row.id)} title={t("PRICE_TABLE_EDIT_BUTTON_TITLE")} className='edit-price edit-price-field'>
                                                <Pen className="icon-edit"/>
                                            </button>
                                            <button onClick={()=>this.handleDelete(row.value.id)} title={t("PRICE_TABLE_DELETE_BUTTON_TITLE")} className='edit-price edit-price-field'>
                                                <Del className="icon-del"/>
                                            </button>
                                        </>)}
                                    </div>
                                </td>):null
                            }

                        </tr>
                    )):''}
                     {this.state.addPriceRow?(
                     <tr className="add-new-price" id="add-button-field">
                        <td className="add-new-price" >
                        <select  defaultValue = {this.state.price.category} onChange={this.handleCategory}  className="add-new-price">
                            <option  className="add-new-price" value='hosting' >{t("PRICE_TABLE_HOSTING")}</option>
                            <option  className="add-new-price" value='implementation' >{t("PRICE_TABLE_IMPLEMENTATION")}</option>
                            <option  className="add-new-price"  value='support' >{t("PRICE_TABLE_SUPPORT")}</option>
                            <option  className="add-new-price" value='license' >{t("PRICE_TABLE_LICENSE")}</option>
                            <option  className="add-new-price" value='other' >{t("PRICE_TABLE_OTHER")}</option>
                        </select>
                        </td>
                        <td className="add-new-price" ><input type="text" className="add-new-price" onChange={this.handleUserRangeFrom} value={this.state.price.userRangeFrom}/></td>
                        <td className="add-new-price" ><input type="text" className="add-new-price" onChange={this.handleUserRangeTo} value={this.state.price.userRangeTo} /></td>
                        <td className="add-new-price" >
                            <select  defaultValue = {this.state.price.period} onChange={this.handlePeriod}  className="add-new-price" >
                                <option  className="add-new-price" value='once' >{t("PRICE_TABLE_ONCE")}</option>
                                <option  className="add-new-price" value='yearly' >{t("PRICE_TABLE_YEARLY")}</option>
                            </select>
                        </td>
                        <td className="add-new-price" ><input type="text" className="add-new-price" onChange={this.handlePriceType} value={this.state.price.priceType}/></td>
                        <td className="add-new-price" >
                            <select  defaultValue = {this.state.price.individual} onChange={this.handleIndividual}  className="add-new-price"  id="Producer-Country" name="country">
                                <option  className="add-new-price"  value={true}>{t("PRICE_TABLE_INDIVIDUAL")}</option>
                                <option  className="add-new-price"  value={false}>{t("PRICE_TABLE_NOT_INDIVIDUAL")}</option>
                            </select>
                        </td>
                        <td className="add-new-price" ><textarea rows="4" className="add-new-price" onChange={this.handleDescription} defaultValue={this.state.price.description}/></td>
                        <td>
                            <div className="edit-column-price add-new-price">
                                <button className="edit-price add-new-price icon-cancel" title={t("TABLE_CANCEL_BUTTON_TITLE")}  onClick={()=>this.cancelAdd()} />
                            </div>
                        </td>
                    </tr>):
                        this.props.state.editable?(
                            <tr id="add-button-field">
                                <td  colSpan="8">
                                    <div className="col-right-plus" title={t("PRICE_TABLE_ADD_BUTTON_TITLE")}  onClick={()=>this.addPrice()}>
                                        <Plus id="row-add-button path"/>
                                    </div>
                                </td>
                            </tr>):null
                   }
                </tbody>

            </table>
        )
    }
}
const mapStateToProps = state => {
    return {
        producerId: state.login.account.id,
        profile: state.producer.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addPrice: (body, producerId) => dispatch(addPrice(body, producerId)),
        updatePrice: (body, producerId) => dispatch(updatePrice(body, producerId)),
        deletePrice: (id, producerId) => dispatch(deletePrice(id, producerId))
    }
};

const PriceTableWithRedux = connect(mapStateToProps, mapDispatchToProps)(PriceTable);
export default withTranslation()(PriceTableWithRedux);
