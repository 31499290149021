import React from 'react';

import './ArrowLabel.css';
import arrow from '../../../../assets/images/arrow-right-solid.svg';


const arrowLabel = (props) => {
    return (
        <div>
            <img className='Arrow' src={arrow} alt='' style={arrowGap}/>
            <label>{props.label}</label>
        </div>
    );
}

const arrowGap = {
    marginRight: '15px'
};

export default arrowLabel;
