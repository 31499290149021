import React from 'react';
import Modal from 'react-modal';
import {useTranslation} from 'react-i18next';
import './AllModal.css';
import cross from '../../assets/images/times-solid.svg';

/**
 * General modal can be used to create new modals or used as standard modal
 */
Modal.setAppElement('#root');
const generalModal = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();
    return (
        <div>
            <Modal className="DefaultModal" isOpen={props.isOpen} onRequestClose={props.close}>
                <div className="ModalLabel">
                    {t(props.header)}
                    <img src={cross} onClick={props.close}/>
                </div>
                <div className='ModalContent'>
                    {props.children}
                </div>
            </Modal>
        </div>
    )
}

export default generalModal;
