import {Route, Switch} from 'react-router-dom';
import HelpButton from '../components/HelpButton/HelpButton';
import React from 'react';
import {
    CUSTOMER_MY_PROFILE_ROUTE_PATH,
    CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH,
    CUSTOMER_PRODUCER_LIST_ROUTE_PATH,
    CUSTOMER_PRODUCER_PROFILE_ROUTE_PATH,
    CUSTOMER_REQUIREMENTS_ROUTE_PATH,
    PRODUCER_CUSTOMER_LIST_ROUTE_PATH, PRODUCER_MY_FAVORITE_REQUIREMENTS_ROUTE_PATH,
    PRODUCER_MY_PROFILE_ROUTE_PATH,
    PRODUCER_REQUIREMENTS_ROUTE_PATH
} from './RoutingPaths';

const HelpRouting = () => {
    return (
        <Switch>
            <Route path={CUSTOMER_REQUIREMENTS_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_CUSTOMER_REQUIREMENTS'/>}/>
            <Route path={CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_CUSTOMER_MYREQUIREMENTS'/>}/>
            <Route path={CUSTOMER_PRODUCER_LIST_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_CUSTOMER_PRODUCERLIST'/>}/>
            <Route path={CUSTOMER_MY_PROFILE_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_CUSTOMER_MYPROFILE'/>}/>
            <Route path={CUSTOMER_PRODUCER_PROFILE_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_CUSTOMER_PRODUCERPROFILE'/>}/>

            <Route path={PRODUCER_REQUIREMENTS_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_PRODUCER_REQUIREMENTS'/>}/>
            <Route path={PRODUCER_MY_PROFILE_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_PRODUCER_MYPROFILE'/>}/>
            <Route path={PRODUCER_CUSTOMER_LIST_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_PRODUCER_CUSTOMERLIST'/>}/>
            <Route path={PRODUCER_MY_FAVORITE_REQUIREMENTS_ROUTE_PATH} render={() => <HelpButton helpContext ='HELP_PRODUCER_MY_FAVORITES' />} />

            <Route path='' render={() =><HelpButton helpContext ='HELP_LOGIN'/>}/>
        </Switch>
    );
}

export default HelpRouting;
