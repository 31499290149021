export const LOAD_SUPPORTED_LANGUAGES_FAIL = 'load_supported_languages_fail';
export const LOAD_SUPPORTED_LANGUAGES_SUCCESS = 'load_supported_languages_success';
export const LOAD_SUPPORTED_LANGUAGES_START = 'load_supported_languages_start';

export const LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_FAIL = 'load_all_requirement_responses_for_product_area_fail';
export const LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_SUCCESS = 'load_all_requirement_responses_for_product_area_success';
export const LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_START = 'load_all_requirement_responses_for_product_area_start';

export const TOGGLE_RESPONSE = 'toggle_response';
export const TOGGLE_RESPONSES = 'toggle_responses';
export const CLEAR_TOGGLE_ALL_RESPONSES = 'clear_toggle_all_responses';
export const BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_START = 'build_toggle_response_information_list_start';
export const BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_SUCCESS = 'build_toggle_response_information_list_success';
export const BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_FAIL = 'build_toggle_response_information_list_fail;'


export const LOAD_REQUIREMENT_RESPONSE_ASSET_SUCCESS = 'load_requirement_response_asset_success';
export const LOAD_REQUIREMENT_RESPONSE_ASSET_FAIL = 'load_requirement_response_asset_fail';
export const SAVE_REQUIREMENT_RESPONSE_ASSET_SUCCESS = 'upload_requirement_response_asset_success';
export const SAVE_REQUIREMENT_RESPONSE_ASSET_FAIL = 'upload_requirement_response_asset_fail';
export const DELETE_REQUIREMENT_RESPONSE_ASSET_SUCCESS = 'delete_requirement_response_asset_success';
export const DELETE_REQUIREMENT_RESPONSE_ASSET_FAIL = 'delete_requirement_response_asset_fail';

export const CREATE_REQUIREMENT_FAIL = 'save_requirement_fail';
export const CREATE_REQUIREMENT_SUCCESS = 'save_requirement_success';
export const CREATE_REQUIREMENT_START = 'save_requirement_start';

export const LOGIN_FAIL = 'login_fail';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_START = 'login_start';

export const USER_LOGOUT = 'logout';

export const LOAD_REQUIREMENTS_FAIL = 'load_requirements_fail';
export const LOAD_REQUIREMENTS_SUCCESS = 'load_requirements_success';
export const LOAD_REQUIREMENTS_START = 'load_requirements_start';

export const LOAD_CUSTOMER_REQUIREMENTS_START = 'load_customer_requirements_start';
export const LOAD_CUSTOMER_REQUIREMENTS_SUCCESS = 'load_customer_requirements_success';
export const LOAD_CUSTOMER_REQUIREMENTS_FAIL = 'load_customer_requirements_fail';

export const UPDATE_REQUIREMENT_FAIL = 'update_requirement_fail';
export const UPDATE_REQUIREMENT_SUCCESS = 'update_requirement_success';
export const UPDATE_REQUIREMENT_START = 'update_requirement_start';

export const SUBMIT_REQUIREMENT_FAIL = 'submit_requirement_fail';
export const SUBMIT_REQUIREMENT_SUCCESS = 'submit_requirement_success';
export const SUBMIT_REQUIREMENT_START = 'submit_requirement_start';

export const DELETE_REQUIREMENT_FAIL = 'delete_requirement_fail';
export const DELETE_REQUIREMENT_SUCCESS = 'delete_requirement_success';
export const DELETE_REQUIREMENT_START = 'delete_requirement_start';

export const ADMIN_REQUIREMENTS_FAIL = 'admin_requirements_fail';
export const ADMIN_REQUIREMENTS_SUCCESS = 'admin_requirements_success';
export const ADMIN_REQUIREMENTS_START = 'admin_requirements_start';

export const LOAD_REQUIREMENT_STATUS_FAIL = 'load_requirement_status_fail';
export const LOAD_REQUIREMENT_STATUS_SUCCESS = 'load_requirement_status_success';
export const LOAD_REQUIREMENT_STATUS_START = 'load_requirement_status_start';

export const HTTP_STATUS_UNAUTHORIZED = 'http_status_unauthorized';

//Use these SHOW- actions to force a Message to be shown.
//When renaming: Do not forget the usages in MessageAction and Message Reducer
export const SHOW_ERROR = 'show_error';
export const SHOW_INFO = 'show_info';
export const MESSAGE_ACCEPTED = 'message_accepted'; //Hides Message component.

export const SELECTED_REQUIREMENT_FAIL = 'selected_requirement_fail';
export const SELECTED_REQUIREMENT_SUCCESS = 'selected_requirement_success';
export const SELECTED_REQUIREMENT_START = 'selected_requirement_start';
export const SELECTED_REQUIREMENT_CLEAR = 'selected_requirement_clear';

export const DESELECTED_REQUIREMENT_FAIL = 'deselected_requirement_fail';
export const DESELECTED_REQUIREMENT_SUCCESS = 'deselected_requirement_success';
export const DESELECTED_REQUIREMENT_START = 'deselected_requirement_start';

export const FILTER_SELECTED_REQUIREMENTS_FAIL = 'filter_selected_requirements_fail';
export const FILTER_SELECTED_REQUIREMENTS_SUCCESS = 'filter_selected_requirements_success';
export const FILTER_SELECTED_REQUIREMENTS_START = 'filter_selected_requirements_start';

export const REMOVE_REQUIREMENT_ASSOCIATION_FAIL = 'remove_requirement_connection_fail';
export const REMOVE_REQUIREMENT_ASSOCIATION_SUCCESS = 'remove_requirement_connection_success';
export const REMOVE_REQUIREMENT_ASSOCIATION_START = 'remove_requirement_connection_start';

export const SAVE_REQUIREMENT_ASSOCIATION_FAIL = 'save_requirement_association_fail';
export const SAVE_REQUIREMENT_ASSOCIATION_SUCCESS = 'save_requirement_association_success';
export const SAVE_REQUIREMENT_ASSOCIATION_START = 'save_requirement_association_start';

export const CLEAR_SAVED_REQUIREMENT_ASSOCIATIONS = 'clear_saved_requirement_associations';
export const CLEAR_SAVED_REQUIREMENT_MESSAGE = 'clear_saved_requirement_message';

export const LOAD_PRODUCER_START = 'load_producer_start';
export const LOAD_PRODUCER_SUCCESS = 'load_producer_success';
export const LOAD_PRODUCER_FAIL = 'load_producer_fail';

export const LOAD_PRODUCER_LIST = 'load_producer_list';
export const CLEAR_PRODUCER_LIST = 'clear_producer_list';

export const LOAD_COUNTRIES = 'load_countries';

export const LOAD_CUSTOMER_INFO_FAIL = 'load_customer_info_fail';

export const TOGGLE_CATEGORIES = 'toggle_categories';
export const TOGGLE_CATEGORY = 'toggle_category';
export const CLEAR_TOGGLE_ALL_CATEGORIES = 'clear_toggle_all_categories';

export const BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_START = 'build_toggle_categories_information_list_start';
export const BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_SUCCESS = 'build_toggle_categories_information_list_success';
export const BUILD_TOGGLE_CATEGORIES_INFORMATION_LIST_FAIL = 'build_toggle_categories_information_list_fail';

export const LOAD_CUSTOMER_LIST = 'load_customer_list';
export const LOAD_CUSTOMER_PROFILE = 'load_customer-profile';

export const LOAD_PRODUCER_REQUIREMENT_RESPONSES_FAIL = 'load_requirement_responses_fail';
export const LOAD_PRODUCER_REQUIREMENT_RESPONSES_SUCCESS = 'load_requirement_responses_success';
export const LOAD_PRODUCER_REQUIREMENT_RESPONSES_START = 'load_requirement_responses_start';

export const SAVE_REQUIREMENT_RESPONSE_FAIL = 'save_requirement_response_fail';
export const SAVE_REQUIREMENT_RESPONSE_SUCCESS = 'save_requirement_response_success';
export const SAVE_REQUIREMENT_RESPONSE_START = 'save_requirement_response_start';

export const TOGGLE_REQUIREMENT = 'toggle_requirement';
export const TOGGLE_REQUIREMENTS = 'toggle_requirements';
export const CLEAR_TOGGLE_ALL_REQUIREMENT = 'clear_toggle_all_requirements';
export const BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_FAIL = 'build_toggle_requirement_information_list_fail';
export const BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_SUCCESS = 'build_toggle_requirement_information_list_success';
export const BUILD_TOGGLE_REQUIREMENT_INFORMATION_LIST_START = 'build_toggle_requirement_information_list_start';

export const FILTER_SECTION_CHANGED = 'filter_section_change';
export const FILTER_ITEM_CHANGED = 'filter_item_change';
export const FILTER_RESET = 'filter_reset';
export const FILTER_SET = 'filter_set';
export const FILTER_SET_FAIL = 'filter_set_fail';
export const FILTER_INIT = 'filter_init';
export const FILTER_INIT_FAIL = 'filter_init_fail';
export const CLEAR_FILTER = 'clear_filter';
export const REMOVE_FROM_FILTERED_REQUIREMENTS = 'remove_from_filtered_requirements';

export const LOAD_PRODUCERS_FAVORITES_START = 'load_producers_favorites_start';
export const LOAD_PRODUCERS_FAVORITES_SUCCESS = 'load_producers_favorites_success';
export const LOAD_PRODUCERS_FAVORITES_FAIL = 'load_producers_favorites_fail';
export const SAVE_TO_PRODUCERS_FAVORITES_START = 'save_to_producers_favorites_start';
export const SAVE_TO_PRODUCERS_FAVORITES_SUCCESS = 'save_to_producers_favorites_success';
export const SAVE_TO_PRODUCERS_FAVORITES_FAIL = 'save_to_producers_favorites_fail';
export const REMOVE_FROM_PRODUCER_FAVORITES_START = 'remove_from_producer_favorites_start';
export const REMOVE_FROM_PRODUCER_FAVORITES_SUCCESS = 'remove_from_producer_favorites_success';
export const REMOVE_FROM_PRODUCER_FAVORITES_FAIL = 'remove_from_producer_favorites_fail';

export const LOAD_CATEGORY_SUCCESS = 'load_category_names_success';
export const LOAD_CATEGORY_START = 'load_category_names_start';
export const LOAD_CATEGORY_FAIL = 'load_category_names_fail';

export const LOAD_PRODUCT_AREAS_SUCCESS = 'load_product_area_names_success';
export const LOAD_PRODUCT_AREAS_START = 'load_product_area_names_start';
export const LOAD_PRODUCT_AREAS_FAIL = 'load_product_area_names_fail';
