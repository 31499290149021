import * as actionTypes from './actionTypes';
import {createToggleInformationList} from '../../utility/ToggleHandler';
import {sendReadResponseActivity} from '../../activity/ActivityNotifier';

function buildInformationListStart() {
    return {
        type: actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_START
    };
}

function buildInformationListSuccess(toggleInformation) {
    return {
        type: actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_SUCCESS,
        toggleResponsesList: toggleInformation
    };
}

function buildInformationListFail(error) {
    return {
        type: actionTypes.BUILD_TOGGLE_RESPONSE_INFORMATION_LIST_FAIL,
        error: error
    };
}

export function toggleResponseInformationList(responseIds) {
    return (dispatch) => {
        dispatch(buildInformationListStart());
        try {
            const toggleInformation = createToggleInformationList(responseIds);
            dispatch(buildInformationListSuccess(toggleInformation))
        } catch (error) {
            dispatch(buildInformationListFail(error))
        }
    };
}


export function clearToggleAllResponses() {
    return {
        type: actionTypes.CLEAR_TOGGLE_ALL_RESPONSES
    };
}


export function toggleResponse(responseId) {
    sendReadResponseActivity([responseId]);
    return (dispatch) => {
        dispatch(toggle(responseId))
    };
}

export function toggleResponses(toggleBoolean, listForActivity) {
    console.log(listForActivity);
    if(!toggleBoolean)sendReadResponseActivity(listForActivity.map(toggle => toggle.id));
    return (dispatch) => {
        dispatch(toggleAll(toggleBoolean))
    }
}

function toggleAll(toggleBoolean) {
    return {
        type: actionTypes.TOGGLE_RESPONSES,
        toggleAllResponses: !toggleBoolean
    };
}

function toggle(responseId) {
    return {
        type: actionTypes.TOGGLE_RESPONSE,
        responseId: responseId
    }
}
