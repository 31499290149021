import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Filter.css';

//This component needs to be controlled by father component.
const FilterSection = (props) => {

    const {t} = useTranslation();
    return (
        <>
            <div className="default-filter">
                {props.isSubsection || props.title? <div className = "default-filter-title" >
                    {props.isSubsection? <input className="filter-checkbox" name="section"
                                                onChange={(event) => props.setSection( props.title, event.target.checked)}
                                                checked={props.allChecked}
                                                type="checkbox"/>:''}{t(props.title)}
                </div>:''}
                {props.filterList ? props.filterList.sort((a, b) => a.id - b.id).map(filterEntry => (
                    <div key={filterEntry.id} className="default-filter-item">
                        <input className="filter-checkbox" name={filterEntry.name} id={filterEntry.id}
                               onChange={( event) => props.handleFilter(props.title, event)}
                               checked={filterEntry.checked} type="checkbox"/>
                        <label htmlFor={filterEntry.id}>{t(filterEntry.name)}</label>
                    </div>)) : ''
                }
            </div>
        </>
    )
};

export default FilterSection;