import NavigationLink from '../../../components/Navigation/NavigationLink/NavigationLink';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './ProducerMenuBar.css';

const ProducerMenuBar = () => {
    const {t} = useTranslation();
    let profilePublished =  useSelector(state=>state.producer.profile.profilePublished);
    return (
        <div className='ProducerMenuBar'>
            <ul className='ProducerNavigationBar'>
                <ul className='ProducerNavigationBar Routing'>
                    <NavigationLink sublineOrange={!profilePublished} link='/producer/requirements'>{t("REQUIREMENTS_CATALOGUE_MENU")}</NavigationLink>
                    <NavigationLink sublineOrange={!profilePublished} link='/producer/myfavoriterequirements'>{t('My_FAVORITE_REQUIREMENTS')}</NavigationLink>
                    <NavigationLink sublineOrange={!profilePublished} link='/producer/myprofile'>{t('MY_PROFILE_PRODUCER_MENU')}</NavigationLink>
                    <NavigationLink sublineOrange={!profilePublished} link='/producer/customerlist'>{t("INTERESTED_IN_PRODUCT_MENU")}</NavigationLink>
					<NavigationLink sublineOrange={!profilePublished} link='/producer/logout'>{t("LOGOUT")}</NavigationLink>
                </ul>
            </ul>
        </div>
    );
};

export default ProducerMenuBar;
