import * as actionTypes from '../actions/actionTypes';

const initialState = {
    type: actionTypes.MESSAGE_ACCEPTED,
    text: '',
    error: null,
    info: null,
    message: null
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_ERROR:
            return {
                ...state,
                type: actionTypes.SHOW_ERROR,
                text: (action.text ? action.text : (action.error.message ? action.error.message : action.error))
            }
        case actionTypes.SHOW_INFO:
            return {
                ...state,
                type: actionTypes.SHOW_INFO,
                text: (action.text === undefined ? action.info.message : action.text)
            }
        case actionTypes.MESSAGE_ACCEPTED:
            return {
                ...state,
                type: actionTypes.MESSAGE_ACCEPTED,
                text: '',
                error: null,
                info: null
            }
        default:
            return state;
    }
};

export default messageReducer;
