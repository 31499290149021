import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {producerFavorites, removeFromProducerFavorites} from '../../../store/actions/FavoritesAction';
import {buildRequirementMapWithResponses} from '../../../utility/RequirementHandler';
import {topScroll} from '../../../utility/UxHelper';
import {calculateEditStatus} from '../../../utility/CategoryStatusCalculator';
import {
    clearRequirementSelection,
    selectRequirement,
    selectAllRequirement
} from '../../../store/actions/RequirementSelectionAction';

import RequirementToolbar from '../../../components/Toolbar/RequirementToolbar';
import ProducerFilterArea from '../../../components/Filter/ProducerFilterArea/ProducerFilterArea';
import InfoLabel from '../../../components/UI/Label/InfoLabel/InfoLabel';
import ProducerToggleRequirementView
    from '../../../components/Requirements/ProducerToggleRequirementView/ProducerToggleRequirementView';
import {createExcelTranslationMap} from '../../../utility/UiHelper';
import {clearToggleAllRequirement} from '../../../store/actions/ToggleRequirementAction';
import {clearToggleAllCategories} from '../../../store/actions/ToggleCategoriesAction';

class MyFavoriteRequirements extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props
        this.translationMap = createExcelTranslationMap(t);

    }

    state = {
        favoriteRequirementsWithResponses: {},
        categoryEditStatus: {}
    }

    componentDidMount() {
        this.props.onLoadingMyFavorites(this.props.account.id, this.props.account.user.languageCode);
        this.props.onClearToggleAllCategories();
        this.props.onClearToggleAllRequirements();
        topScroll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let favoritesMapWithResponses;
        if (prevProps.requirementResponseList !== this.props.requirementResponseList || prevProps.favoriteRequirements !== this.props.favoriteRequirements) {
            favoritesMapWithResponses = this.buildRequirementMapWithResponses();
            this.setState({favoriteRequirementsWithResponses: favoritesMapWithResponses});
        }
    }

    buildRequirementMapWithResponses() {
        let requirementMapWithResponses = {};
        if (this.props.favoriteRequirements === undefined) {
            return;
        }
        requirementMapWithResponses = buildRequirementMapWithResponses(this.props.favoriteRequirements, this.props.requirementResponseList);
        this.setState({favoriteRequirementsWithResponses: requirementMapWithResponses});
        this.changeRequirementsEditStatus(requirementMapWithResponses);

        return requirementMapWithResponses;
    }

    changeRequirementsEditStatus(requirementMapWithResponses) {
        let categoryRequirementsEditStatus = {};
        for (const categoryKey in requirementMapWithResponses) {
            categoryRequirementsEditStatus[categoryKey] = calculateEditStatus(requirementMapWithResponses[categoryKey]);
        }
        this.setState({categoryEditStatus: categoryRequirementsEditStatus});
    }

    selectAllRequirements() {
        let requirementCollection = [];
        const masterMap = (this.props.filteredMap ? this.props.filteredMap : this.state.favoriteRequirementsWithResponses);
        for (const key in masterMap) {
            const element = masterMap[key];
            requirementCollection = requirementCollection.concat(...element)
        }
        this.props.onAllRequirementSelection(requirementCollection, this.props.allRequirementSelected, this.translationMap);
    }

    render() {
        const {t} = this.props;
        if (this.props.favoriteRequirements.length === 0) {
            return <InfoLabel text={t('NO_FAVORITES')}/>
        }
        return (
            <>
                <RequirementToolbar/>
                <ProducerFilterArea requirementMapWithResponses={this.state.favoriteRequirementsWithResponses}/>
                <ProducerToggleRequirementView requirementsWithResponses={this.state.favoriteRequirementsWithResponses}
                                               categoryEditStatus={this.state.categoryEditStatus}
                                               requirementDeletable={true}
                                               deleteRequirement={(requirement) => this.remove(requirement)}
                                               selectRequirementAction={(requirement) => this.props.onRequirementSelection(requirement, this.translationMap)}
                                               selectedRequirementIds={this.props.selectedRequirementIds}
                                               selectAllRequirementAction={() => this.selectAllRequirements()}
                                               allSelectedRequirements={this.props.allRequirementSelected}
                />
            </>
        );
    }

    remove(requirement) {
        return this.props.onDeletingMyFavorites(requirement.id, requirement.categoryId);
    }
}

const mapStateToProps = state => {
    return {
        account: state.login.account,
        favoriteRequirements: state.favorites.producerFavorites,
        requirementResponseList: state.requirementResponses.allRequirementResponses,
        filter: state.filteredRequirement.filter,
        filteredMap: state.filteredRequirement.filteredRequirements,
        selectedRequirementIds: state.selectedRequirement.selectedRequirementIds,
        allRequirementSelected: state.selectedRequirement.allRequirementSelected
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingMyFavorites: (id, languageCode) => dispatch(producerFavorites(id, languageCode)),
        onDeletingMyFavorites: (requirementId, categoryId) => dispatch(removeFromProducerFavorites(requirementId, categoryId)),
        onClearRequirementSelection: () => dispatch(clearRequirementSelection()),
        onRequirementSelection: (requirement, translationMap) => dispatch(selectRequirement(requirement, translationMap)),
        onAllRequirementSelection: (requirementCollection, allSelected, translationMap) => dispatch(selectAllRequirement(requirementCollection, allSelected, translationMap)),
        onClearToggleAllRequirements: () => dispatch(clearToggleAllRequirement()),
        onClearToggleAllCategories: () => dispatch(clearToggleAllCategories())
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MyFavoriteRequirements));
