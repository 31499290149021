import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import * as logoutAction from '../../store/actions/LogoutAction';
import i18next from 'i18next';

class Logout extends Component {

    logoutFunction() {
        this.props.onLogout();
        i18next.changeLanguage(navigator.language);
        return <Redirect to='/'/>
    }

    render() {
        return (
            <>
                {this.logoutFunction()}
            </>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logoutAction.logout()),
    }
};

export default withTranslation()(connect(null, mapDispatchToProps)(Logout));
