import React, {Component} from 'react';
import uploadIcon from '../../assets/images/file-upload-solid.svg';
import {withTranslation} from 'react-i18next';

/**
 * props:
 * uploadAsset: action to save a asset in database
 * ownerId: to identify to whom the asset belong
 * assetType: need to identify which database table should used
 * uploadError: Show error on gui
 * */
class AssetInput extends Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    handleAssetUpload(event) {
        event.preventDefault();
        if (this.fileInput.current.files[0].name !== 0) {
            let formData = new FormData();
            formData.append('document', this.fileInput.current.files[0]);
            formData.append('type', this.props.assetType);
            formData.append('ownerId', this.props.ownerId);
            formData.append('requirementId', this.props.requirementId);
            this.props.uploadAsset(this.props.ownerId, formData);
        }
    }

    render() {
        const {t} = this.props;
        return (
            <label>
                <div><img src={uploadIcon} alt=''/></div>
                <div>{t('UPLOAD_DOCUMENTS')}</div>
                <input type="file"
                       ref={this.fileInput}
                       onChange={this.handleAssetUpload.bind(this)}
                       onClick={(event => event.target.value = null)}
                       style={{display: 'none'}}/>
            </label>
        );
    }
}

export default withTranslation()(AssetInput);

