import React from 'react';
import Modal from 'react-modal';
import '../AllModal.css';
import GeneralModal from '../GeneralModal';

Modal.setAppElement('#root');
const DetailsModal = ({header, isOpen, details, close}) => {
    return (
        <div>
            <GeneralModal header={header}
                          isOpen={isOpen}
                          close={close}>
                <textarea readOnly={true} className="ModalTextarea" rows={10} value={details}/>
            </GeneralModal>
        </div>
    )
}
export default DetailsModal;
