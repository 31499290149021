import React, {Component} from 'react';
import './CustomerProfile.css'
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {loadCountries} from '../../../store/actions/CountriesAction';
import {updateCustomerProfile, updateCustomerLoginInformation} from '../../../store/actions/CustomerInfoActions';
import * as UxHelper from '../../../utility/UxHelper';


class CustomerProfile extends Component {
    state = {
        profile: {},
        editMode: false
    }

    componentDidMount() {
        window.addEventListener("mousedown", this.clickHandler);
        if (!this.props.countries) this.props.loadCountries();
        if (this.props.customerInfo) this.updateProcess();
        UxHelper.topScroll();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerInfo !== this.props.customerInfo) {
            this.updateProcess();
        }

    }

    UNSAFE_componentWillMount() {
        window.removeEventListener('mousedown', this.clickHandler);
    }

    updateProcess = () => {
        this.setState({
            profile: {
                name: this.props.customerInfo.name,
                productAreaId: this.props.customerInfo.productAreaId,
                countryId: this.props.customerInfo.countryId,
                personCount: this.props.customerInfo.personCount,
                email: this.props.customerInfo.email,
                readyForContact: this.props.customerInfo.readyForContact,
				readyForReference: this.props.customerInfo.readyForReference
            }
        })
        this.props.updateLoginInformation({
            ...this.props.account,
            countryId: this.props.customerInfo.countryId,
            name: this.props.customerInfo.name,
            personCount: this.props.customerInfo.personCount,
            readyForContact: this.props.customerInfo.readyForContact,
			readyForReference: this.props.customerInfo.readyForReference
        });
    }
    clickHandler = (event) => {
        let set = new Set(["INPUT", "SELECT"]);
        if (this.state.editMode) {
            if (!set.has(event.target.nodeName)) {
                this.setState({editMode: false}, () => this.props.updateProfile(this.props.id, this.state.profile))
            }
        }
    }
    handleEvent = () => {
        return new Promise((resolve, reject) => {
            this.setState({editMode: true}, () => resolve(this.state.editMode))
        })
    };

    handler = (field) => {
        return event => {
            let profileObj = this.state.profile;
            profileObj[field] = event.target.value;
            if (this.state.editMode) {
                this.setState({profile: profileObj})
            } else {
                this.handleEvent().then(() => {
                    this.setState({profile: profileObj})
                })
            }
        }
    };

    render() {
        const {t, i18n} = this.props;
        return (
            <div className="customer-profile-container">
                <div className="customer-profile-field">
                    <div className="customer-profile-field-left">
                        {t("CUSTOMER_PROFILE_NAME")}
                    </div>
                    <div className="customer-profile-field-right">
                        <input type="text" onChange={this.handler("name")}
                               value={this.state.profile.name ? this.state.profile.name : ''}/>
                    </div>
                </div>
                <div className="customer-profile-field">
                    <div className="customer-profile-field-left">
                        {t("CUSTOMER_PROFILE_COUNTRY")}
                    </div>
                    <div className="customer-profile-field-right">
                        <select onChange={this.handler("countryId")}
                                value={this.state.profile.countryId ? this.state.profile.countryId : ''}>
                            {this.props.countries ?
                                this.props.countries.map(country => (
                                    <option key={country.id} value={country.id}>
                                        {
                                            i18n.exists(country.name) ? (
                                                t(country.name)
                                            ) : country.name
                                        }
                                    </option>
                                )) : null
                            }
                        </select>
                    </div>
                </div>
                <div className="customer-profile-field">
                    <div className="customer-profile-field-left">
                        {t("CUSTOMER_PROFILE_EMAIL")}
                    </div>
                    <div className="customer-profile-field-right">
                        <input type="text" onChange={this.handler("email")}
                               value={this.state.profile.email ? this.state.profile.email : ''}/>
                    </div>
                </div>
                <div className="customer-profile-field">
                    <div className="customer-profile-field-left">
                        {t("CUSTOMER_PROFILE_PERSON_COUNT")}
                    </div>
                    <div className="customer-profile-field-right">
                        <input type="text" onChange={this.handler("personCount")}
                               value={this.state.profile.personCount ? this.state.profile.personCount : ''}/>
                    </div>
                </div>
                <div className="customer-profile-field">
                    <div className="customer-profile-text">
                        {t("CUSTOMER_PROFILE_TEXT")}
                    </div>
                </div>
                <div className="customer-profile-selector-container">
                    <select onChange={this.handler("readyForContact")}
                            value={this.state.profile.readyForContact ? this.state.profile.readyForContact : false}
                            className="customer-profile-selector">
                        <option value={true}>
                            {t("CUSTOMER_PROFILE_DROPDOWN_YES")}
                        </option>
                        <option value={false}>
                            {t("CUSTOMER_PROFILE_DROPDOWN_NO")}
                        </option>
                    </select>
                </div>
                <div className="customer-profile-field">
                    <div className="customer-profile-text">
                        {t("CUSTOMER_PROFILE_REFERENCE_TEXT")}
                    </div>
                </div>
                <div className="customer-profile-selector-container">
                    <select onChange={this.handler("readyForReference")}
                            value={this.state.profile.readyForReference ? this.state.profile.readyForReference : false}
                            className="customer-profile-selector">
                        <option value={true}>
                            {t("CUSTOMER_PROFILE_REFERENCE_DROPDOWN_YES")}
                        </option>
                        <option value={false}>
                            {t("CUSTOMER_PROFILE_REFERENCE_DROPDOWN_NO")}
                        </option>
                    </select>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        countries: state.countries,
        customerInfo: state.customerInfo.profile,
        id: state.login.account.id,
        account: state.login.account
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadCountries: () => dispatch(loadCountries()),
        updateProfile: (id, body) => dispatch(updateCustomerProfile(id, body)),
        updateLoginInformation: (body) => dispatch(updateCustomerLoginInformation(body))
    }
};
const CustomerProfileWithRedux = connect(mapStateToProps, mapDispatchToProps)(CustomerProfile);
export default withTranslation()(CustomerProfileWithRedux);
