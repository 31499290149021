import React from 'react';
import {useTranslation} from 'react-i18next';
import reactIcon from '../../../../assets/images/react.png';
import micronautIcon from '../../../../assets/images/micronaut-swirl.png';
import postgresqlIcon from '../../../../assets/images/postgresql.png';
import AboutLink from './AboutLink';
import './AboutInfo.css';

const aboutInfo = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();

    return (
        <>
            <div className='AboutInfo'>
                <div>
                    <b>{t('ABOUT_TECHNOLOGY_STACK')}</b> <br/>
                    <img
                        src={reactIcon} alt=''
                        style={img}/>
                    {t('ABOUT_REACT')}
                    <br/>
                    <img
                        src={micronautIcon} alt=''
                        style={img}/>
                    {t('ABOUT_MICRONAUT')}
                    <br/>
                    <img src={postgresqlIcon} alt=''
                         style={img}/>
                    {t('ABOUT_POSTGRESQL')}
                    <br/>
                </div>
                <div>
                    <b>{t('ABOUT_PRODUCER_OPERATOR')}</b> <br/>
                    <AboutLink href={t('ABOUT_AT_CRIS_LINK')} text={t('ABOUT_AT_CRIS')}/><br/>
                    <AboutLink href={t('ABOUT_SUPPORT_LINK')} text={t('ABOUT_SUPPORT')}/><br/>
                    <AboutLink href={t('ABOUT_DEVELOPMENT_LINK')} text={t('ABOUT_DEVELOPMENT')}/><br/>
                </div>
            </div>
        </>
    )
}

const img = {
    width: '15px'
}

export default aboutInfo;
