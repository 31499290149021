import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import '../../MenuBar.css';
import NavigationLink from '../../../components/Navigation/NavigationLink/NavigationLink';
import {loadCustomerProfile} from '../../../store/actions/CustomerInfoActions';
import {
    CUSTOMER_LOGOUT_ROUTE_PATH,
    CUSTOMER_MY_PROFILE_ROUTE_PATH,
    CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH,
    CUSTOMER_PRODUCER_LIST_ROUTE_PATH,
    CUSTOMER_REQUIREMENTS_ROUTE_PATH
} from '../../../routing/RoutingPaths';

class CustomerMenuBar extends Component {

    componentDidMount() {
        this.props.onLoadCustomerProfile(this.props.customerId);
    }

    render() {
        const {t} = this.props;
        return (
            <div className='MenuBar'>
                <ul className='NavigationBar'>
                    <NavigationLink
                        sublineOrange={this.props.customerProfile ? !this.props.customerProfile.readyForContact : null}
                        link={CUSTOMER_REQUIREMENTS_ROUTE_PATH}>{t('REQUIREMENTS_CATALOGUE_MENU')}</NavigationLink>
                    <NavigationLink
                        sublineOrange={this.props.customerProfile ? !this.props.customerProfile.readyForContact : null}
                        link={CUSTOMER_MY_REQUIREMENTS_ROUTE_PATH}>{t('MY_REQUIREMENTS_LIST_MENU')}</NavigationLink>
                    <NavigationLink
                        sublineOrange={this.props.customerProfile ? !this.props.customerProfile.readyForContact : null}
                        link={CUSTOMER_PRODUCER_LIST_ROUTE_PATH}>{t('PRODUCT_PROVIDER_MENU')}</NavigationLink>
                    <NavigationLink
                        sublineOrange={this.props.customerProfile ? !this.props.customerProfile.readyForContact : null}
                        link={CUSTOMER_MY_PROFILE_ROUTE_PATH}>{t('CUSTOMER_MY_PROFILE_MENU')}</NavigationLink>
                    <NavigationLink
                        sublineOrange={this.props.customerProfile ? !this.props.customerProfile.readyForContact : null}
                        link={CUSTOMER_LOGOUT_ROUTE_PATH}>{t('LOGOUT')}</NavigationLink>
                </ul>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        customerId: state.login.account.id,
        customerProfile: state.customerInfo.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomerProfile: (customerId) => dispatch(loadCustomerProfile(customerId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerMenuBar));
