import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import Layout from './hoc/Layout/Layout';
import Login from './containers/Login/Login';
import PasswordChange from './containers/Login/PasswordChange/PasswordChange'
import CustomerRouting from './routing/CustomerRouting';
import ProducerRouting from './routing/ProducerRouting';
import AdminRouting from './routing/AdminRouting';
import {
    CUSTOMER_MAIN_ROUTE_PATH,
    PRODUCER_MAIN_ROUTE_PATH,
    ADMINISTRATOR_MAIN_ROUTE_PATH,
    PASSWORD_CHANGE_ROUTE_PATH,
    LOGIN_ROUTE_PATH
} from './routing/RoutingPaths';
import {ACCOUNT, LANGUAGE_CODE} from './GlobalConstants';
import {authCheckState} from './store/actions/LoginAction';
import i18n from 'i18next';
import {initializeTranslation} from './i18n';


class App extends Component {

    state = {
        i18nInitialized: false
    }

    componentDidMount() {
        initializeTranslation().then(() => {
            i18n.changeLanguage(localStorage.getItem(LANGUAGE_CODE)).then(() =>
                this.setState({
                    i18Initialized: true
                })
            );
        });

        if (localStorage.getItem(ACCOUNT) && this.props.account == '') {
            this.props.onRefresh();
        }
    }

    render() {
        if (!this.state.i18Initialized) {
            return (
                <>
                    <div>Loading Languages</div>
                </>
            )
        }
        return (
            <>
                <Layout showMenuBar={this.props.showMenuBar}>
                    <Switch>
                        <Route path={CUSTOMER_MAIN_ROUTE_PATH} component={CustomerRouting}/>
                        <Route path={PRODUCER_MAIN_ROUTE_PATH} component={ProducerRouting}/>
                        <Route path={ADMINISTRATOR_MAIN_ROUTE_PATH} component={AdminRouting}/>
                        <Route path={PASSWORD_CHANGE_ROUTE_PATH} component={PasswordChange}/>
                        <Route path={LOGIN_ROUTE_PATH} component={Login}/>
                    </Switch>
                </Layout>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        showMenuBar: state.login.valid,
        role: state.login.account.userType,
        account: state.login.account
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () => dispatch(authCheckState())
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));
