import {saveAs} from 'file-saver';
import * as actionTypes from "../store/actions/actionTypes";

export function extractedAssetFrom(reader, assetName) {
    let contentArray = [];
    reader.read().then(function processText({done, value}) {
        if (done) {
            let blob = new Blob(contentArray, {type: 'application/octet-stream'});
            saveAs(blob, assetName);
            return;
        }
        contentArray.push(value)
        return reader.read().then(processText);
    });
}

export function showUploadErrorMessage(message){
    let text = message.message? message.message: 'ERROR_UPLOAD_DOCUMENT';
    return {
        type: actionTypes.SHOW_ERROR,
        error: true,
        text: text
    };
}
