import {
    LOAD_SUPPORTED_LANGUAGES_START,
    LOAD_SUPPORTED_LANGUAGES_SUCCESS,
    LOAD_SUPPORTED_LANGUAGES_FAIL
} from '../actions/actionTypes';

const initialState = {
    languages: [],
    loading: false,
    error: ''
}

const supportedLanguagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SUPPORTED_LANGUAGES_START:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUPPORTED_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.languages,
                loading: false
            }
        case LOAD_SUPPORTED_LANGUAGES_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state;
    }
}

export default supportedLanguagesReducer;
