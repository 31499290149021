import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import moment from 'moment';

import './AnswerBox.css';

import QuickAnswerBox from './QuickAnswerBox/QuickAnswerBox';
import ButtonAnswerRow from './ButtonAnswerRow/ButtonAnswerRow';

import * as requirementResponseAction from '../../store/actions/RequirementResponseAction';
import {deleteAsset, downloadAsset, uploadAsset} from '../../store/actions/AssetAction';
import {toggleResponse} from '../../store/actions/ToggleResponseAction';
import AssetHandler from '../UploadDocuments/AssetHandler';
import AngleRotationIcon from '../UI/AngleRotationIcon/AngleRotationIcon';
import * as messageAction from '../../store/actions/MessageAction';

/**
 * props:
 * editable: it is possible to enter information and save it
 */
class AnswerBox extends Component {

    state = {
        currentResponseComment: null
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.mouseDownHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.mouseDownHandler);
    }

    changedSystemAvailableHandler = (event) => {
        this.props.onAutoSaveRequirementResponse(event.target.value, this.configurable(), this.responseComment(), this.requirementResponseStatus(), this.requirementResponseId(), this.props.requirementId, this.props.account);
    }

    changedConfigurableHandler = (event) => {
        this.props.onAutoSaveRequirementResponse(this.systemAvailable(), event.target.value, this.responseComment(), this.requirementResponseStatus(), this.requirementResponseId(), this.props.requirementId, this.props.account);
    }

    changeResponseCommentHandler = (event) => {
        this.setState({currentResponseComment: event.target.value});
    }

    mouseDownHandler = () => {
        if (this.state.currentResponseComment !== null && this.state.currentResponseComment !== this.props.comment) {
            this.props.onAutoSaveRequirementResponse(this.systemAvailable(), this.configurable(), this.state.currentResponseComment, this.requirementResponseStatus(), this.requirementResponseId(), this.props.requirementId, this.props.account);
        }
    }

    responseComment() {
        return this.props.comment;
    }

    systemAvailable() {
        return this.props.availableRequirementResponse ? this.props.availableRequirementResponse.available : '';
    }

    configurable() {
        return this.props.availableRequirementResponse ? this.props.availableRequirementResponse.configurable : '';
    }

    requirementResponseId() {
        if (this.props.availableRequirementResponse === undefined || this.props.availableRequirementResponse.id === undefined) {
            return null;
        }
        return this.props.availableRequirementResponse.id
    }

    responseLastUpdatedDate(t) {
        if (this.props.availableRequirementResponse === undefined) {
            return t('RESPONSE_NO_DATE');
        }
        return this.props.availableRequirementResponse.lastUpdated ? t('RESPONSE_SAVE_DATE') + ' ' + moment((this.props.availableRequirementResponse.lastUpdated.toString())).format('YYYY-MM-DD') : t('RESPONSE_NO_DATE');
    }

    requirementResponseDocuments() {
        return this.props.assets ? this.props.assets : '';
    }

    requirementResponseStatus() {
        return this.props.availableRequirementResponse ? this.props.availableRequirementResponse.status : 'DRAFT';
    }

    render() {
        const {t} = this.props;
        const title = this.props.editable ? t('QUICK_ANSWER') : this.getProducerName();
        return (
            <div className='AnswerBox'>
                <div style={labelGab}>
                    <label style={dateGab}>
                        {this.props.editable ? '' : t('RESPONSE_OF_WHOM')}<b>{title}</b>&nbsp;{this.responseLastUpdatedDate(t)}
                    </label>
                    {this.props.editable ? this.saveButtonAnswerRow(t) : <div className='ButtonAnswerRow'/>}
                    {this.props.showAngle ?
                        <AngleRotationIcon
                            clicked={() => this.props.onToggleResponse(this.requirementResponseId())}
                            direction={this.props.showAnswer}/> : ''}
                </div>
                <QuickAnswerBox systemAvailable={this.systemAvailable()}
                                changedSystemAvailable={() => this.changedSystemAvailableHandler}
                                configurable={this.configurable()}
                                changedConfigurable={() => this.changedConfigurableHandler}
                                editable={this.props.editable}/>
                {this.props.showAnswer ?
                    <>
                        <div style={answerGab}>
                            <label><b>{t('REQUIREMENT_RESPONSE_TITLE')}</b></label>
                        </div>
                        <textarea
                            style={textAreaStyle}
                            placeholder={t('ANSWER_INPUT_PLACEHOLDER')}
                            rows={10}
                            value={this.state.currentResponseComment !== null ? this.state.currentResponseComment : this.props.comment}
                            onChange={this.changeResponseCommentHandler}
                            disabled={!this.props.editable}
                        />
                        <AssetHandler documents={this.requirementResponseDocuments()}
                                      ownerId={this.requirementResponseId()}
                                      editable={this.props.editable}
                                      documentTyp='RequirementResponse'
                                      uploadDocumentAction={(ownerId, documents) => this.props.onUploadAsset(ownerId, documents)}
                                      deleteDocument={(id, ownerId) => this.props.onDeleteAsset(id, ownerId)}
                                      downloadDocument={(id, assetName) => this.props.onDownloadAsset(id, assetName)}
                                      requirementId={this.props.requirementId}/>
                    </> : ''}
            </div>
        )
    }

    getProducerName() {
        if (this.props.availableRequirementResponse === undefined) {
            return '';
        }
        return this.props.availableRequirementResponse.producerName ? this.props.availableRequirementResponse.producerName : '';
    }

    saveButtonAnswerRow(t) {
        return <ButtonAnswerRow
            saveDraft={() => this.props.onSaveRequirementResponseAsDraft(this.systemAvailable(), this.configurable(), this.responseComment(), this.requirementResponseId(), this.props.requirementId, this.props.account)}
            savePublish={this.saveToPublishWithWarning(t)}/>;
    }

    saveToPublishWithWarning(t) {
        if (this.systemAvailable() || this.configurable() || this.responseComment() || this.requirementResponseDocuments()) {
            return () => this.props.onPublishSaveRequirementResponse(this.systemAvailable(), this.configurable(), this.responseComment(), this.requirementResponseId(), this.props.requirementId, this.props.account);
        }
        return () => this.props.onShowNothingSelectedMessage(t('RESPONSE_SAVE_MSG'));
    }
}

const textAreaStyle = {
    marginTop: '20px',
    marginRight: '5px',
    resize: 'none',
    fontFamily: 'sans-serif'
};

const answerGab = {
    paddingTop: '20px'
};

const labelGab = {
    marginTop: '7px',
    marginBottom: '10px',
    lineHeight: 1.2,
    flexDirection: 'row',
    display: 'flex'
};

const dateGab = {
    marginTop: '5px',
    verticalAlign: 'bottom',
    paddingRight: '10px'
};

const mapStateToProps = state => {
    return {
        account: state.login.account,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveRequirementResponseAsDraft: (systemAvailable, configurable, responseComment, responseId, requirementId, account) => dispatch(requirementResponseAction.saveRequirementResponse(systemAvailable, configurable, responseComment, 'DRAFT', responseId, requirementId, account)),
        onPublishSaveRequirementResponse: (systemAvailable, configurable, responseComment, responseId, requirementId, account) => dispatch(requirementResponseAction.saveRequirementResponse(systemAvailable, configurable, responseComment, 'PUBLISHED', responseId, requirementId, account)),
        onAutoSaveRequirementResponse: (systemAvailable, configurable, responseComment, status, responseId, requirementId, account) => dispatch(requirementResponseAction.saveRequirementResponse(systemAvailable, configurable, responseComment, status, responseId, requirementId, account)),
        onUploadAsset: (ownerId, documents) => dispatch(uploadAsset(ownerId, documents)),
        onDeleteAsset: (id, ownerId) => dispatch(deleteAsset(id, ownerId)),
        onDownloadAsset: (id, assetName) => dispatch(downloadAsset(id, assetName)),
        onToggleResponse: (responseId) => dispatch(toggleResponse(responseId)),
        onShowNothingSelectedMessage: (text) => dispatch(messageAction.showInfo(true, text))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AnswerBox));
