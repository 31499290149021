import {
    LOAD_PRODUCT_AREAS_SUCCESS,
    LOAD_PRODUCT_AREAS_START,
    LOAD_PRODUCT_AREAS_FAIL
} from '../actions/actionTypes';

const initialState = {
    productAreas: [],
    loading: false,
}

const productAreaReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRODUCT_AREAS_START:
            return {
                ...state,
                loading: true
            };
        case LOAD_PRODUCT_AREAS_SUCCESS:
            return {
                ...state,
                productAreas: action.productAreas
            }
        case LOAD_PRODUCT_AREAS_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

export default productAreaReducer;
