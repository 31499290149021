import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {toggleRequirements} from '../../store/actions/ToggleRequirementAction';
import {toggleCategories} from '../../store/actions/ToggleCategoriesAction';
import {toggleResponses} from '../../store/actions/ToggleResponseAction'
import ExcelExport from '../ExcelExport/ExcelExport';
import Button from '../UI/Button/Button';
import plusCircle from '../../assets/images/plus-circle-solid.svg';
import './RequirementToolbar.css';
import DropDownMenu from '../UI/DropDownMenu/DropDownMenu';

/**
 *
 * @param props
 * saveToMyRequirements='onClick event for save to my requirement button'
 * excelExportRequirements='collection of data for requirement excel export'
 * clicked='onClick event for new requirement button'
 * toggleCategories='onClick event to open or close categories'
 * showRequirements='boolean for button title'
 * @returns {*}
 */
class RequirementToolbar extends Component {

    toggleCategoriesMenuTitle() {
        if (this.props.toggleAllCategories) {
            return 'CLOSE_CATEGORIES';
        }
        return 'OPEN_CATEGORIES';
    }


    toggleRequirementsMenuTitle() {
        if (this.props.toggleAllRequirements) {
            return 'CLOSE_REQUIREMENTS'
        }
        return 'OPEN_REQUIREMENTS';
    }

    render() {
        const {t} = this.props;
        return (
            <div className='ButtonRow'>
                <div className='LeftButtons'>
                    <ExcelExport/>
                    {this.props.saveToFavoritesButtonTitle ? <Button title={this.props.saveToFavoritesButtonTitle} clicked={this.props.saveToMyRequirements}/> : ''}
                    <DropDownMenu dropDownTitle={t('TOGGLE_ALL')}
                                  menuItemContainer={[{
                                      title: t(this.toggleCategoriesMenuTitle()),
                                      action: () => this.toggleAllCategories()
                                  },
                                      {
                                          title: t(this.toggleRequirementsMenuTitle()),
                                          action: () => this.toggleAllRequirementsWithResponses()
                                      }]}/>
                </div>
                <div>
                    {this.props.clicked !== undefined ? <Button title={t('NEW_REQUIREMENT_BUTTON')} color='Blue'
                                                                clicked={this.props.clicked}
                                                                url={plusCircle}/> : ''}
                </div>
            </div>
        )
    };

    toggleAllCategories() {
        if (this.props.toggleAllRequirements) {
            this.props.onToggleAllRequirements(this.props.toggleAllRequirements);
        }
        return this.props.onToggleAllCategories(this.props.toggleAllCategories);
    }

    toggleAllRequirementsWithResponses() {
        if (!this.props.toggleAllCategories) {
            this.props.onToggleAllCategories(this.props.toggleAllCategories);
        }
        this.props.onToggleAllRequirements(this.props.toggleAllRequirements);
        if (this.props.account.userType === 'CUSTOMER') {
            this.props.onToggleAllResponses(this.props.toggleAllRequirements, this.props.listForActivity);
        }
    }
}

const mapStateToProps = state => {
    return {
        toggleAllCategories: state.toggleCategories.toggleAllCategories,
        toggleAllRequirements: state.toggleRequirement.toggleAllRequirementResponses,
        toggleAllResponses: state.toggleResponse.toggleAllResponses,
        listForActivity: state.toggleResponse.toggleResponsesList,
        account: state.login.account
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleAllCategories: (toggle) => dispatch(toggleCategories(toggle)),
        onToggleAllRequirements: (toggleBoolean) => dispatch(toggleRequirements(toggleBoolean)),
        onToggleAllResponses: (toggleBoolean, listForActivity) => dispatch(toggleResponses(toggleBoolean, listForActivity))
    }
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequirementToolbar));
