import React from 'react';
import './Error.css';

const error = (props) => (
    <div className='Error'>
        <span className='Smiley' role='img'>&#128546;</span>
        <div>{props.msg}</div>
    </div>

);

export default error;
