import React, {Component} from 'react';
import {connect} from 'react-redux';

import '../../../UI/Checkbox/Checkbox.css';

/**
 *
 * @param props
 * shadowBox= to show the checkbox in a shadow box please insert 'ShadowBox'. It is a css class which shows a shadow box.
 * id='name of the input element'. Is the id from the requirement
 * clicked={function }
 * checked=boolean is checkbox checked
 * label='text which is shown in the checkbox'
 * editable: is answerBox editable
 *
 * @returns {*}
 */
class RequirementCheckbox extends Component {



    label() {
        if (this.props.editable) {
            return <b> {this.props.label}</b>;
        }
        return this.props.label
    }

    render() {
        return (
            <div className={this.props.shadowBox}>
                <input name={this.props.id} type="checkbox" className='Checkbox' onClick={this.props.clicked}
                       checked={this.props.checked}/>
                {this.label()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        toggleResponseList: state.toggleResponse.toggleResponsesList
    }
};

export default connect(mapStateToProps, null)(RequirementCheckbox);
