import React, {Component} from 'react';
import './Message.css';
import {withTranslation} from 'react-i18next';
import * as actionTypes from '../../store/actions/actionTypes';

class message extends Component {

    getBannerClass(type) {
        switch (type) {
            case actionTypes.MESSAGE_ACCEPTED:
            case actionTypes.SHOW_ERROR:
            case actionTypes.SHOW_INFO:
            default:
                return 'Banner';
        }
    }

    getMessageClass(type) {
        switch (type) {
            case actionTypes.MESSAGE_ACCEPTED:
            case actionTypes.SHOW_ERROR:
            case actionTypes.SHOW_INFO:
            default:
                return 'Message';
        }
    }

    getButtonClass(type) {
        switch (type) {
            case actionTypes.MESSAGE_ACCEPTED:
            case actionTypes.SHOW_ERROR:
            case actionTypes.SHOW_INFO:
            default:
                return 'MessageButton';
        }
    }

    render() {
        const {t} = this.props;
        if (this.props.type === actionTypes.MESSAGE_ACCEPTED) {
            //do not show yourself even if somebody else tries to force you
            return <div> {this.props.type}</div>;
        }
        return (
            <div className='MessageOverlay'>
                <div className={this.getBannerClass(this.props.type)}>
                    <div className={this.getMessageClass(this.props.type)}>{t(this.props.text)}</div>
                    <button className={this.getButtonClass(this.props.type)}
                            onClick={this.props.hide}>{this.props.buttonTitle ? this.props.buttonTitle : t('BUTTON_OK')}</button>
                </div>
            </div>
        )
    };
}

export default withTranslation()(message);
