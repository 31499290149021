export function convertRequirementResponses(httpResponse) {
    return {
        id: httpResponse.id,
        requirementId: httpResponse.requirementId,
        status: httpResponse.status,
        producerId: httpResponse.producerId,
        producerName: httpResponse.producerName,
        lastUpdated: httpResponse.lastUpdated,
        languageCode: httpResponse.languageCode,
        comment: httpResponse.responseComment ? httpResponse.responseComment : '',
        available: httpResponse.available,
        configurable: httpResponse.configurable,
        productArea: httpResponse.productArea,
        documents: httpResponse.documents ? httpResponse.documents : ''
    };
}
