import React from 'react';
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import Modal from 'react-modal';
import cross from '../../../../../assets/images/times-solid.svg';
import './ContactPerson.css';

import '../../../../../components/Modal/AllModal.css';
Modal.setAppElement('#root');
const ContactPersonDetails = ({ isOpen, contactPerson, close }) => {
    const { t, i18n } = useTranslation();
    const countries = useSelector( state=> state.countries );
    return(
        <div>
            <Modal id="contact-person-modal" className="DefaultModal"  isOpen={isOpen} onRequestClose={close}>
                <div className="ModalLabel">
                        {t('PRODUCT_CONTACT_PERSON_MODAL_HEADER')}
                    <img src={cross} onClick={close} />
                </div>
                <div id="contact-person-container">
                    <div className="contact-person-field">
                        <div className="contact-person-field-title">
                            {t('PRODUCT_CONTACT_PERSON_NAME')}:
                        </div>
                        <div className="contact-person-field-meaning" >
                            <input type="text" value={contactPerson ? contactPerson.name : ''} disabled />
                        </div>
                    </div>
                    <div className="contact-person-field" >
                        <div className="contact-person-field-title" >
                            {t('PRODUCT_CONTACT_PERSON_EMAIL')}:
                        </div>
                        <div className="contact-person-field-meaning" >
                            <input type="text" value={contactPerson ? contactPerson.email : ''} disabled />
                        </div>
                    </div>
                    <div className="contact-person-field" >
                        <div className="contact-person-field-title" >
                            {t('PRODUCT_CONTACT_PERSON_COUNTRY')}:
                        </div>
                        <div className="contact-person-field-meaning" >
                        <select  disabled={true} value = { contactPerson? contactPerson.countryId : "" }>
                            {countries?
                                countries.map(country=>(
                                  <option key={country.id} value = {country.id}>
                                    {
                                      i18n.exists(country.name) ? (
                                        t(country.name)
                                      ): country.name
                                    }
                                  </option>
                                )): null
                            }
                        </select>
                        </div>
                    </div>
                    <div className="contact-person-field" >
                        <div className="contact-person-field-title" >
                            {t('PRODUCT_CONTACT_PERSON_PHONE_NUMBER')}:
                        </div>
                        <div className="contact-person-field-meaning" >
                            <input  type="text" value={contactPerson ? contactPerson.telNo : ''}  disabled />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default ContactPersonDetails;
