/*
id can be number or string
copylist has data structure
[
 {id: '3', toggle: false},
 {id: '5', toggle: false},
 {id: '6', toggle: false},
 {id: '7', toggle: false}
]
 */
export function changeToggleInformationFor(id, copyList) {
    if (id === undefined || id === null || copyList === undefined || copyList === null) {
        throw new Error('Please insert values for function parameter and not undefined or null!');
    }
    let toggleInfo = copyList.find(toggle => id.toString() === toggle.id.toString());
    if (toggleInfo === undefined) {
        toggleInfo = {
            'id': id,
            'toggle': false
        }
        copyList.push(toggleInfo);
        return copyList;
    }
    toggleInfo.toggle = !toggleInfo.toggle;
    return copyList;
}

/*
change all toggle information
* */
export function setToggleAll(copyList, toggleAll) {
    const filter = copyList.filter(toggle => toggle.toggle === toggleAll);
    return filter.length === 0;
}

/*filter undefined ids out of list and create a toggleInformationList*/
export function createToggleInformationList(ids) {
    let toggleInformation = [];
    if (ids !== undefined) {
        const filterUndefinedIds = ids.filter(id => id !== undefined);
        const filterEmptyIds = filterUndefinedIds.filter(id => id !== '');
        toggleInformation = filterEmptyIds.map(id => ({id: id, toggle: false}));
    }
    return toggleInformation;
}

/*
toggle attribute from toggleInformationList will set to for every object to true or false
* */
export function toggleAllInList(toggleAll, toggleList) {
    toggleList.map(toggle => toggle.toggle = toggleAll);
    return toggleList;
}

