import * as actionTypes from '../actions/actionTypes';
import moment from 'moment';


let selectedRequirements = [];

export function deselect(requirement, translationMap) {
    return (dispatch) => {
        dispatch(deselectRequirementStart());
        try {
            if (isRequirementSelected(requirement)) {
                removeDeselectedRequirementFromList(requirement);
            }
            dispatch(deselectRequirementSuccess(translationMap));
        } catch (error) {
            dispatch(deselectRequirementFail(error));
        }
    }
}

function deselectRequirementStart() {
    return {
        type: actionTypes.DESELECTED_REQUIREMENT_START
    }
}

export function deselectRequirementSuccess(translationMap) {
    const excelExport = selectedRequirements.map(requirement => [{value: requirement.categoryName}, {value: requirement.label}]);
    const requirementIds = selectedRequirements.map(requirement => requirement.id);
    const responseIds = createSelectedResponses(selectedRequirements);
    const excelExportRequirementsWithResponses = createExcelExportRequirementsWithResponsesArray(selectedRequirements, translationMap);

    return {
        type: actionTypes.DESELECTED_REQUIREMENT_SUCCESS,
        excelExportRequirements: [...excelExport],
        requirementIds: [...requirementIds],
        responseIds: [...responseIds],
        excelExportRequirementsWithResponses: excelExportRequirementsWithResponses
    }
}

function deselectRequirementFail(error) {
    return {
        type: actionTypes.DESELECTED_REQUIREMENT_FAIL,
        error: error
    }
}


/*requirementCollection variable is a JavaScript array with JavaScript objects which looks like:
* Object { name: IdFromRequirement, label: "Text from the requirement", categoryName: "Name of the category", status: "StatusOfTheRequirement" }*/
export function selectAllRequirement(requirementCollection, allSelected, translationMap) {
    return (dispatch) => {
        dispatch(selectRequirementStart());
        dispatch(clearRequirementSelection());
        try {
            if (!allSelected) {
                collectAllRequirements(requirementCollection);
            }
            dispatch(selectRequirementSuccess(!allSelected, translationMap));
        } catch (error) {
            dispatch(selectRequirementFail(error))
        }
    };
}

export function collectAllRequirements(requirementCollection) {
    requirementCollection.map(requirement => collectRequirement(requirement));
}


/*requirement variable is a JavaScript object which looks like:
* Object { name: IdFromRequirement, label: "Text from the requirement", categoryName: "Name of the category", status: "StatusOfTheRequirement" }*/
export function selectRequirement(requirement, translationMap) {
    return (dispatch) => {
        dispatch(selectRequirementStart());
        try {
            collectRequirement(requirement);
            dispatch(selectRequirementSuccess(false, translationMap));
        } catch (error) {
            dispatch(selectRequirementFail(error))
        }
    };
}

function selectRequirementStart() {
    return {
        type: actionTypes.SELECTED_REQUIREMENT_START
    }
}

function createExcelExportRequirementsWithResponsesArray(filteredRequirements, translationMap) {
    if (translationMap === undefined) {
        return [];
    }
    return filteredRequirements.flatMap(requirement => requirement.responses && requirement.responses.length > 0 ? flatResponses(requirement.categoryName, requirement.label, requirement.responses, translationMap) : [[{value: requirement.categoryName}, {value: requirement.label}]]);
}

function createSelectedResponses(filteredRequirements) {
    const responseMap = filteredRequirements.flatMap(requirement => requirement.responses && requirement.responses.length > 0 ? requirement.responses : []);
    return responseMap.map(response => response.id);
}

export function selectRequirementSuccess(allSelected, translationMap) {
    const excelExport = selectedRequirements.map(requirement => [{value: requirement.categoryName}, {value: requirement.label}]);
    const requirementIds = selectedRequirements.map(requirement => requirement.id);
    const responseIds = createSelectedResponses(selectedRequirements);
    const excelExportRequirementsWithResponses = createExcelExportRequirementsWithResponsesArray(selectedRequirements, translationMap);

    return {
        type: actionTypes.SELECTED_REQUIREMENT_SUCCESS,
        excelExportRequirements: [...excelExport],
        requirementIds: [...requirementIds],
        responseIds: [...responseIds],
        allRequirementSelected: allSelected,
        excelExportRequirementsWithResponses: excelExportRequirementsWithResponses
    }
}

function flatResponses(categoryName, requirementLabel, responses, translationMap) {
    return responses.map(response => [{value: categoryName}, {value: requirementLabel}, {value: response.producerName}, {value: translationMap.get(response.available)}, {value: translationMap.get(response.configurable)}, {value: response.comment}, {value: response.documents ? translationMap.get('YES') : translationMap.get('NO')}, {value: moment((response.lastUpdated.toString())).format('YYYY-MM-DD')}]);
}


function selectRequirementFail(error) {
    return {
        type: actionTypes.SELECTED_REQUIREMENT_FAIL,
        error: error
    }
}

/*selectedRequirement must have a field categoryName and a field label. Otherwise the function is not working*/

export function collectRequirement(selectedRequirement) {
    if (selectedRequirement === null || selectedRequirement === undefined) {
        return;
    }
    if (isRequirementSelected(selectedRequirement)) {
        removeDeselectedRequirementFromList(selectedRequirement);
        return;
    }
    insertRequirementToList(selectedRequirement);
}

function insertRequirementToList(selectedRequirement) {
    selectedRequirements.push(selectedRequirement);
}

function removeDeselectedRequirementFromList(selectedRequirement) {
    const indexOf = selectedRequirements.indexOf(selectedRequirement);
    selectedRequirements.splice(indexOf, 1);
}

function isRequirementSelected(selectedRequirement) {
    return selectedRequirements.includes(selectedRequirement);
}

export function clearRequirementSelection() {
    selectedRequirements = [];
    return {
        type: actionTypes.SELECTED_REQUIREMENT_CLEAR
    }
}

function filterSelectedRequirementsStart() {
    return {
        type: actionTypes.FILTER_SELECTED_REQUIREMENTS_START
    };
}

function filterSelectedRequirementsSuccess(excelExport, requirementIds, excelExportRequirementsWithResponses) {
    return {
        type: actionTypes.FILTER_SELECTED_REQUIREMENTS_SUCCESS,
        excelExportRequirements: excelExport,
        requirementIds: requirementIds,
        responseIds: createSelectedResponses(selectedRequirements),
        excelExportRequirementsWithResponses: excelExportRequirementsWithResponses
    };
}

function filterSelectedRequirementsFail() {
    return {
        type: actionTypes.FILTER_SELECTED_REQUIREMENTS_FAIL
    };
}

function assignFilteredRequirements(newSelectedRequirements, selectedRequirementIds, allRequirementSelected) {
    if (allRequirementSelected) {
        return newSelectedRequirements
    }
    return newSelectedRequirements.filter(requirement => selectedRequirementIds.includes(requirement.id));
}

export function filterSelectedRequirements(selectedRequirementIds, filteredRequirements, translationMap, allRequirementSelected) {
    return (dispatch) => {
        dispatch(filterSelectedRequirementsStart());
        try {
            if (!filteredRequirements || (Object.keys(filteredRequirements).length === 0 && filteredRequirements.constructor === Object)) {
                dispatch(filterSelectedRequirementsFail());
                return;
            }
            if (!selectedRequirementIds) {
                dispatch(filterSelectedRequirementsSuccess([], [], []));
                return;
            }
            let newSelectedRequirements = [];
            for (const key in filteredRequirements) {
                const element = filteredRequirements[key];
                newSelectedRequirements = newSelectedRequirements.concat(...element)
            }
            selectedRequirements = assignFilteredRequirements(newSelectedRequirements, selectedRequirementIds, allRequirementSelected);
            const excelExport = selectedRequirements.map(requirement => [{value: requirement.categoryName}, {value: requirement.label}]);
            const requirementIds = selectedRequirements.map(requirement => requirement.id);
            const excelExportRequirementsWithResponses = createExcelExportRequirementsWithResponsesArray(selectedRequirements, translationMap);
            dispatch(filterSelectedRequirementsSuccess(excelExport, requirementIds, excelExportRequirementsWithResponses));
        } catch (error) {
            dispatch(filterSelectedRequirementsFail(error))
        }
    };
}
