import React from 'react';
import {useTranslation} from 'react-i18next';

import './RequirementDropDownForm.css';
import DropDown from '../../UI/DropDown/DropDown';

/**
 * selectedProductArea,
 * productAreaValues,
 * productAreaChange,
 * selectedCategory,
 * categoriesValue,
 * categoriesChange,
 * selectedStatus,
 * statusValues,
 * statusChange
 * @param props
 */
const requirementDropDownForm = (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();

    return (
        <div className='RequirementDropDownForm'>
            <label>{t('PRODUCT_AREA_NAME')}</label>
            <DropDown selectedValue={props.selectedProductArea}
                      values={props.productAreaValues}
                      handleChange={props.productAreaChange}/>
            <label>{t('CATEGORY_NAME')}</label>
            <DropDown selectedValue={props.selectedCategory}
                      values={props.categoriesValue}
                      handleChange={props.categoriesChange}/>
            <label>{t('STATUS_NAME')}</label>
            <DropDown selectedValue={props.selectedStatus}
                      values={props.statusValues}
                      handleChange={props.statusChange}/>
        </div>
    )
}

export default requirementDropDownForm;
