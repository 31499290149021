import {
    LOAD_SUPPORTED_LANGUAGES_START,
    LOAD_SUPPORTED_LANGUAGES_SUCCESS,
    LOAD_SUPPORTED_LANGUAGES_FAIL
} from './actionTypes';
import {SERVER_URL} from '../../config';

export function supportedLanguages() {
    return async (dispatch) => {
        dispatch(loadSupportedLanguagesStart());
        try {
            const response = await loadSupportedLanguages();
            dispatch(loadSupportedLanguagesSuccess(await response.json()));
        } catch (error) {
            dispatch(loadSupportedLanguagesFail(error));
        }
    }
}

function loadSupportedLanguagesStart() {
    return {
        type: LOAD_SUPPORTED_LANGUAGES_START
    }
}

function loadSupportedLanguagesSuccess(languages) {
    return {
        type: LOAD_SUPPORTED_LANGUAGES_SUCCESS,
        languages: languages
    }
}

function loadSupportedLanguagesFail(error) {
    return {
        type: LOAD_SUPPORTED_LANGUAGES_FAIL,
        error: error
    }
}

async function loadSupportedLanguages() {
    return await fetch(SERVER_URL + '/supportedlanguages', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}
