import * as actionTypes from '../actions/actionTypes';

const initialState = {
    allRequirementResponses: [],
    loading: false,
    error: ''
};

const requirementResponseReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_START:
        case actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_SUCCESS:
        case actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_SUCCESS:
            return {
                ...state,
                loading: false,
                allRequirementResponses: action.requirementResponseList
            }
        case actionTypes.LOAD_PRODUCER_REQUIREMENT_RESPONSES_FAIL:
        case actionTypes.LOAD_REQUIREMENT_RESPONSE_ASSET_FAIL:
        case actionTypes.DELETE_REQUIREMENT_RESPONSE_ASSET_FAIL:
        case actionTypes.SAVE_REQUIREMENT_RESPONSE_ASSET_FAIL:
        case actionTypes.LOAD_ALL_REQUIREMENT_RESPONSES_FOR_PRODUCT_AREA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.SAVE_REQUIREMENT_RESPONSE_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SAVE_REQUIREMENT_RESPONSE_SUCCESS:
            return {
                ...state,
                loading: false,
                allRequirementResponses: submitRequirementResponse(action, state)
            }
        case actionTypes.SAVE_REQUIREMENT_RESPONSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.HTTP_STATUS_UNAUTHORIZED:
            return {
                allRequirementResponses: [],
                loading: false,
                error: ''
            }
        case actionTypes.SAVE_REQUIREMENT_RESPONSE_ASSET_SUCCESS:
            return {
                ...state,
                allRequirementResponses: addAssetToRequirementResponse(action.document, [...state.allRequirementResponses]),
                loading: false,
                error: ''
            }
        case actionTypes.DELETE_REQUIREMENT_RESPONSE_ASSET_SUCCESS:
            return {
                ...state,
                allRequirementResponses: removeAssetFromRequirementResponse(action.assetId, action.responseId, [...state.allRequirementResponses]),
                loading: false,
                error: ''
            }
        default:
            return state;
    }
}

export function removeAssetFromRequirementResponse(assetId, responseId, copyAllRequirementResponses) {
    if (assetId === null || responseId === null) {
        throw new Error('Ids are null!');
    }
    const response = copyAllRequirementResponses.find(response => responseId === response.id);
    if (response === undefined) {
        throw new Error('Response with id:' + responseId + ' does not exist!');
    }
    const copyDocuments = [...response.documents];
    const isRemovedAsset = (asset) => asset.id === assetId;
    copyDocuments.splice(copyDocuments.findIndex(isRemovedAsset), 1);
    response.documents = copyDocuments;
    const indexOf = copyAllRequirementResponses.indexOf(response);
    copyAllRequirementResponses[indexOf] = response;
    return copyAllRequirementResponses;
}

export function addAssetToRequirementResponse(document, copyAllRequirementResponses) {
    if (document === null) {
        throw new Error('Asset is null!');
    }
    const response = copyAllRequirementResponses.find(response => document.ownerId === response.id);
    if (response === undefined) {
        throw new Error('Response with id:' + document.ownerId + ' does not exist!');
    }
    const copyDocuments = [...response.documents];
    copyDocuments.push(document);
    response.documents = copyDocuments;
    copyAllRequirementResponses[copyAllRequirementResponses.indexOf(response)] = response;
    return copyAllRequirementResponses;
}

function submitRequirementResponse(action, state) {
    const copiedRequirementResponses = [...state.allRequirementResponses];
    const submittedResponse = action.requirementResponse;
    const isUpdatedResponse = copiedRequirementResponses.find((response) => response.id === submittedResponse.id);
    if (isUpdatedResponse !== undefined) {
        copiedRequirementResponses[copiedRequirementResponses.indexOf(isUpdatedResponse)] = submittedResponse;
        return copiedRequirementResponses;
    }
    copiedRequirementResponses.push(submittedResponse);
    return copiedRequirementResponses;
}

export default requirementResponseReducer;
