import React from 'react';

import './Button.css';

/**
 *
 * @param props
 * * title='title of the button'
 * * color='default color of button is green. Other options are: color='Blue', color='DarkBlue', color='Orange'
 * * clicked={function which the button should call}
 * * url='path where the image is shown'
 * @returns {*}
 */
const button = (props) => (
    <button className={['Button', props.color].join(' ')} onClick={props.clicked}>
        <div>{props.url ? <img src={props.url} alt=''/>: ''}</div>
        <div>
            {props.title}
        </div>
    </button>
);

export default button;
