import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import './ErrorPopup.css';
import {ReactComponent as Exclamation} from '../../../assets/images/exclamation-triangle-solid.svg';

const styles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0)',

    },
    content: {
        height: "110px",
        width: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop:"200px",
        padding: "0",
        border: "none",
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottopRightRadius: 5,
        borderBottomLeftRadius: 5,
        outline: 'none'
    }
};
Modal.setAppElement('#root');
const ErrorPopup = ({errorState, close}) => {
    const {t} = useTranslation();
    return(
        <div>
            <Modal style ={
                {
                    overlay: styles.overlay,
                    content: styles.content
                }
            } isOpen={errorState.isOpen} onRequestClose={close} >
                <div className="error-header">
                    <Exclamation className="exclamation-mark"/>
                    {t('ERROR_UPLOAD_DOCUMENT')}
                </div>
                <div className="error-message">
                    {errorState.message}
                </div>
            </Modal>
        </div>
    )
}
export default ErrorPopup;
