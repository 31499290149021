import * as actionTypes from '../actions/actionTypes';

const initialState = {
    excelExportRequirements: [],
    selectedRequirementIds: [],
    selectedResponseIds: [],
    allRequirementSelected: false,
    error: '',
    excelExportRequirementsWithResponses: []
};

const requirementSelectionReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.DESELECTED_REQUIREMENT_START:
        case actionTypes.SELECTED_REQUIREMENT_START:
            return {
                ...state
            };
        case actionTypes.FILTER_SELECTED_REQUIREMENTS_SUCCESS:
        case actionTypes.DESELECTED_REQUIREMENT_SUCCESS:
            return {
                ...state,
                excelExportRequirements: action.excelExportRequirements,
                selectedRequirementIds: action.requirementIds,
                selectedResponseIds: action.responseIds,
                excelExportRequirementsWithResponses: action.excelExportRequirementsWithResponses
            }
        case actionTypes.SELECTED_REQUIREMENT_SUCCESS:
            return {
                ...state,
                excelExportRequirements: action.excelExportRequirements,
                selectedRequirementIds: action.requirementIds,
                selectedResponseIds: action.responseIds,
                allRequirementSelected: action.allRequirementSelected,
                excelExportRequirementsWithResponses: action.excelExportRequirementsWithResponses
            };
        case actionTypes.DESELECTED_REQUIREMENT_FAIL:
        case actionTypes.SELECTED_REQUIREMENT_FAIL:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.FILTER_SECTION_CHANGED:
        case actionTypes.SELECTED_REQUIREMENT_CLEAR:
            return {
                ...state,
                ...initialState
            }
        case actionTypes.CREATE_REQUIREMENT_SUCCESS:
            return {
                ...state,
                allRequirementSelected: false
            }
        default:
            return state;
    }
};

export default requirementSelectionReducer;
