import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';
import {showUploadErrorMessage, extractedAssetFrom} from '../../utility/AssetHandler';
import {convertRequirementResponses} from '../../utility/RequirementResponseHandler';
import {saveRequirementResponseSuccess} from './RequirementResponseAction';

/**Reducer for this action is RequirementResponseReducer**/

function downloadAssetFail(error) {
    return {
        type: actionTypes.LOAD_REQUIREMENT_RESPONSE_ASSET_FAIL,
        error: error
    };
}

async function downloadAssetFor(id) {
    return await fetch(SERVER_URL + '/assets/download?assetId=' + id + '&assetType=RequirementResponse', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
        },
        credentials: 'include'
    })
}

export const downloadAsset = (id, assetName) => {
    return async (dispatch) => {
        try {
            const response = await downloadAssetFor(id);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            extractedAssetFrom(response.body.getReader(), assetName);
        } catch (error) {
            dispatch(downloadAssetFail(error))
        }
    }
}

function deleteAssetSuccess(id, ownerId) {
    return {
        type: actionTypes.DELETE_REQUIREMENT_RESPONSE_ASSET_SUCCESS,
        assetId: id,
        responseId: ownerId
    };
}

function deleteAssetFail(error) {
    return {
        type: actionTypes.DELETE_REQUIREMENT_RESPONSE_ASSET_FAIL,
        error: error
    };
}

async function deleteAssetFor(id, ownerId) {
    return await fetch(SERVER_URL + '/assets/delete?assetId=' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            ownerId: ownerId,
            assetType: 'RequirementResponse'
        })
    })
}

export const deleteAsset = (id, ownerId) => {
    return async (dispatch) => {
        try {
            const response = await deleteAssetFor(id, ownerId);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            dispatch(deleteAssetSuccess(id, ownerId))
        } catch (error) {
            dispatch(deleteAssetFail(error))
        }
    }
}


export const uploadAsset = (ownerId, documents) => {
    return async (dispatch) => {
        try {
            const response = await upload(documents);
            if (response.status === 401) {
                dispatch(redirectUnauthorized());
                return;
            }
            if (response.status === 400) {
                dispatch(uploadAssetFail(await response.json()));
                dispatch(showUploadErrorMessage({message: 'ERROR_UPLOAD_DOCUMENT_TYPES'}));
                return;
            }
            if (response.status === 201) {
                dispatch(saveRequirementResponseSuccess(convertRequirementResponses(await response.json())));
                return;
            }
            const doc = await response.json();
            dispatch(saveRequirementResponseSuccess(convertRequirementResponses(doc)));
        } catch (error) {
            dispatch(uploadAssetFail(error));
            dispatch(showUploadErrorMessage({message: 'ERROR_UPLOAD_DOCUMENT'}));
        }
    }
}

async function upload(data) {
    return await fetch(SERVER_URL + '/assets/upload', {
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
        },
        credentials: 'include',
        body: data
    })
}

function uploadAssetFail(error) {
    return {
        type: actionTypes.SAVE_REQUIREMENT_RESPONSE_ASSET_FAIL,
        error: error
    };
}

function redirectUnauthorized() {
    return {
        type: actionTypes.HTTP_STATUS_UNAUTHORIZED
    };
}

