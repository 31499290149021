import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Filter.css';

//This component needs to be controlled by father component.
const FilterDateSection = (props) => {

    const {t} = useTranslation();
    return (
        <>
            <div className="default-filter">
                { props.isSubsection && props.title ? <div className = "default-filter-title" >{t(props.title)}
                </div>: <div className = "default-filter-title" />}

                {props.filterList ? props.filterList.sort((a, b) => a.id - b.id).map(filterEntry => (
                    <div key={filterEntry.id} className="row-filter-item">
                        <label  htmlFor={filterEntry.id}>{t(filterEntry.name)}</label>
                        <input type="date" name={filterEntry.name} id={filterEntry.id}
                               onChange={( event) => props.handleFilter(props.title, event)}
                                checked={(filterEntry.value ? filterEntry!= "": false)} />
                    </div>)) : ''
                }
            </div>
        </>
    )
};

export default FilterDateSection;