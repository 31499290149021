import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';

export function hide(){
    return async (dispatch) => {
        dispatch(updateMessageAccepted());
    }
}

export function showError(error, text) {
    return async (dispatch) => {
    dispatch(updateShowError(error, text));
    }
}

export function showInfo(info, text) {
    return async (dispatch) => {
    dispatch(updateShowInfo(info, text));
    }
}

function updateShowError(error, text) {
    return {
        type: actionTypes.SHOW_ERROR,
        text: text,
        error: error
    }
}

function updateShowInfo(info, text) {
    return {
        type: actionTypes.SHOW_INFO,
        text: text,
        info: info
    }
}

function updateMessageAccepted() {
    return {
        type: actionTypes.MESSAGE_ACCEPTED,
        text: ''
    }
}