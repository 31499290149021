import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Link} from 'react-router-dom';
import './ProducerList.css';
import {loadProducerList, resetList} from '../../../store/actions/CustomerInfoActions';
import {loadCountries} from '../../../store/actions/CountriesAction';
import ProducerDocument from './ListComponents/Document/ProducerDocument';
import ContactPersonDetails from './ListComponents/ContactPerson/ContactPerson';
import DetailsModal from '../../../components/Modal/DetailsModal/DetailsModal';
import * as UxHelper from '../../../utility/UxHelper';
import Filter from "../../../components/Filter/Filter";
import FilterSection from "../../../components/Filter/FilterSection";


const styles = {
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
}

function trimCompanyUrl(companyUrl) {
    return companyUrl ? companyUrl.trim() : '';
}

const ProducerList = () => {
    const {t} = useTranslation();
    const producerList = useSelector(state => state.customerInfo.producerList);
    const countries = useSelector(state => state.countries);
    const dispatch = useDispatch();

    const [filterList, setFilterList] = useState(undefined);

    const [documentHeight, setDocumentHeight] = useState(undefined);
    //contact person modal states
    const [personDetailsIsOpen, setPersonDetailsIsOpen] = useState(false);
    const [personDetails, setPersonDetails] = useState(null);
    //details modal states
    const [detailsIsOpen, setDetailsIsOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [allChecked, setAllChecked] = useState(null);

    useEffect(() => {
        UxHelper.topScroll();
    }, [])

    useEffect(() => {
        return () => dispatch(resetList());
    }, [])

    useEffect(() => {
        dispatch(loadProducerList({ids: []}));
    }, [])

    useEffect(() => {
        if (!countries) dispatch(loadCountries());
    }, [])

    useEffect(() => {
        if (!filterList && producerList) {
            let filterArray = producerList.map(producer => ({
                id: producer.id,
                name: producer.name,
                checked: false
            }))
            setFilterList(filterArray)
        }
        if (producerList) {
            setDocumentHeightProcess();
            setDescriptionHeightProcess();
        }
    }, [producerList])


    const handleFilter = (filterSection, event) => {
        let transitionalArray = filterList.filter(producer => event.target.id !== producer.id.toString());
        setFilterList([...transitionalArray, {
            id: event.target.id,
            checked: event.target.checked,
            name: event.target.name
        }])

    };

    const resetSection = () => {
        let resetArrayOfFilterList = filterList.map(item => ({
            id: item.id,
            name: item.name,
            checked: false
        }));
        setFilterList(resetArrayOfFilterList);
        setDocumentHeight(undefined);
        dispatch(loadProducerList({ids: []}));
        setAllChecked(false);
    };

    const setSection = (filterSection, checked) => {
        let resetArrayOfFilterList = filterList.map(item => ({
            id: item.id,
            name: item.name,
            checked: checked
        }));
        setFilterList(resetArrayOfFilterList);
        setAllChecked(checked);
    };

    const applyFilter = () => {
        let filteredArray = []
        filterList.map((producer) => {
            if (producer.checked) filteredArray.push(producer.id)
        })
        setDocumentHeight(undefined);
        dispatch(loadProducerList({ids: filteredArray}));
    };

    const setDocumentHeightProcess = () => {
        let initialHeight = 0;
        let elementArray = document.getElementsByClassName("producer-documents");
        for (let element of elementArray) {
            if (element.clientHeight > initialHeight) {
                initialHeight = element.clientHeight;
            }
        }
        if (initialHeight === 20) initialHeight = 43;
        setDocumentHeight(initialHeight);
    };

    const setDescriptionHeightProcess = () => {
        let initialHeight = 0;
        let elementArray = document.getElementsByClassName("product-description-container");
        for (let element of elementArray) {
            if (element.clientHeight > initialHeight) {
                initialHeight = element.clientHeight;
            }
        }
        if (initialHeight === 20) initialHeight = 43;
    };
    //contact person's modal handling
    const closeContactPersonDetails = () => {
        setPersonDetailsIsOpen(false)
    };

    const openContactPersonModal = (person) => {
        setPersonDetails(person)
    };
    //details modal handling
    const closeDetails = () => {
        setDetailsIsOpen(false)
    }
    const openDetails = (details) => {
        setDetails(details)
    }

    useEffect(() => {
        if (!personDetailsIsOpen) setPersonDetails(null);
    }, [personDetailsIsOpen])

    useEffect(() => {
        if (personDetails) setPersonDetailsIsOpen(true);
    }, [personDetails])

    useEffect(() => {
        if (!detailsIsOpen) setDetails(null);
    }, [detailsIsOpen])

    useEffect(() => {
        if (details) setDetailsIsOpen(true);
    }, [details])

    function createProducerColumn(producer) {
        const toProducerProfile = {
            pathname: '/customer/producerprofile/' + producer.name,
            state: {producerId: producer.id}
        }

        return <div key={producer.id} className="producer-column">
            <div className="producer-row-header">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.name ? producer.name : '-'}
                </Link>
            </div>
            <div className="producer-row logo-cell">
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.producerLogoName ?
                            <img
                                src={`data:image/${producer.producerLogoName};base64, ${producer.producerLogoContent}`}/> : '-'
                    }
                </Link>
            </div>
            <div className="producer-row">
                {trimCompanyUrl(producer.companyUrl) ?
                    <a href={producer.companyUrl} target="_blank"
                       rel="noopener noreferrer">
                        {t('PRODUCER_LIST_COMPANY_URL_LINK')}
                    </a> : '-'
                }
            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.belongsTo ? producer.belongsTo : '-'}
                </Link>
            </div>
            <div
                className={producer.personnelTotal ? "producer-row item-alignment" : "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.personnelTotal ? producer.personnelTotal : '-'}
                </Link>
            </div>
            <div className={producer.turnover ? "producer-row item-alignment" : "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.turnover ? producer.turnover : '-'}
                </Link>
            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.countryName ? producer.countryName : '-'}
                </Link>
            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productName ? producer.productName : '-'}
                </Link>
            </div>
            <div className="producer-row logo-cell">
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.productLogoName ?
                            <img
                                src={`data:image/${producer.productLogoName};base64, ${producer.productLogoContent}`}/> : '-'
                    }
                </Link>
            </div>
            <div className="producer-row">
                {
                    producer.productUrl ?
                        <a href={producer.productUrl} target="_blank"
                           rel="noopener noreferrer">
                            {t('PRODUCER_LIST_PRODUCT_URL_LINK')}
                        </a> : '-'
                }

            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.formattedSince ? producer.formattedSince : '-'}
                </Link>
            </div>
            <div
                className={producer.productTurnover ? "producer-row item-alignment" : "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productTurnover ? producer.productTurnover : '-'}
                </Link>
            </div>
            <div
                className={producer.productPersonnelTotal ? "producer-row item-alignment" : "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productPersonnelTotal ? producer.productPersonnelTotal : '-'}
                </Link>
            </div>
            <div
                className={producer.productPersonnelDev ? "producer-row item-alignment" : "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productPersonnelDev ? producer.productPersonnelDev : '-'}
                </Link>
            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productInProgress ? producer.productInProgress : '-'}
                </Link>
            </div>
            <div className="producer-row">
                <Link to={toProducerProfile} style={styles.link}>
                    {producer.productLiveCount ? producer.productLiveCount : '-'}
                </Link>
            </div>
            <div className={
                producer.prices ? producer.prices.filter(price => price.category === "implementation").length !== 0 ?
                    "producer-row item-alignment" : "producer-row" :
                    "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.prices ? producer.prices.filter(price => price.category === "implementation").length !== 0 ?
                            producer.prices.filter(price => price.category === "implementation")[0].priceType : '-' :
                            '-'
                    }
                </Link>
            </div>
            <div className={
                producer.prices ? producer.prices.filter(price => price.category === "support").length !== 0 ?
                    "producer-row item-alignment" : "producer-row" :
                    "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.prices ? producer.prices.filter(price => price.category === "support").length !== 0 ?
                            producer.prices.filter(price => price.category === "support")[0].priceType : '-' :
                            '-'
                    }
                </Link>
            </div>
            <div className={
                producer.prices ? producer.prices.filter(price => price.category === "hosting").length !== 0 ?
                    "producer-row item-alignment" : "producer-row" :
                    "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.prices ? producer.prices.filter(price => price.category === "hosting").length !== 0 ?
                            producer.prices.filter(price => price.category === "hosting")[0].priceType : '-' :
                            '-'
                    }
                </Link>
            </div>
            <div className={
                producer.prices ? producer.prices.filter(price => price.category === "license").length !== 0 ?
                    "producer-row item-alignment" : "producer-row" :
                    "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.prices ? producer.prices.filter(price => price.category === "license").length !== 0 ?
                            producer.prices.filter(price => price.category === "license")[0].priceType : '-' :
                            '-'
                    }
                </Link>
            </div>
            <div className={
                producer.prices ? producer.prices.filter(price => price.category === "other").length !== 0 ?
                    "producer-row item-alignment" : "producer-row" :
                    "producer-row"}>
                <Link to={toProducerProfile} style={styles.link}>
                    {
                        producer.prices ? producer.prices.filter(price => price.category === "other").length !== 0 ?
                            producer.prices.filter(price => price.category === "other")[0].priceType : '-' :
                            '-'
                    }
                </Link>
            </div>
            <div className="producer-row product-description-container">
                                    <span>
                                        {producer.productDescription ?
                                            <textarea rows="9" onClick={() => openDetails(producer.productDescription)}
                                                      value={producer.productDescription} readOnly/> : '-'}
                                    </span>
            </div>

            <div style={{height: documentHeight ? `${documentHeight - 20}px` : ''}}
                 className={producer.documents ? "producer-row producer-documents document-alignment-left" : "producer-row producer-documents"}>
                {producer.documents ? producer.documents.map(document => <ProducerDocument
                    key={document.id} document={document}/>) : '-'}
            </div>
            <div className="producer-row">
                {producer.persons[0] ? (
                    <span onClick={() => openContactPersonModal(producer.persons[0])}
                          className="person-name-field">
                                            {producer.persons[0].name}
                                        </span>
                ) : (
                    <Link to={toProducerProfile} style={styles.link}>
                        -
                    </Link>)
                }
            </div>
        </div>;
    }

    return (
        <>
            <ContactPersonDetails isOpen={personDetailsIsOpen} close={closeContactPersonDetails}
                                  contactPerson={personDetails}/>
            <DetailsModal header='PRODUCT_DETAILS_MODAL_HEADER' isOpen={detailsIsOpen} close={closeDetails}
                          details={details}/>

            <Filter title="PRODUCER_LIST_PRODUCT_PROVIDER" applyFilter={applyFilter} resetFilter={resetSection}>
                <FilterSection isSubsection={true} allChecked={allChecked} title="FILTER_ALL" filterList={filterList}
                               handleFilter={handleFilter} setSection={setSection}/>
            </Filter>

            <div className="list-container">
                <div className="fixed-column">
                    <div className="empty-cell">
                    </div>
                    <div className="row-title logo-cell">
                        {t('PRODUCER_LOGO')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_COMPANY_URL')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_OWNER_BELONGS_TO')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_PERSONNEL_TOTAL')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_TURNOVER')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_LOCATION')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_CRIS_PRODUCT')}
                    </div>
                    <div className="row-title logo-cell">
                        {t('PRODUCT_LOGO')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCT_URL')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_CRIS_AT_MARKET')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_COMPANY_TURNOVER')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_PERSONNEL_CRIS')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_PERSONNEL_DEV')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_CURRENT_PROJECTS')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_PRODUCTIVE_SYSTEMS')}
                    </div>
                    <div className="row-title">
                        {t('PRICE_TABLE_IMPLEMENTATION')}
                    </div>
                    <div className="row-title">
                        {t('PRICE_TABLE_SUPPORT')}
                    </div>
                    <div className="row-title">
                        {t('PRICE_TABLE_HOSTING')}
                    </div>
                    <div className="row-title">
                        {t('PRICE_TABLE_LICENSE')}
                    </div>
                    <div className="row-title">
                        {t('PRICE_TABLE_OTHER')}
                    </div>
                    <div className="row-title product-description-container">
                        {t('PRODUCER_DETAILS')}
                    </div>
                    <div style={{height: `${documentHeight - 20}px`}} className="row-title">
                        {t('PRODUCER_DOCUMENTS')}
                    </div>
                    <div className="row-title">
                        {t('PRODUCER_CONTACT_PERSON')}
                    </div>
                </div>
                <div className="content-list">
                    {producerList ?
                        producerList.map(producer => createProducerColumn(producer)) : null
                    }
                </div>
            </div>
        </>
    )
};

export default ProducerList;
