import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from "../../config";
import * as StaticFilterDefinitions from "../../utility/StaticFilterDefinitions";
import * as RequirementHandler from "../../utility/RequirementHandler";
import {sendFilterActivity} from '../../activity/ActivityNotifier';
import {filterSelectedRequirements} from './RequirementSelectionAction';

let actualFilter = [];
let actualProducerFilter = [];

export function clearFilter() {
    return {
        type: actionTypes.CLEAR_FILTER
    };
}

export const loadCustomerMainViewFilter = () => {
    return async (dispatch) => {
        try {
            const response = await getProducerList();
            if (response.status >= 400) {
                dispatch(loadingFail(response.status));
            } else {
                let prodList = await response.json();
                dispatch(loadingFilterSuccess(prodList));
            }
        } catch (error) {
            dispatch(loadingFail(error));
        }
    }
}

export function filterItemChanged(filterSection, event) {
    return {
        type: actionTypes.FILTER_ITEM_CHANGED,
        filterSection: filterSection,
        itemId: event.target.id,
        itemChecked: event.target.checked,
        itemValue: event.target.value
    }
}

export function filterSectionChanged(filterSection, checked) {
    return {
        type: actionTypes.FILTER_SECTION_CHANGED,
        filterSection: filterSection,
        sectionChecked: checked,
        itemValue: null
    }
}

export function filterSet(newFiltering, allRequirements, account, selectedRequirementsIds, translationMap, allRequirementSelected) {

    return async (dispatch) => {
        try {
            if (allRequirements) {
                const filterRequirementsAction = filterRequirements(newFiltering, allRequirements, account);
                dispatch(filterRequirementsAction);
                if (selectedRequirementsIds) {
                    dispatch(filterSelectedRequirements(selectedRequirementsIds, filterRequirementsAction.filteredRequirements, translationMap, allRequirementSelected));
                }
            }
        } catch (error) {
            dispatch(filterRequirementsFail(error));
        }
    }
}

async function getProducerList() {

    return await fetch(`${SERVER_URL}/producers/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    });
}

function loadingFilterSuccess(prodList) {
    let filter = [];
    if (prodList) {
        filter.push({name: "PRODUCER_LIST_PRODUCT_PROVIDER", filterList: [...prodList]});
    }
    filter.push({
        name: " ", filterList: [{id: "A" + StaticFilterDefinitions.ANSWERED_ID, name: "ANSWERED", checked: false},
            {id: "A" + StaticFilterDefinitions.NOT_ANSWERED_ID, name: "NOT_ANSWERED", checked: false}]
    });

    return {
        type: actionTypes.FILTER_INIT,
        filter: filter
    }
}

function loadingFail(error) {
    return {
        type: actionTypes.FILTER_INIT_FAIL,
        error: error
    }
}

function filterRequirementsFail(error) {
    return {
        type: actionTypes.FILTER_SET_FAIL,
        error: error
    }
}

export function filterRequirements(newFiltering, allRequirements, account) {
    //safe filter for comparison
    setAnsweredFilter(newFiltering);
    //Producer is connected using OR
    //answered is connected with AND
    //we use answered to create a 'positive list' of producers for which we keep requirements.
    let answeredFilter = newFiltering[1].filterList.filter(filterItem => filterItem.checked === true).map(filterItem => filterItem.id);

    let answered = true;
    /* if (answeredFilter.length === 1 && answeredFilter[0] === StaticFilterDefinitions.NOT_ANSWERED_ID) {
         answered = false;
     }*/
    let producerIds = newFiltering[0].filterList.filter(filterItem => filterItem.checked === true).map(filterItem => filterItem.id);
    //short track: nothing checked or all checked means: "take all"
    if ((answeredFilter.length === 0 || answeredFilter.length === 2)
        && (producerIds.length === 0)) { //Auf Wunsch des Chefs: Filter answered ist nicht aktiv. || producerIds.length === newFiltering[0].filterList.length
        return {
            type: actionTypes.FILTER_SET,
            filteredRequirements: RequirementHandler.deepCopyAll(allRequirements)
        };
    }
    // we got work!
    if (!producerIds || producerIds.length === 0) producerIds = [...newFiltering[0].filterList];

    //Problem: in actual state structure, we cannot easily combine requirements and non existing responses
    let filteredRequirements = getAnsweredByProducer(producerIds, allRequirements, answered);

    //do not forget to add those requirements without any answer in case of 'not answered'
    if (!answered) {
        filteredRequirements = addNotAnswered(filteredRequirements, allRequirements);
    } else {
        //Send activity info as the customer did choose some producers explicitly
        sendFilterActivity(producerIds, account);
    }
    return {
        type: actionTypes.FILTER_SET,
        filteredRequirements: filteredRequirements
    };
}

export function getPositiveProducerIdList(newFiltering) {
    let producerIds = newFiltering[0].filterList.filter(filterItem => filterItem.checked === true).map(filterItem => filterItem.id);
    //no Filter means "take all", too
    if (!producerIds || producerIds.length === 0) producerIds = newFiltering[0].filterList.map(filterItem => filterItem.id);

    return {
        producerIds: producerIds
    };
}

function getFilteredRequirementResponses(producerIds, responses, answered) {
    let matchingResponses = [];
    for (const i in responses) {
        if (producerIds.includes(responses[i].producerId) === answered) {
            matchingResponses.push(responses[i]);
        }
    }
    return matchingResponses;
}

function addToCategoryIfAnsweredByProducer(targetList, requirement, producerIds, answered) {
    let matchingResponses = getFilteredRequirementResponses(producerIds, requirement.responses, answered);
    if (matchingResponses.length > 0) {
        targetList.push(RequirementHandler.deepCopyRequirement(requirement, matchingResponses));
    }
}

export function getAnsweredByProducer(producerIds, allRequirements, answered) {

    let filteredRequirements = {};
    for (const category in allRequirements) {
        if (!filteredRequirements[category]) filteredRequirements[category] = [];
        for (const i in allRequirements[category]) {
            if (allRequirements[category][i].responses && allRequirements[category][i].responses.length > 0) {
                addToCategoryIfAnsweredByProducer(filteredRequirements[category], allRequirements[category][i], producerIds, answered);
            }
        }
    }
    return filteredRequirements;
}

export function addNotAnswered(filteredRequirements, allRequirements) {
    if (!filteredRequirements) filteredRequirements = {};
    for (const category in allRequirements) {
        if (!filteredRequirements[category]) filteredRequirements[category] = [];
        for (const i in allRequirements[category]) {
            if ((!allRequirements[category][i].responses || allRequirements[category][i].responses.length === 0)) {
                filteredRequirements[category].push(RequirementHandler.deepCopyRequirement(allRequirements[category][i]));
            }
        }
    }
    return filteredRequirements;
}


export function setAnsweredFilter(newFiltering) {
    //Copy all as we need different references.
    actualFilter = [{}, {}];
    for (const section in newFiltering) {
        let newFilterList = [];
        if (newFiltering[section].filterList) {
            newFilterList = newFiltering[section].filterList.map(filterItem => ({
                id: filterItem.id,
                name: filterItem.name,
                checked: filterItem.checked
            }));
        }
        actualFilter[section] = {name: newFiltering[section].name, filterList: newFilterList}
    }

}

//////////////////////////////
// Producer Filter Functions

export function loadProducerMainViewFilter() {
    return {
        type: actionTypes.FILTER_INIT,
        filter: [{
            name: " ", filterList: [{id: StaticFilterDefinitions.ANSWERED_ID, name: "ANSWERED", checked: false},
                {id: StaticFilterDefinitions.ANSWERED_DRAFT_ID, name: "ANSWERED_DRAFT", checked: false},
                {id: StaticFilterDefinitions.NOT_ANSWERED_ID, name: "NOT_ANSWERED", checked: false}]
        },
            {name: "BEFORE", filterList: [{id: "B.0", name: "LAST_SAVED_BEFORE_THIS_DATE", checked: false, value: ""}]}
        ]
    };
}

export function producerFilterSet(newFiltering, allRequirements) {

    return async (dispatch) => {
        try {
            if (allRequirements) {
                dispatch(filterProducerRequirements(newFiltering, allRequirements));
            }
        } catch (error) {
            dispatch(filterRequirementsFail(error));
        }
    }
}

export function filterProducerRequirements(newFiltering, allRequirements) {
    setProducerFilter(newFiltering);

    let requirementsMap = {};

    let takeAll = true;

    if (actualProducerFilter[0].filterList[StaticFilterDefinitions.NOT_ANSWERED_ID].checked === true) {
        requirementsMap = addNotAnswered(requirementsMap, allRequirements);
        takeAll = false;
    }
    let lastUpdated = actualProducerFilter[1].filterList[0].value;

    if (actualProducerFilter[0].filterList[StaticFilterDefinitions.ANSWERED_DRAFT_ID].checked === true) {
        requirementsMap = addAnsweredByStatus(requirementsMap, 'DRAFT', allRequirements, lastUpdated);
        takeAll = false;
    }
    if (actualProducerFilter[0].filterList[StaticFilterDefinitions.ANSWERED_ID].checked === true) {
        requirementsMap = addAnsweredByStatus(requirementsMap, 'PUBLISHED', allRequirements, lastUpdated);
        takeAll = false;
    }

    if (takeAll) {
        if (lastUpdated) {
            requirementsMap = addNotAnswered(requirementsMap, allRequirements);
            requirementsMap = addAnsweredByStatus(requirementsMap, 'DRAFT', allRequirements, lastUpdated);
            requirementsMap = addAnsweredByStatus(requirementsMap, 'PUBLISHED', allRequirements, lastUpdated);
        } else {
            requirementsMap = RequirementHandler.deepCopyAll(allRequirements);
        }
    }
    return {
        type: actionTypes.FILTER_SET,
        filteredRequirements: requirementsMap
    };

}

//For Producer only: uses response to compare and copy
export function addAnsweredByStatus(filteredRequirements, status, allRequirements, lastUpdated) {

    if (!filteredRequirements) filteredRequirements = {};
    for (const category in allRequirements) {
        if (!filteredRequirements[category]) filteredRequirements[category] = [];
        for (const i in allRequirements[category]) {
            //Producer has exactly one response, if there is one
            if (allRequirements[category][i].responses && allRequirements[category][i].responses[0]) {
                if (allRequirements[category][i].responses[0].status === status) {
                    if (dateInRange(allRequirements[category][i].responses[0].lastUpdated, lastUpdated)) {
                        filteredRequirements[category].push(RequirementHandler.deepCopyRequirement(allRequirements[category][i]));
                    }
                }
            }
        }
    }
    return filteredRequirements;
}

function dateInRange(lastUpdated, beforeThisDate) {
    if (!beforeThisDate || beforeThisDate.length < 10 || !lastUpdated) {
        return true;
    }
    //Date und Moment gehen nicht, schaffen einstellige Monate nicht. Deshalb mal wieder der Umweg über String und Integer:
    const bf = beforeThisDate.split("-").join("");
    const lu = "" + lastUpdated[0] + (lastUpdated[1] < 10 ? "0" + lastUpdated[1] : lastUpdated[1]) + (lastUpdated[2] < 10 ? "0" + lastUpdated[2] : lastUpdated[2]);
    return (parseInt(lu) < parseInt(bf));
}


function setProducerFilter(newFiltering) {
    //Copy all as we need different references.
    actualProducerFilter = [{}];
    for (const section in newFiltering) {
        let newFilterList = [];
        if (newFiltering[section].filterList) {
            newFilterList = newFiltering[section].filterList.map(filterItem => ({
                id: filterItem.id,
                name: filterItem.name,
                checked: filterItem.checked,
                value: filterItem.value
            }));
        }
        actualProducerFilter[section] = {name: newFiltering[section].name, filterList: newFilterList}
    }

}
