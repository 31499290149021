import React, { useState } from 'react';
import Button from '../../../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ErrorPopup from '../../../../../components/UploadDocuments/UploadError/ErrorPopup';
import { uploadLogo, deleteLogo } from '../../../../../store/actions/ProducerAction';
import {ReactComponent as Del} from '../../../../../assets/images/trash-alt-regular.svg';
import './Header.css';

const Header = ({props, providerInfo, state}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleProducerLogo = async(event) => {
        if(event.target.files.length !== 0){
            let producerLogo = new FormData();
            producerLogo.append('producerlogo', event.target.files[0]);
            dispatch(uploadLogo(providerInfo.id, producerLogo, 'producer'));
        }
    }

    const handleProductLogo = async(event) => {
        if(event.target.files.length !== 0){
            let productLogo = new FormData();
            productLogo.append('producerlogo', event.target.files[0]);
            dispatch(uploadLogo(providerInfo.id, productLogo, 'product'));
        }
    }
    const deleteProducerLogo = () => {
        dispatch(deleteLogo(providerInfo.id, 'producer'));
    }
    const deleteProductLogo = () => {
        dispatch(deleteLogo(providerInfo.id, 'product'));
    }

    return (
        <>
            <div className="producer-title" style={{width: `${state.headerWidth - 50}px`}}>
                <div className="ProviderName">
                    <span className="NameText">
                        {state.disabled?(<span>{state.profile.name}</span>):(<input type="text" className="producer-profile-main-fields" onChange={props.handleFields('name')}  value={ state.profile.name ? state.profile.name : "" }/>)}
                    </span>
                </div>
            {state.editable?(
                    <div className="editContainer">
                        <button onClick={props.editProfile} className="title-edit-button"/>
                    </div>):''
            }
            </div>
            {state.editable?(
                <div className="Footer-Container">
                    <div className="Cache-Button">
                        <Button clicked={props.cache} color="Orange" title={t('PRODUCER_CACHE_NOT_PUBLISHED')}/>
                    </div>
                    <div className="Publish-Button">
                        <Button clicked={props.publish} title={t('PRODUCER_SAVE_PUBLISH')}/>
                    </div>
                </div>):''
            }
            <div className="producer-header">

            </div>
            <div className="logo-container">
                <div className="producer-logo">
                    <div className="text-name">
                        <span>{t('PRODUCER_LOGO')}</span>
                    </div>
                    <div className="logo-img">
                        {
                            providerInfo.profile.producerLogoName ? (
                            <img className="logo" src={`data:image/${providerInfo.profile.producerLogoName};base64, ${providerInfo.profile.producerLogoContent}`} alt="Company Logo"/>) : null
                        }
                    </div>
                    {state.editable?(
                        <>
                            <div className="editContainer-logo">
                                <label htmlFor="editContainer-logo-producer"><span className="edit"></span></label>
                                <input type="file" onChange={handleProducerLogo} onClick={(event)=> { event.target.value = null}} id="editContainer-logo-producer"/>
                            </div>
                            {
                                providerInfo.profile.producerLogoName ? (
                                    <div className="deleteContainer-logo">
                                        <button onClick={()=>deleteProducerLogo()} className="delete-logo"><Del /></button>
                                    </div>
                                ): null
                            }

                        </>):''
                    }
                </div>
                <div    className="product-logo">
                    <div className="text-name">
                        <span>{t('PRODUCT_LOGO')}</span>
                    </div>
                    <div className="logo-img">
                        {
                            providerInfo.profile.productLogoName ? (
                                <img className="logo" src={`data:image/${providerInfo.profile.productLogoName};base64, ${providerInfo.profile.productLogoContent}`} alt="Company Logo"/>) : null
                        }

                    </div>
                    {state.editable?(
                        <>
                            <div className="editContainer-logo">
                                <label htmlFor="editContainer-logo-product"><span className="edit"></span></label>
                                <input type="file"  onChange={handleProductLogo} onClick={(event)=> { event.target.value = null}} id="editContainer-logo-product"/>
                            </div>
                            {
                                providerInfo.profile.productLogoName ? (
                                    <div className="deleteContainer-logo">
                                        <button className="delete-logo" onClick={()=>deleteProductLogo()} ><Del /></button>
                                    </div>
                                ) : null
                            }
                        </>
                        ):''
                    }
                </div>
            </div>
        </>
    )
};
export default Header;
