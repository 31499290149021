import {
    LOAD_CATEGORY_SUCCESS,
    LOAD_CATEGORY_START,
    LOAD_CATEGORY_FAIL
} from './actionTypes';
import {SERVER_URL} from '../../config';

export function categories(languageCode) {
    return async (dispatch) => {
        dispatch(loadCategoriesStart())
        try {
            const response = await loadCategories(languageCode);
            dispatch(loadCategoriesSuccess(await response.json()));
        } catch (error) {
            dispatch(loadCategoriesFail(error))
        }
    }
}

function loadCategoriesSuccess(categories) {
    return {
        type: LOAD_CATEGORY_SUCCESS,
        categories: categories
    }
}

function loadCategoriesStart() {
    return {
        type: LOAD_CATEGORY_START
    }
}

function loadCategoriesFail(error) {
    return {
        type: LOAD_CATEGORY_FAIL,
        error: error
    }
}

async function loadCategories(languageCode) {
    return await fetch(SERVER_URL + '/category/allBy?languageCode=' + languageCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });
}
