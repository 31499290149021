import * as actionTypes from '../actions/actionTypes';
import {removeDeletedRequirement} from '../../utility/RequirementHandler';

const initialState = {
    filteredRequirements: null,
    error: ''
};

const requirementFilterReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FILTER_INIT:
            return {
                ...state,
                filter: action.filter
            };
        case actionTypes.FILTER_ITEM_CHANGED:
            return {
                ...state,
                filter: setNewFilterItemInformation(action, [...state.filter])
            };
        case actionTypes.FILTER_SECTION_CHANGED:
            return {
                ...state,
                filter: setNewFilterSectionInformation(action, [...state.filter])
            };
        case actionTypes.FILTER_SET:
            return {
                ...state,
                filteredRequirements: action.filteredRequirements
            };
        case actionTypes.FILTER_INIT_FAIL:
        case actionTypes.FILTER_SET_FAIL:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.CLEAR_FILTER:
            return {
                ...state,
                ...initialState
            };
        case actionTypes.REMOVE_FROM_FILTERED_REQUIREMENTS:
            return {
                ...state,
                filteredRequirements: removeDeletedRequirement(action.removedCategoryId, action.removedRequirementId, state.filteredRequirements)
            }
        default:
            return state;
    }
}

function setNewFilterItemInformation(action, filterCopy) {

    let filterSection = filterCopy.find(section => section.name === action.filterSection);
    if (filterSection != null && filterSection.filterList) {
        let indexX = filterSection.filterList.find(item => action.itemId.toString() === item.id.toString());
        indexX.checked = action.itemChecked;
        indexX.value = action.itemValue;
    }
    return filterCopy;
}

function setNewFilterSectionInformation(action, filterCopy) {

    let filterSection = filterCopy.find(section => section.name === action.filterSection);
    if (filterSection != null) {
        let resetArrayOfFilterList = filterSection.filterList.map(item => ({
            id: item.id,
            name: item.name,
            checked: action.sectionChecked,
            value: action.itemValue
        }));
        filterSection.filterList = resetArrayOfFilterList;
        filterSection.allChecked = action.sectionChecked;
        return filterCopy;
    }
}

export default requirementFilterReducer;



