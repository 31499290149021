import * as actionTypes from '../actions/actionTypes';
import {SERVER_URL} from '../../config';

export const loadCountries = () => {
    return async (dispatch) => {
        try{
            const response = await getCountries();
            const countries = await response.json();
            dispatch(loadingCountries(countries));
        } catch (error) {
            //TODO: implement an error action with an proper message because console.log is leaking information
        }
    }
}

function loadingCountries(countries){
    return {
        type: actionTypes.LOAD_COUNTRIES,
        countries: countries
    }
}

async function getCountries(){
    const response = await fetch(`${SERVER_URL}/countries`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    })
    return response
}
