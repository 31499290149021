import React, {Component} from 'react';
import './Layout.css';
import Sidebar from '../Sidebar/Sidebar';
import CustomerMenuBar from '../../containers/Customer/MenuBar/CustomerMenuBar';
import ProducerMenuBar from '../../containers/Producer/MenuBar/ProducerMenuBar';
import AdminMenuBar from '../../containers/Admin/MenuBar/AdminMenuBar';
import * as messageAction from "../../store/actions/MessageAction";
import Message from "../Message/Message";
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';


class Layout extends Component {

    state = {
        showMessage: false,
        messageText: ''
    }

    hideMessage() {
        this.props.hideMessage();
    }

    menuBar(role) {
        if (role === "CUSTOMER") {
            return (
                <CustomerMenuBar/>
            )
        }
        if (role === "PRODUCER") {
            return (
                <ProducerMenuBar/>
            )
        }
        return <AdminMenuBar/>
    }

    productAreaName(t) {
        if (this.props.account.productArea) {
            return t('PRODUCT_AREA') + this.props.account.productArea.name;
        }
        return '';
    }

    render() {
        const {t} = this.props;
        return (
            <div className='Layout'>
                <Sidebar productAreaName={this.productAreaName(t)}
                         loginValid={this.props.loginValid}/>
                <main>
                    {(this.props.showMessage ? <Message type={this.props.messageType}
                                                        text={this.props.messageText}
                                                        hide={() => this.hideMessage()}/> : '')}
                    {this.props.showMenuBar ? this.menuBar(this.props.account.userType) : ''}{this.props.children}
                </main>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const showMyMessage = (state.message.type !== 'message_accepted');
    return {
        account: state.login.account,
        loginValid: state.login.valid,
        showMessage: showMyMessage,
        messageType: state.message.type,
        messageText: state.message.text
    }
};

const mapDispatchToProps = dispatch => {
    return {
        hideMessage: () => dispatch(messageAction.hide())
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Layout));
