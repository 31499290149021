import React, {Component} from 'react';
import ToggleView from '../../UI/ToggleView/ToggleView';
import RequirementControl from '../RequirementControl/RequirementControl';

/**
 *
 * @param props
 * requirements: map with all approved requirements sorted by categories
 * openToggleHandler: needed for toggling the header
 * toggleInformationList: a list with all categories and their toggle state
 * responseEditable: Responses can be editable.
 * requirementDeletable: Requirement ist deletable from the view
 * deleteRequirement: action to remove requirement from view
 */
class ApprovedRequirementsView extends Component {

    createToggleView() {
        let requirements = null;

        for (const key in this.props.requirements) {
            const values = this.props.requirements[key];

            requirements = (
                <>
                    {requirements}
                    {this.showToggle(key, values) ? <ToggleView heading={values[0].categoryName}
                                                                content={this.createContent(values, this.props)}
                                                                openToggleHandler={() => this.props.openToggleHandler(values[0].categoryId)}
                                                                toggle={this.getCategoryToggle(key)}
                                                                iconStatus={this.props.requirementIconStatus ? this.props.requirementIconStatus[key] : ''}
                    /> : ''}
                </>
            );
        }
        return requirements;
    }

    showToggle(key, values) {
        return values !== undefined && values[0] !== '' && values[0] !== undefined && !(values[0].status === 'DRAFT' || values[0].status === 'FOR_APPROVAL');
    }

    createContent(requirements, props) {
        return <>{requirements.map(requirement =>
            <RequirementControl key={requirement.id}
                                requirementId={requirement.id}
                                label={requirement.label}
                                clicked={() => props.checkboxClicked(requirement)}
                                checked={props.checkboxChecked !== undefined ? props.checkboxChecked.includes(requirement.id) : false}
                                deletable={props.requirementDeletable}
                                delete={() => (props.deleteRequirement(requirement))}
                                showAllResponses={this.getRequirementToggle(requirement.id)}
                                toggleRequirement={() => props.toggleRequirement(requirement.id)}
                                availableRequirementResponses={requirement.responses}
                                responseEditable={props.responseEditable}
                                toggleResponsesInformationList={this.props.toggleResponsesInformationList}
            />)}
        </>;
    }

    getCategoryToggle(key) {
        const categoryToggleInfo = this.props.toggleInformationList.find(toggleInfo => key === toggleInfo.id);
        if (categoryToggleInfo === undefined) {
            return false;
        }
        return categoryToggleInfo.toggle;
    }

    getRequirementToggle(key) {
        if (this.props.toggleRequirementInformationList === undefined) {
            return false;
        }
        const requirementToggleInfo = this.props.toggleRequirementInformationList.find(toggleInfo => key === toggleInfo.id);
        if (requirementToggleInfo === undefined) {
            return false;
        }
        return requirementToggleInfo.toggle;
    }


    render() {
        return (
            <>
                {this.createToggleView()}
            </>
        )
    }
}

export default ApprovedRequirementsView;
