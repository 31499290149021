import React, {Component} from 'react';

import './AboutLabel.css';
import packageJson from '../../../package.json';
import InfoModal from '../Modal/InfoModal/InfoModal';

class AboutLabel extends Component {
    state = {
        show: false
    }

    showInfos = () => {
        this.setState({show: !this.state.show})
    }

    render() {
        return (
            <div className='Info'>
                <label onClick={this.showInfos} style={{cursor: 'pointer'}}>{packageJson.name + ' ' + packageJson.version}</label>
                {this.state.show ? <InfoModal header='ABOUT_CONNECT'
                                              isOpen={true}
                                              showAbout={this.showInfos}
                /> : ''}
            </div>
        );
    }
}

export default AboutLabel;
