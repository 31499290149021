import React from 'react';

import './Sidebar.css';
import Logo from '../../components/Logo/Logo';
import HelpRouting from '../../routing/HelpRouting';
import AboutLabel from '../../components/AboutLabel/AboutLabel';

const sidebar = (props) => (
    <div className='Sidebar'>
        <Logo/>
        <label className='ProductArea'>{props.loginValid ? props.productAreaName : ''}</label>
        <HelpRouting/>
        <AboutLabel/>
    </div>
);

export default sidebar;
